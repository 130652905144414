import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

//Styles
//import styles from './nuevaObra-jss';
import styles from '../../routes/Pages/ObrasRegistradas/registroObra-jss'

import SweetAlert from 'sweetalert-react';
import '../../styles/components/vendors/sweetalert-react/sweetalert.css';

class CharacterPaper extends React.Component {

    state = {
        selected: null
    };

    handleOpenSwal = (character) => {
        this.setState({ show: true, selected: character });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.onDelete(this.state.selected);
    }

    render() {
        const { classes, onDelete, onEdit, character, canEdit } = this.props;

        return (
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                            <Grid item xs={12} md={12} >
                              <Paper className={classes.paperCharaters}>
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center">
                                  <Grid item xs={12} sm={8} md={8} >
                                    <div className={classes.contentFlex}>
                                      <div>
                                        <Typography component="p" className={classes.labelTitle}>
                                          Nombre:
                                        </Typography>
                                        <Typography component="p" className="textJustify">
                                          {character.nombre}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography component="p" className={classes.labelTitle}>
                                          Edad:
                                        </Typography>
                                        <Typography component="p" className="textJustify">
                                          {character.edad}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4} >
                                    <div className={classes.rightAction}>
                                      <IconButton className={classes.button} aria-label="Delete" color="primary" onClick={() => this.handleOpenSwal(character)} disabled={canEdit}>
                                        <Delete />
                                      </IconButton>
                                      <IconButton className={classes.button} aria-label="Delete" color="primary" onClick={() => onEdit(character)} disabled={canEdit}>
                                        <Edit />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center">
                                  <Grid item xs={12} md={12} >
                                    <div className="espaciadoP">
                                      <Typography component="p" className={classNames(classes.labelTitle, classes.pt2)}>
                                        Psicología del personaje:
                                    </Typography>
                                      <Typography component="p" className="textJustify">
                                      {character.psicologia}
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Paper>
                              <SweetAlert
                                    show={this.state.show}
                                    customClass={classes.alertDialog}
                                    title="¿Estás seguro?"
                                    text="Una vez que elimines este elemento, no se podrá recuperar"
                                    showCancelButton
                                    onConfirm={() => {
                                        this.handleConfirmSwal();
                                    }}
                                    onCancel={() => {
                                        this.setState({ show: false, selected: null });
                                    }}
                                />
                            </Grid>
                        </Grid>
        )
    }
}

CharacterPaper.propTypes = {
    classes: PropTypes.object,
    character: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func
}

CharacterPaper.defaultProps = {
    onDelete: () => { },
    onEdit: () => { }
}

export default withStyles(styles)(CharacterPaper); 