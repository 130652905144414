import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './../reportes-jss'
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

//Services
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';

//Notifications  
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

//Auxiliars
import { convertToStringArray } from '../../../../utils/helpers';
import { textLabelsEs } from '../../../../utils/tableTexts';

import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

const keys = ['proveedor', 'tituloObra', 'tipoObra', 'pais', 'numCapitulos', 'precioCapitulo', 'totalObra', 'tipoContrato'];

class AdvFilter extends React.Component {
  state = {
    //Notificiations 
    open: false,
    //Filters 
    empresa: 0,
    pais: 0,
    tipoObra: 0,
    tipoContrato: 0,
    //Catalogues
    paises: [],
    contratos: [],
    array: [],
    //Table
    columns: [],
    data: [],
    dataCsV: [],
    //Loading 
    loading: false
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClearFilters = () => {
    this.setState({
      empresa: 0,
      pais: 0,
      tipoObra: 0,
      tipoContrato: 0,
      loading: true
    });

    const request = {
      empresa: 0,
      pais: 0,
      tipoObra: "0",
      tipoContrato: 0
    }
    this.handleFetch(request);
  }

  //Notifications
  handleClose = () => {
    this.setState({ open: false });
  };

  //Llamada de busqueda ch
  searchs = () => {
    this.setState({ loading: true });

    const request = {
      empresa: this.state.empresa,
      pais: this.state.pais,
      tipoObra: this.state.tipoObra,
      tipoContrato: this.state.tipoContrato
    }

    this.handleFetch(request);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const { lang } = this.context;
    this.setState(
      {
        columns: [lang.reports.compProv, lang.reports.title, lang.reports.playType, lang.reports.country,
        lang.reports.chapterNumber, lang.reports.chapterPrice, lang.reports.playTotal, lang.reports.contractType]
      }
    )

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ paises: response.data })
        }
      }).catch(
        error => {
          console.log(error)
        });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 6 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ contratos: response.data })
        }
      }).catch(
        error => {
          console.log(error)
        });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 7 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ array: response.data })
        }
      }).catch(
        error => {
          console.log(error)
        });

    //Llamada carga inicial
    const request = {
      empresa: 0,
      pais: 0,
      tipoObra: "0",
      tipoContrato: 0
    }
    this.handleFetch(request);
  }

  handleFetch(request) {
    apiPOST('/reportes/obrasCompradas', request).then(response => {
      if (response.status === 200) {

        // JCMY 27/03/18
        // Ajuste par evitar que tronara en replace
        let table = []
        Object.keys(response.data).forEach(function (key) {
          let row = []

          //AHDA 18/06/2019: Added validations for nulls
          const item = response.data[key]; 

          row.push(item.proveedor ? item.proveedor : "-")
          row.push(item.tituloObra ? item.tituloObra : "-")
          row.push(item.tipoObra === "primigenia" ? "Primigenia" : "Adaptación") //AHDA 05/06/2019
          row.push(item.pais ? item.pais : "-")
          row.push(item.numCapitulos ? item.numCapitulos : "-")
          // EGS 24/04/2019 - Evitar nulls evitando tronar el multiselect de los filtroos 
          row.push(( item.precioCapitulo != null ) ? item.precioCapitulo.replace(/,/g, '') : '$0')
          row.push(( item.totalObra != null ) ? item.totalObra.replace(/,/g, '') : '$0')
          row.push(item.tipoContrato? item.tipoContrato : "-")
          table.push(row)
        })


        this.setState({ data: table, dataCsV: table, loading: false })
      } else {
        this.setState({ open: true, loading: false });
      }
    }).catch(error => {
      this.setState({ open: true, loading: false });
    });
  }

  //Download csv
  handleDownload = (data) => {
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteCompraObras'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    const dataCsv = [["Empresa/Proveedor", "Título de la Obra", "Tipo Obra", "País", "Num. Capítulos", "Precio por capítulo", "Total de la obra", "Tipo de Contrato"]]

    for (const x in data) {
      const object = []
      object.push(data[x])
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Compraobras.csv";
    download.click();
  }


  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.dataCsV)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title={lang.reports.reports} desc="">
                {this.state.loading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}

                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.empresa} onChange={this.handleChange} name="empresa" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.array.map((arr, idx) => (
                              <MenuItem value={arr.value} key={`arr${idx}`}>
                                {arr.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.compProv}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.pais} onChange={this.handleChange} name="pais" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.paises.map((pais, idx) => (
                              <MenuItem value={pais.value} key={`pais${idx}`}>
                                {pais.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.country}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.tipoObra} onChange={this.handleChange} name="tipoObra" className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          <MenuItem value={"Primigenia"}>{lang.reports.primal}</MenuItem>
                          <MenuItem value={"Adaptación"}>{lang.reports.adaptation}</MenuItem>
                        </Select>
                        <FormHelperText>{lang.reports.playType}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.tipoContrato} onChange={this.handleChange} name="tipoContrato" className={classes.selectEmpty} >
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.contratos.map((contrato, idx) => (
                              <MenuItem value={contrato.value} key={`contrato${idx}`}>
                                {contrato.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.contractType}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.compraObras}>
                  <MUIDataTable
                    title={`${lang.reports.repPlayPurchase} (${this.state.data.length} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common.errMsg} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

export default withStyles(styles)(AdvFilter);
