import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LoaderFull from '../../../../components/Loader/LoaderFull'; //AHDA 27/05/2019
import styles from './../reportes-jss';
import Tooltip from '@material-ui/core/Tooltip'; //AHDA 31/05/2019
import IconButton from '@material-ui/core/IconButton'; //AHDA 31/05/2019
import CloudDownload from '@material-ui/icons/CloudDownload'; //AHDA 31/05/2019
import SearchIcon from '@material-ui/icons/Search';
import { textLabelsEs } from '../../../../utils/tableTexts'; //AHDA 31/05/2019


//Filtros
import { renderInput,getSuggestions,renderSuggestion} from './../autocomplete';
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import enLocale from 'moment/locale/en-au';
import esLocale from 'moment/locale/es';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const locale = {
  en: enLocale,
  es: esLocale
}

class BitacoraObras extends React.Component{
    state = {
        //Table
        columns: ['Fecha', 'Título de la obra', 'Usuario', 'Etapa', 'Estatus', 
            {
                name: 'Comentarios',
                options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="columnComments">
                            <div className='textEllipsis' >
                                <span >{value === "" ? "-" : value}</span>
                            </div>
                            <IconButton className="cursorPointer" aria-label="Details" onClick={() => this.handleClickOpen(tableMeta.rowData)}>
                                <MoreHorizIcon />
                                {/* <SearchIcon /> */}
                            </IconButton>
                            {/* <a className="cursorPointer" onClick={() => this.handleClickOpen(tableMeta.rowData)} >
                                <span>{value === "" ? "-" : value}</span>
                                &nbsp;
                                <strong>...</strong>
                            </a> */}
                        </div>
                    );
                }
                }
            }
        ],
        data: [],
        //filters
        startDate: null,
        endDate: null,
        title: '',
        status: '0',
        //Catalogue
        titles: [],
        statuses:[],
        //Notification Error
        errorMessage: "",
        openError: false,
        results:"", 
        //Loading
        isLoading: false, //AHDA 27/05/2019

        openDetailDialog: false,
        selected: null,
    }

    //Datepickers
    handleDateChange = date => {
        this.setState({ startDate: date });
    };
    
    handleDateChangeFin = date => {
        this.setState({ endDate: date });
    };

    
    //Innputs
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleStateChange = changes => {
        if (changes.hasOwnProperty('selectedItem')) {
        this.setState({ title: changes.selectedItem })
        } else if (changes.hasOwnProperty('inputValue')) {
        this.setState({ title: changes.inputValue })
        }
    }

    handleClickOpen = (data) => {
        this.setState({
            selected: {
                date: data[0] || "",
                title: data[1] || "",
                userName: data[2] || "",
                comment: data[5] || "",
            },
            openDetailDialog: true,
        })
    }

    handleCloseStep = () => {
        this.setState({selected: null,openDetailDialog: false });
    }

    handleClearFilters = () => {

        this.setState({
            startDate: null,
            endDate: null,
            title: '',
            status: '0',
        });
    
        const request = {
            startDate: this.state.startDate == null ? "0" : this.state.startDate,
            endDate: this.state.endDate == null ? "0" : this.state.endDate,
            title: this.state.title == "" ? "0" : this.state.title,
            stat: this.state.status,
            dates:"0"
        }
    
        this.handleFetch(request);
    }

    //   JCMY 09/05/2019
    handleFetch = (request) => {
        //AHDA 27/05/2019: Added loading 
        this.setState({ isLoading: true });

        apiPOST('/reportes/blogworks',request).then(response => {
            if (response.status === 200) {

            const result = [], csvResult = []; //AHDA 31/05/2019
            const list = response.data[0];
            for (const x in list) {
                var obj = [], objCsv = []; //AHDA 31/05/2019
                obj.push(list[x].date != null ? list[x].date : "-");
                obj.push(list[x].title != null ? list[x].title : "-");
                obj.push(list[x].user != null ? list[x].user : "-");
                obj.push(list[x].stage != null ? list[x].stage : "-");
                obj.push(list[x].status != null ? list[x].status : "-");

                if (list[x].fullComments != null) {
                    obj.push(list[x].fullComments != null ? list[x].fullComments : "-");
                } else {
                    obj.push(list[x].comments != null ? list[x].comments : "-");
                }

                result.push(obj);

                //AHDA 31/05/2019: Data for csv 
                objCsv = [...obj]; 
                objCsv[objCsv.length - 1] = objCsv[objCsv.length - 1].replace(/,/g, ';');
                csvResult.push(objCsv); 

            }
            this.setState({ data: result, dataCsv: csvResult,  isLoading: false, results: response.data.length })
            } else {
            this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
            }
        }).catch(error => {
            this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
        });
    }

    //Notifications
    handleCloseError = () => {
        this.setState({ openError: false });
    }

    componentDidMount() {
        // JCMY 09/05/2019
        //Carga inicial
        const request = {
            startDate: this.state.startDate == null ? "0" : this.state.startDate,
            endDate: this.state.endDate == null ? "0" : this.state.endDate,
            title: this.state.title == "" ? "0" : this.state.title,
            stat: this.state.status,
            dates:"0"
        }

        this.handleFetch(request);

        //Get titles
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 16 }, true).then(response => {
        if (response.status === 200) {
            this.setState({ titles: response.data })
        }
        });

        //Get statuses
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 2 }, true).then(response => {
            if (response.status === 200) {
                this.setState({ statuses: response.data })
            }
            }
        );
        
    }

    addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    //Llamada de busqueda
    searchs = () => {
        var sd = this.state.startDate === null ? "0" : new Date(this.state.startDate);
        var ed = this.state.endDate === null ? "0" : new Date(this.state.endDate)

        let dates = ""
        if(sd == "0" && ed == "0"){
            dates = "0"
        }else{
            dates = "1"
        }

        const request = {
            startDate: sd === "0" ? "0" :  sd.getFullYear() + "-" + ((sd.getMonth() + 1) > 9 ? '' : '0') + (sd.getMonth() + 1) + "-" + ((sd.getDate()) <= 9 ? '0' : '') + sd.getDate() + " " + this.addZero(sd.getHours()) + ":" + this.addZero(sd.getMinutes()) +":"+ this.addZero(sd.getSeconds()),
            endDate: ed === "0" ? "0" :  + ed.getFullYear() + "-" + ((ed.getMonth() + 1) > 9 ? '' : '0') + (ed.getMonth() + 1) + "-" + ((ed.getDate()) <= 9 ? '0' : '') + ed.getDate()+ " " + this.addZero(ed.getHours()) + ":" + this.addZero(ed.getMinutes()) +":" + this.addZero(ed.getSeconds()),
            title: this.state.title == "" ? "0" : this.state.title,
            stat: this.state.status,
            dates:dates
        }
            
        const ds = new Date(sd);
        const df = new Date(ed);

        if (ds.getTime() > df.getTime()) {
            this.setState({ openError: true, isLoading: false, errorMessage: "La fecha de inicio no puede ser menor a la fecha final" });
        } else {
            this.handleFetch(request);
        }
    
    }

    //AHDA 31/05/2019: Added method for download csv 
    //-------------------------------- Download CSV 
    handleDownload = (data) => {
        const body = {
            id_usuario: returnCurrentUserId(),
            user_action: 3,
            work_id: 0,
            portal: 'obras',
            screen: 'reporteBitacoraObras'
        }
        apiPOST('/audit/add', body);
      
        const dataCsv = [[...this.state.columns]]
    
        for (const x in data) {
            const object = []
            object.push(data[x])
            dataCsv.push(object);
        }
    
        const download = document.createElement("a");
        document.body.appendChild(download);
        const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
        download.href = window.URL.createObjectURL(blob);
        download.download = "BitacoraDeObras.csv";
        download.click();
    }

    render() {
        const { classes } = this.props;
        const { columns, data, selected } = this.state;
        const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            print: false,
            rowsPerPage: 10,
            page: 0,
            selectableRows: false,
            textLabels: textLabelsEs, //AHDA 31/05/2019
            customToolbar: () => {
              return (
                <Tooltip title={"Descargar"} placement="bottom">
                    <IconButton aria-label="Download" onClick={() => this.handleDownload(this.state.dataCsv)}>
                    <CloudDownload />
                    </IconButton>
                </Tooltip>
                )
            } //AHDA 31/05/2019
        };
        return (
            <div className="marginTopLogo">
                <PapperBlock title="Reportes" whiteBg desc="" >
                    {/*AHDA 27/05/2019: Added loading*/}
                    {this.state.isLoading ? <LoaderFull /> : ''}
                    {/* Inicio Filtros */}
                    <form className={classes.container}>
                        <Grid
                            container
                            spacing={24}
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                            <Grid item xs={6} sm={3} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                keyboard={false}
                                helperText="Inicio Movimiento"
                                className={classes.formControl}
                                format="DD/MM/YYYY hh:mm:ss"
                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                value={this.state.startDate}
                                onChange={this.handleDateChange}
                                disableOpenOnEnter
                                animateYearScrolling={false}
                                clearable={true}
                                cancelLabel="Cancelar"
                                clearLabel="Limpiar"
                                placeholder="DD/MM/AAAA hh:mm:ss"
                                />
                            </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                keyboard={false}
                                helperText="Fin Movimiento"
                                className={classes.formControl}
                                format="DD/MM/YYYY hh:mm:ss"
                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                value={this.state.endDate}
                                onChange={this.handleDateChangeFin}
                                disableOpenOnEnter
                                animateYearScrolling={false}
                                clearable={true}
                                cancelLabel="Cancelar"
                                clearLabel="Limpiar"
                                placeholder="DD/MM/AAAA hh:mm:ss"
                                />
                            </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                                <div className={classes.root}>
                                    <Downshift selectedItem={this.state.title} onStateChange={this.handleStateChange}>
                                    {({
                                        getInputProps,
                                        getItemProps,
                                        isOpen,
                                        inputValue,
                                        selectedItem,
                                        highlightedIndex
                                    }) => (
                                        <div className={classes.formControl}>
                                            {renderInput({
                                            fullWidth: true,
                                            classes,
                                            helperText: 'Título de la obra',
                                            InputProps: getInputProps({
                                                placeholder: 'Título de la obra',
                                                id: 'user',
                                                name: 'user',
                                            }),
                                            })}
                                            {isOpen ? (
                                            <Paper className={classes.paper} square>
                                                {getSuggestions(inputValue, this.state.titles).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({ item: suggestion.label }),
                                                    highlightedIndex,
                                                    selectedItem,
                                                }),
                                                )}
                                            </Paper>
                                            ) : null}
                                        </div>
                                        )}
                                    </Downshift>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                                <FormControl className={classes.formControl}>
                                    <Select value={this.state.status} onChange={this.handleChange} name="status" displayEmpty className={classes.selectEmpty}>
                                    {/*AHDA 27/05/2019: Changed value*/}
                                    <MenuItem value={'0'}>Todos</MenuItem>
                                    {
                                        this.state.statuses.map((statu, idx) => (
                                        <MenuItem value={statu.value} key={`statu${idx}`}>{statu.data}</MenuItem>))
                                    }
                                    </Select>
                                    <FormHelperText>Estatus de la obra</FormHelperText>
                                </FormControl>
                            </Grid>
                            
                        </Grid>
                        <Grid
                            container
                            spacing={24}
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                            {/* CH 05/04/2019
                            Se centran botones en versión móvil */}
                            <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                            <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                                {/* {lang.common.search} */}
                                Buscar
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                                Limpiar
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                    {/* Fin Filtros */}
                    <div className={classes.bitacoraMov}>
                    <MUIDataTable
                        title={`Bitácora de obras (${this.state.data.length} resultados)`}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.openError}
                        autoHideDuration={6000}
                        onClose={this.handleCloseError}>
                        <CustomNotification
                            onClose={this.handleCloseError}
                            variant="error"
                            message={this.state.errorMessage} />
                    </Snackbar>
                    <Dialog
                          open={this.state.openDetailDialog}
                          onClose={this.handleCloseStep}
                        //   TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                              <Typography variant="h6" color="inherit" className={classes.flex}>
                                Detalle de comentarios
                              </Typography>
                              <IconButton color="inherit" onClick={this.handleCloseStep} aria-label="Close">
                                <CloseIcon />
                              </IconButton>
                            </Toolbar>
                          </AppBar>
                        <div className={classes.detailContainer}>
                            <div className={classes.detailRowContainer}>
                                <strong>{'Título de la obra: '}</strong>
                                {!!selected && selected.title && <span>{selected.title}</span>}
                            </div>
                            <div className={classes.detailRowContainer}>
                                <strong>{'Fecha: '}</strong>
                                {!!selected && selected.date && <span>{selected.date}</span>}
                            </div>
                            <div className={classes.detailRowContainer}>
                                <strong>{'Comentarios: '}</strong>
                                {!!selected && selected.comment && <span>{selected.comment}</span>}
                            </div>
                            <div className={classes.detailRowContainer}>
                                <strong>{'Usuario: '}</strong>
                                {!!selected && selected.userName && <span>{selected.userName}</span>}
                            </div>
                        </div>
                    </Dialog>
                </PapperBlock>
            </div>
        );
      }
}

export default withStyles(styles)(BitacoraObras);