import React from 'react';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import styles from './reportePais-jss'

class AdvFilter extends React.Component {
  state = {
    
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="marginTopLogo">
      <PapperBlock title="Reportes" whiteBg desc="">
        <div className={classes.reportePais}>
          
        </div>
      </PapperBlock>
      </div>
    );
  }
}

export default withStyles(styles)(AdvFilter);