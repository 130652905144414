import cyan from '@material-ui/core/colors/cyan';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      height: 470
    },
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing.unit * 1,
    '& label': {
      fontSize: 12
    },
    '& div': {
      fontSize: 14,
      marginTop: 10
    },
    '& p': {
      fontSize: 11,
      marginTop: 4
    }
  },
  loginWrap: {
    [theme.breakpoints.up('md')]: {
      width: 860
    },
  },
  formWrap: {
    maxHeight: '550px',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      marginTop: -24
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing.unit * 3
    },
  },
  btnArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // paddingBottom: '2rem !important',
      '& button': {
        width: '100%',
        margin: 5,
      },
      '& button:nth-child(1)': {
        marginLeft: 'unset',
      },
      '& button:nth-child(2)': {
        marginLeft: 'unset !important',
      },
    },
    '& button:nth-child(2)': {
      marginLeft: theme.spacing.unit
    }
  },
  noMargin: {
    margin: 0,
    marginTop: '1rem'
  },
  marginB:{
    marginBottom: theme.spacing.unit * 2
  },
  optArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
  },
  redBtn: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  blueBtn: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
    '&:hover': {
      backgroundColor: indigo[700],
    },
  },
  cyanBtn: {
    color: theme.palette.getContrastText(cyan[700]),
    backgroundColor: cyan[500],
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  help: {
    fontSize: 14,
    textAlign: 'center',
  },
  footer: {
    textAlign: 'center',
    marginTop: 12,
    padding: 5,
    background: theme.palette.grey[100],
    fontSize: 14,
    position: 'relative'
  },
  welcomeWrap: {
    position: 'relative'
  },
  welcome: {
    background: `rgba(255, 255, 255, 0.6) no-repeat center`,
    position: 'absolute',
    width: '100%',
    height: 'calc(100% + 30px)',
    padding: '20px 50px',
    top: -15,
    boxShadow: theme.shadows[5],
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: 20,
    '& img': {
      width: 32
    },
    '& h3': {
      fontSize: 18,
      margin: 0,
      paddingLeft: 10,
      fontWeight: 500,
      color: theme.palette.grey[700]
    }
  },
  brandText: {
    marginTop: 10,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  decoBottom: {
    fontSize: 480,
    position: 'absolute',
    left: 10,
    bottom: -190,
    opacity: 0.1,
    color: theme.palette.secondary.dark
  },
  tab: {
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * -3,
    },
  },
  link: {
    fontSize: 12,
    marginLeft: -30,
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  socMedFull: {
    marginBottom: theme.spacing.unit * 2
  },
  lockWrap: {
    textAlign: 'center',
    padding: theme.spacing.unit * 3
  },
  avatar: {
    width: 150,
    height: 150,
    margin: '5px auto 30px',
    [theme.breakpoints.up('md')]: {
      margin: '-75px auto 30px',
    },
    boxShadow: theme.shadows[8]
  },
  userName: {
    marginBottom: theme.spacing.unit * 3
  },
  hint: {
    padding: theme.spacing.unit
  },
  center: {
    textAlign: 'center'
  },
  w100: {
    width: '100%',
  },
    // CH 08/05/2019
    // Se agregan estilos para términos y condiciones 
  contentLegal: {
    //marginLeft: theme.spacing.unit * 3,
    //marginTop: theme.spacing.unit * 2,
  },
  headingExpand: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.primary.main
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  paper: {
    position: 'absolute',
    width: "50%",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    }
  },
  bgTitle: {
    backgroundColor: theme.palette.primary.dark,
    height: 50,
    padding: '2px 15px',
    color: '#fff',
    '& h6':{
      display: 'inline-block',
      paddingTop: 10
    }
  },
  flexHead: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  colorTitle: {
    color: "white",
    lineHeight: 'normal'
  },
  bgContenido: {
    padding: 15,
    maxHeight: 500,
    overflow: 'auto',
  },
  titleContenido: {
    color: '#4a4949',
    fontSize: 12,
    fontWeight: 'bold',
  },
  textContenido: {
    color: '#7d7d7d',
    fontSize: 12,
    textAlign: 'justify',
  },
  brandMovil: {
    alignItems: 'center',
    display: 'flex',
    height: 139,
    justifyContent: 'center',
    padding: '10px 10px 5px',
    position: 'relative',
    '& img': {
      width: 150,
    },
  },
  logoObrasContainer: {
    marginBottom: theme.spacing.unit * 2,
    maxWidth: 240,
    margin: '0 auto',
    '&:after': {
      background: theme.palette.warning.main,
      content: "''",
      display: "block",
      height: 4,
      width: '20%'
    },
  },
  btnActiveDirectory: {
    marginBottom: theme.spacing.unit * 2,
    textTransform: 'none !important'
  }
});

export default styles;
