import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {LanguageConsumer} from './../../language/LanguageContext'; 
// Import icon
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Menu Object
import MenuContent from '../../utils/menu';
import styles from './sidebar-jss';
import { apiPOST, returnCurrentUserId } from '../../services/service_base';
/*function sortByKey(array, key) {
  return array;
}*/

class MainMenu extends React.Component {
  constructor(props){
    super(props); 
    
    this.state = {
      dense: true, 
      open: false,
      dynamicMenu:[]
    }
  }

  componentDidMount(){

    let user = JSON.parse(localStorage.getItem('current_user'));
    let auxMenu = new Array();
    //AHDA 15/04/2019
    //Added petition to get configuration and add notifications option 
    let auxConfiguration = undefined; 

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/notifications/getConfiguration', { portal: "obras", user_id: returnCurrentUserId() }, true).then(
      res => {
        auxConfiguration = res.data; 
      }
    ).catch(err => {
      auxConfiguration = undefined; 
    }).finally(() => {
      //AHDA 17/05/2019: added validation for refresh 
      apiPOST("/roles/getmenuaction",{
        rolId : user.rdsUser.id_rol
      }, true).then(response => {
        let menuPermissions = response.data;
        localStorage.setItem("user_permissions", JSON.stringify(response.data));
        if(menuPermissions != null){
          MenuContent.map(item => {
            //AHDA 28/05/2019: If changes in item are necessary, use this
            let auxItem = Object.assign({}, item); 
            
            //Find menu
            let permission = menuPermissions.filter(x => x.menu_id === item.id)[0];
            
            //Check configuration permissions 
            if(item.id === 0){
              
              let aux = [];
              item.child.map(item =>{
                let submenu = menuPermissions.filter(x => x.menu_id === item.id)[0];
                if(submenu != undefined){
                                  
                  let c = JSON.parse(submenu.grant_configuration);
                  
                  if(submenu.menu_id === 15){
                    if(c.chkViewProfileAdmin){
                      aux.push(item);
                    }
                  }
  
                  if(submenu.menu_id === 14){
                    if(c.chkViewUser){
                      aux.push(item);
                    }
                  }
                  if(submenu.menu_id === 19){
                    if(c.chkViewParameter){
                      aux.push(item);
                    }
                  }
  
                  /*TODO ADD CONG NOTIG*/
                  if(submenu.menu_id === 16){
                    //isanchez 07.05.2019 double permissions
                    if(c.chkViewNotification || c.chkViewUserNoti){
                      aux.push(item);
                    }
                  }
                  
                }
              });
  
              if(aux.length>0){
                let i = item;
                i.child = aux;
                auxMenu.push(i);
              }
  
            }
  
            //Find menu 
            if(permission != undefined){
              let config = JSON.parse(permission.grant_configuration);
              
              if(item.id === 13){
                
                let auxChild = [];

                //AHDA 05/06/2019: Changed order
                if(config.chkEvalArea){
                  auxChild.push(item.child[0]);
                }
  
                if(config.chkHistMovement){
                  auxChild.push(item.child[1]);
                }

                if(config.chkHistWork){
                  auxChild.push(item.child[2]);
                }
  
                if(config.chkBuyWork){
                  auxChild.push(item.child[3]);
                }
  
                if(config.chkWorkAutor){
                  auxChild.push(item.child[4]);
                }
  
                if(config.chkStatusContr){
                  auxChild.push(item.child[5]);
                }

                if(config.chkDocStatus){
                  auxChild.push(item.child[6]);
                }

                if(config.chkWorkFlowPayment){
                  auxChild.push(item.child[7]);
                }

                if(config.chkLitWork){
                  auxChild.push(item.child[8]);
                }
                  
                if(auxChild.length>0){
                  //AHDA 28/05/2019: changed for auxChild
                  auxItem.child = auxChild;
                  auxMenu.push(auxItem);
                }
              }
              else if(item.id === 12){
                if(config.chkViewProfile){
                  auxMenu.push(item);
                }
              }
              else if(item.id === 17){
                if(config.chkViewCatalog){
                  auxMenu.push(item);
                }
              }
              else if(item.id === 16){
                if(config.chkViewNotification){
                  auxMenu.push(item);  
                }
              }
              //isanchez 05.05.2019
              else if(item.id === 21){
                if(config.chkViewAllWork){
                  auxMenu.push(item);
                }  
              }
              //AHDA 28/05/2019: Deleted repeated menu 16
              /*
                isanchez 8.04.2019 inicio
              */
              else if(item.id == 19){
                if(config.chkViewParameter){
                  auxMenu.push(item);
                }
              }
              /*
                isanchez 8.04.2019 fin
              */
              //AHDA 28/05/2019 
              else if(item.id == 11){
                if(config.chkViewWork){
                  auxMenu.push(item);
                }
              }
              else{
                auxMenu.push(item);
              }
            }
          });

          //AHDA 15/04/2019
          //Getting notification configuration 
          //Get notification configuration
          if(auxConfiguration){
            auxConfiguration = auxConfiguration.filter(f => f.send_web_usr === 1); 
            if(auxConfiguration.length > 0){
              //Get option menu for notifications 
              auxMenu.push(MenuContent.find(m => m.id === 20)); 
            }
          }
          this.setState({
            dynamicMenu:auxMenu
          });
        }
  
      }).catch(err => console.log("error menu action", err));
    }); 
  }

  handleClick = () => {
    this.props.toggleDrawerOpen();
    this.props.loadTransition(false);
  }

  handleClickExpand = key => {
    this.setState({[key]: !this.state[`${key}`]}); 
  }

  
  render() {
    const {
      classes, drawerPaper
    } = this.props;

    //AHDA 23/04/2019
    //Changed key of items 
    const renderSubmenu = child => child.map((item, index) => (
      <React.Fragment key={`submenu_${index}`}>
        <LanguageConsumer>
          {
            ({lang}) => 
            <ListItem
            button
            exact
            className={classNames(classes.nested, classes.pUnset)}
            activeClassName={classes.activeOrange}
            component={NavLink}
            to={`${item.link}`}
            onClick={() => this.handleClick()}
            dense={true}
            >
              {item.icon &&
                <ListItemIcon>
                  <Icon className={classes.icon}>{item.icon}</Icon>
                </ListItemIcon>
              }
              <ListItemText className={classNames(classes.primary, classes.paddingtext)} inset primary={lang.menu[`${item.key}`]} />
            </ListItem>
          }
        </LanguageConsumer>
      </React.Fragment>
    ))

    return (
      <LanguageConsumer>
        {
          ({lang}) =>
          <div>
            {this.state.dynamicMenu.map((item, index) => 
            
              (            
                //AHDA 23/04/2019
                //Changed key of items 
                <React.Fragment key={`menu_${index}`}>
                  {
                    item.child === undefined ? 
                    <ListItem
                    button
                    exact={true}
                    // CH 14/05/2019
                    // Se cambian estilos
                    className={classNames(classes.nested, "itemText")}
                    activeClassName={classes.active}
                    component={NavLink}
                    to={`${item.link}`}
                    onClick={() => this.handleClick()}
                    >
                      {item.icon &&
                        <ListItemIcon>
                          <Icon className={classes.icon}>{item.icon}</Icon>
                        </ListItemIcon>
                      }
                      <ListItemText classes={{ primary: classes.primary }} inset primary={lang.menu[`${item.key}`]} />
                    </ListItem> : 
                    <ListItem
                    button
                    className={classNames(classes.nested, "itemText")}
                    onClick={() => this.handleClickExpand(item.key)}
                    >
                      {item.icon &&
                        <ListItemIcon>
                          <Icon className={classes.icon}>{item.icon}</Icon>
                        </ListItemIcon>
                      }
                      <ListItemText classes={{ primary: classes.primary }} inset primary={lang.menu[`${item.key}`]} />
                      { this.state[`${item.key}`] ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                  }
                  {/*AHDA 14/05/2019: !drawerPaper ? false : */}
                  {
                    item.child !== undefined ? 
                    <Collapse in={this.state[`${item.key}`]} timeout="auto" unmountOnExit>
                      {renderSubmenu(item.child)}
                    </Collapse> : ''
                  }
                </React.Fragment>
              )
            )}
          </div>
        }
      
        
      </LanguageConsumer>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  drawerPaper: PropTypes.bool
};

export default withStyles(styles)(MainMenu);
