import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import Cover from './../../../components/Cover/Cover';
import { LanguageConsumer } from '../../../language/LanguageContext';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EdithProfile from './EditProfile';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark';
import IconButton from '@material-ui/core/IconButton';
import MaterialDropZone from '../../../components/Forms/MaterialDropZone';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from './perfil-jss';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { apiPOST, returnCurrentUserId } from '../../../services/service_base';
import { changePassword } from '../../../services/cognito_service';
import { saveFile } from '../../../services/storage';

import { textLabelsEs } from '../../../utils/tableTexts'; //JCMY 06/06/2019

import LoaderFull from '../../../components/Loader/LoaderFull';//JCMY 03/07/2019 incidencia 991

import NuevaObra from '../../../components/Works/NuevaObra';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UserProfile extends React.Component {
  state = {
    //View detail
    open: false,
    //Notifications
    openError: false,
    messageError: 'errMsg',
    variantError: 'error',

    // Date user
    user: 0,
    name: "",
    lastName: "",
    url: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    id_usuario: "",

    // Counter
    registry: "",
    evaluation: "",
    buy: "",
    devalued: "",

    // Componente img
    files: [],
    selectFile: {},

    // Modals
    ORegistro: false,
    OEvaluacion: false,
    OCompradas: false,
    ODevueltas: false,

    // Tablas
    columns: ['ID',
      {
        name: 'Título de la obra',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              // JCMY 18/06/2019 Incidencia televisa AC-913
              <a className="cursorPointer" onClick={() => this.handleClickOpenDetail(tableMeta.rowData)} >{value}</a>
            );
          }
        }
      },
      'Autor(es)/Nickname', 
      // 'Tipo de obra', 
      'Fecha de registro', 'Estatus'],
    dataRegistro: [],
    dataEvaluacion: [],
    dataCompradas: [],
    dataDevueltas: [],

    // JCMY 03/07/2019 incidencia 991
    loading: false
  };

  //AHDA 23/05/2019: Modified method to recieve modal and deleted extra methods
  //------------------------------ Modal
  handleClickOpen = (modal) => {
    this.setState({ [modal]: true });
  };

  // JCMY 03/04/2019
  handleClickOpenDetail = (work) => {
    const auditBody = {
      id_usuario: returnCurrentUserId(),
      user_action: 18,
      work_id: work[0],
      portal: 'obras',
      screen: 'registeredWorks'
    }
    apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));

    this.setState({ openStep: true, workId: work[0], workTitle: work[1] });
  };

  handleCloseStep = () => {
    this.setState({ openStep: false });
  };



  handleClose = (modal) => {
    this.setState({ [modal]: false });
  }
  //------------------------------ End Modal

  handleClear = (event) => {
    this.setState({ files: [], selectFile: {}, oldPassword: "", newPassword: "", confirmPassword: "" });
  }

  handleCloseNotification = () => {
    this.setState({ openError: false });
  }

  handledOpenForm = () => {
    this.setState({ openForm: true })
  }

  handledCloseForm = () => {
    this.setState({
      openForm: false
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickShowOldPassword = () => {
    this.setState(state => ({ showOldPassword: !state.showOldPassword }));
  };

  handleClickShowNewPassword = () => {
    this.setState(state => ({ showNewPassword: !state.showNewPassword }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
  };

  handleSubmit = (event) => {
    this.setState({ loading: true })

    // Update user
    const request = {
      user_id: this.state.id_usuario,
      new_name: this.state.name,
      new_last_name: this.state.lastName,
      user_who_updates: this.state.id_usuario,
      portal: "obras",
      screen: "profile",
      new_portrait: this.state.url
    }

    const user = this.state.user
    const files = this.state.files.length

    //AHDA 24/05/2019
    const oldPassword = this.state.oldPassword
    const newPassword = this.state.newPassword
    const confirmPassword = this.state.confirmPassword
    let errPwd = false;

    if (oldPassword !== "" && newPassword !== "" && confirmPassword !== "") {
      const u = {}
      u.oldPassword = oldPassword;
      u.newPassword = newPassword;
      u.username = user.rdsUser.usuario_cognito;

      changePassword(u).then(
        response => {
          const body = {
            id_usuario: returnCurrentUserId(),
            user_action: 19,
            work_id: 0,
            portal: 'obras',
            screen: 'profile'
          }
          apiPOST('/audit/add', body);
 
          this.setState({ oldPassword: "", newPassword: "", confirmPassword: "", openForm: false, loading: false, variantError: "success", openError: true, messageError: "Información actualizada exitosamente" });
        }
      ).catch(
        error => {
          if (error.status === 401) {
            errPwd = true;
            this.setState({ openError: true, loading: false, messageError: "La contraseña anterior no coincide" });
          } else if (error.status === 400) {
            errPwd = true;
            this.setState({ openError: true, loading: false, messageError: "La contraseña capturada debe ser distinta a las últimas 6 registradas" });
          } else {
            this.setState({ openError: true, loading: false, messageError: "Algo salió mal, intenta de nuevo más tarde" });
          }
        }
      );
    }

    if (files > 0) {
      // Upload img
      const date = new Date();

      saveFile('profilePicture', this.state.selectFile, 'public', date.getTime() + "" + request.user_id).then(
        response => {
          request.new_portrait = response.key
          let key = response.key

          apiPOST('/users/update', request).then(
            response => {
              if (response.status === 200) {
                const user = JSON.parse(localStorage.getItem('current_user'));

                user.rdsUser.nombre = response.data[0].nombre
                user.rdsUser.apellidos = response.data[0].apellidos
                user.rdsUser.key = key

                localStorage.setItem('current_user', JSON.stringify(user));

                //AHDA 24/05/2019: Parche para no recargar si hay error en la contraseña
                if (!errPwd)
                  window.location.reload();

              } else {
                this.setState({ openError: true, loading: false, errorMessage: "Ocurrió un error al actualizar datos" });
              }
            }).catch(error => {
              this.setState({ openError: true, loading: false, errorMessage: "Ocurrió un error inesperado" });
            });
        }).catch(
          err => {
            this.setState({ openError: true, loading: false, errorMessage: "Ocurrió un al subir la imagen" });
          })
    } else {

      if (request.new_name !== user.rdsUser.nombre || request.new_last_name !== user.rdsUser.apellidos) {

        request.new_portrait = user.rdsUser.key

        apiPOST('/users/update', request).then(
          response => {
            if (response.status === 200) {
              const user = JSON.parse(localStorage.getItem('current_user'));

              user.rdsUser.nombre = response.data[0].nombre
              user.rdsUser.apellidos = response.data[0].apellidos
              user.rdsUser.portrait = user.rdsUser.portrait

              localStorage.setItem('current_user', JSON.stringify(user));

              //AHDA 24/05/2019: Parche para no recargar si hay error en la contraseña
              if (!errPwd)
                window.location.reload();

            } else {
              this.setState({ openError: true, loading: false, errorMessage: "Ocurrió un error al actualizar datos" });
            }
          }).catch(error => {
            this.setState({ openError: true, loading: false, errorMessage: "Ocurrió un error inesperado" });
          });
      }
    }
  }


  //--------------------------- Actions img 
  onDrop = (files) => {
    if(files.length>0){
      let oldFiles = this.state.files;
      if (oldFiles.length === 0) {
        oldFiles = oldFiles.concat(files);
        this.setState({ selectFile: oldFiles[0], files: oldFiles });
      } else {
        this.setState({ openError: true, variantError: "warning", messageError: "Solo es aceptado un archivo" });
      }
    }else{
      this.setState({ openError: true, variantError: "warning", messageError: "Archivo no válido. Solo se aceptan imágenes" });

    }
  }

  handleRemove = (file, fileIndex) => {
    let oldFiles = this.state.files;
    window.URL.revokeObjectURL(file.preview);
    oldFiles.splice(fileIndex, 1);
    this.setState({ selectFile: {}, files: oldFiles });
  }
  //--------------------------- End Actions img 

  //Life cycle
  componentDidMount() {
    // Get user
    const user = JSON.parse(localStorage.getItem('current_user'));

    apiPOST('/works/getregisteredworks').then(
      response => {
        const counts = response.data;
        // JCMY 08/05/2019
        let registry = 0
        let evaluation = 0
        let purchasingProcess = 0
        let buy = 0
        let devalued = 0

        let registryList = []
        let evaluationList = []
        let buyList = []
        let devaluedList = []

        for (const x in counts) {
          // JCMY 08/05/2019
          let obj = []
          obj.push(counts[x].id_obras)
          obj.push(counts[x].titulo)
          obj.push(counts[x].autores)
          //obj.push(counts[x].estatus)
          obj.push(counts[x].fechaRegistro)
          obj.push(counts[x].estatus)

          if (counts[x].estatus == 'No viable' //|| counts[x].estatus == 'Más material'
          ) {
            devalued = devalued + 1
            devaluedList.push(obj)
          }
          console.log(counts[x])
          switch (counts[x].estatus) {
            case "Registrada":
            case "Más material":
            case "Solicitar ajustes":
              registry = registry + 1;
              registryList.push(obj)
              break;
            case "Asignada":
            case "Pre evaluada":
              evaluation = evaluation + 1
              evaluationList.push(obj)
              break;
            case "Solicitud de elaboración de contrato":
            case "En firmas":
            case "En proceso de pago":
              buy = buy + 1
              buyList.push(obj)
              break;
            default:
              break;
          }
        }

        this.setState({
          name: user.rdsUser.nombre,
          lastName: user.rdsUser.apellidos,
          id_usuario: user.rdsUser.id_usuario,
          registry: registry,
          evaluation: evaluation,
          //purchasingProcess: purchasingProcess,
          buy: buy,
          devalued: devalued,
          url: user.rdsUser.portrait,
          user: user,
          dataRegistro: registryList,
          dataEvaluacion: evaluationList,
          dataCompradas: buyList,
          dataDevueltas: devaluedList
        });
      }
    )



  }

  render() {
    const { classes, } = this.props;
    const { columns, dataRegistro, dataEvaluacion, dataCompradas, dataDevueltas } = this.state;
    const options = {
      filterType: 'dropdown',
      responsive: 'scroll',
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      download: false, //AHDA 27/05/2019
      print: false, //AHDA 27/05/2019
      textLabels: textLabelsEs //JCMY 06/06/2019
    };

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.newPassword) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('matchPolicy', (value) => {
      if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.;,:-_{}[/#&'¡¿()])[0-9A-Za-z@$!%*?.;,:-_{}[/#&'¡¿()]{12,}$/i.test(value) || value === "") {
        return true;
      }
      return false;
    });
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp', 'image/jpg'];

    const user = JSON.parse(localStorage.getItem('current_user'));
    return (
      <div>
        <LanguageConsumer>
          {
            ({ lang }) =>
              <React.Fragment>

                <Cover
                  avatar={user.rdsUser.key}
                  name={`${user.rdsUser.nombre} ${user.rdsUser.apellidos}`} handledOpenForm={this.handledOpenForm} />

                <Grid
                  container
                  alignItems="flex-start"
                  justify="flex-start"
                  direction="row"
                  spacing={16}
                  className={classes.gridContainer}
                >
                  {/* GEO 10/04/2019
                Se arregla incidencia en jira AC-410 */}
                  <Grid item xs={12} sm={6} md={3} lg={3} className={classes.alignFlex}>


                    <Dialog
                      fullScreen
                      open={this.state.openStep}
                      onClose={this.handleCloseStep}
                      TransitionComponent={Transition}
                    >
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <Typography variant="h6" color="inherit" className={classes.flex}>
                            {this.state.workTitle}
                          </Typography>
                          <IconButton color="inherit" onClick={this.handleCloseStep} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>


                      <NuevaObra onClose={this.handleCloseStep} workId={this.state.workId} />

                    </Dialog>


                    {/* CH 08/05/2019
                      Se agrega modal con tabla */}
                    <Card onClick={() => this.handleClickOpen("ORegistro")} className={classNames(classes.card, classes.width100)}>
                      <CardContent className={classes.content}>
                        <List className={classes.root}>
                          <ListItem>
                            <Avatar className={classes.avatarBlue}>
                              <Assignment />
                            </Avatar>
                            <ListItemText primary={`Total: ${this.state.registry}`} secondary="Obras en registro" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                    {/* Modal Obras en registro */}
                    <Dialog
                      fullScreen
                      open={this.state.ORegistro}
                      onClose={() => this.handleClose("ORegistro")}
                      TransitionComponent={Transition}
                    >
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <Typography variant="h6" color="inherit" className={classes.flex}>
                            Detalles
                            </Typography>
                          <IconButton color="inherit" onClick={() => this.handleClose("ORegistro")} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <div className={classes.detailContainer}>
                        {/*AHDA 27/05/2019: Added counter*/}
                        <MUIDataTable
                          // JCMY 02/07/2019 incidencia 963
                          title={`Obras en registro (${dataRegistro.length} resultados)`}
                          data={dataRegistro}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </Dialog>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className={classes.alignFlex}>
                    <Card onClick={() => this.handleClickOpen("OEvaluacion")} className={classNames(classes.card, classes.width100)}>
                      <CardContent className={classes.content}>
                        <List className={classes.root}>
                          <ListItem>
                            <Avatar className={classes.avatarBlue}>
                              <AssignmentTurnedIn />
                            </Avatar>
                            <ListItemText primary={`Total: ${this.state.evaluation}`} secondary="Obras en evaluación" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                    {/* Modal Obras en evaluacion */}
                    <Dialog
                      fullScreen
                      open={this.state.OEvaluacion}
                      onClose={() => this.handleClose("OEvaluacion")}
                      TransitionComponent={Transition}
                    >
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <Typography variant="h6" color="inherit" className={classes.flex}>
                            Detalles
                            </Typography>
                          <IconButton color="inherit" onClick={() => this.handleClose("OEvaluacion")} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <div className={classes.detailContainer}>
                        {/*AHDA 27/05/2019: Added counter*/}
                        <MUIDataTable
                          title={`Obras en evaluación (${dataEvaluacion.length} resultados)`}
                          data={dataEvaluacion}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </Dialog>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className={classes.alignFlex}>
                    <Card onClick={() => this.handleClickOpen("OCompradas")} className={classNames(classes.card, classes.width100)}>
                      <CardContent className={classes.content}>
                        <List className={classes.root}>
                          <ListItem>
                            <Avatar className={classes.avatarBlue}>
                              <CollectionsBookmark />
                            </Avatar>
                            <ListItemText primary={`Total: ${this.state.buy}`} secondary="Obras compradas" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                    {/* Modal Obras compradas */}
                    <Dialog
                      fullScreen
                      open={this.state.OCompradas}
                      onClose={() => this.handleClose("OCompradas")}
                      TransitionComponent={Transition}
                    >
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <Typography variant="h6" color="inherit" className={classes.flex}>
                            Detalles
                            </Typography>
                          <IconButton color="inherit" onClick={() => this.handleClose("OCompradas")} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <div className={classes.detailContainer}>
                        {/*AHDA 27/05/2019: Added counter*/}
                        <MUIDataTable
                          title={`Obras compradas (${dataCompradas.length} resultados)`}
                          data={dataCompradas}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </Dialog>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className={classes.alignFlex}>
                    <Card onClick={() => this.handleClickOpen("ODevueltas")} className={classNames(classes.card, classes.width100)}>
                      <CardContent className={classes.content}>
                        <List className={classes.root}>
                          <ListItem>
                            <Avatar className={classes.avatarBlue}>
                              <AssignmentReturned />
                            </Avatar>
                            <ListItemText primary={`Total: ${this.state.devalued}`} secondary="Obras devueltas" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                    {/* Modal Obras devueltas */}
                    <Dialog
                      fullScreen
                      open={this.state.ODevueltas}
                      onClose={() => this.handleClose("ODevueltas")}
                      TransitionComponent={Transition}
                    >
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <Typography variant="h6" color="inherit" className={classes.flex}>
                            Detalles
                            </Typography>
                          <IconButton color="inherit" onClick={() => this.handleClose("ODevueltas")} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <div className={classes.detailContainer}>
                        {/*AHDA 27/05/2019: Added counter*/}
                        <MUIDataTable
                          title={`Obras devueltas (${dataDevueltas.length} resultados)`}
                          data={dataDevueltas}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </Dialog>
                  </Grid>
                </Grid>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleCloseNotification}>
                  <CustomNotification
                    onClose={this.handleCloseNotification}
                    variant={this.state.variantError}
                    message={this.state.messageError} />
                </Snackbar>
                {this.state.loading ? <LoaderFull /> : ''}
              </React.Fragment>

          }

        </LanguageConsumer>

        <EdithProfile
          openForm={this.state.openForm}
          closeForm={this.handledCloseForm}
          classes={classes}
          submit={this.handleSubmit}
          clearForm={this.handleClear}
          title="Editar Perfil"
        >
          <div>
            <Grid container spacing={24}>
              <Grid item md={12}>
                <div>
                  <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN</Typography>
                  <MaterialDropZone
                    onDrop={this.onDrop}
                    onRemove={this.handleRemove}
                    files={this.state.files}
                    showPreviews
                    maxSize={93000000}
                    filesLimit={1}
                    text="Arrastra tu imagen o da click aquí"
                    accept={acceptedFiles.join(',')}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={6}>
                {/*AHDA 24/05/2019: changed validations*/}
                <TextValidator label="Nombre*" placeholder="Nombre" id="name" name="name"
                  value={this.state.name}
                  className={classNames(classes.field, classes.w100)}
                  onChange={this.handleChange}
                  validators={['required']}
                  errorMessages={['*Campo requerido']}
                  InputProps={
                    {
                      inputProps: { maxLength: 75 }
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/*AHDA 24/05/2019: changed validations*/}
                <TextValidator label="Apellido*" placeholder="Apellido" id="lastName" name="lastName"
                  value={this.state.lastName}
                  className={classNames(classes.field, classes.w100)}
                  onChange={this.handleChange}
                  validators={['required']}
                  errorMessages={['*Campo requerido']}
                  InputProps={
                    {
                      inputProps: { maxLength: 75 }
                    }
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl className={classNames(classes.margin, classes.textField, classes.w100)}>
                  {/*AHDA 24/05/2019: changed validations*/}
                  <TextValidator
                    label="Contraseña Anterior"
                    id="oldPassword"
                    name="oldPassword"
                    value={this.state.oldPassword}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    type={this.state.showOldPassword ? 'text' : 'password'}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowOldPassword}
                          >
                            {this.state.showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { maxLength: 60 }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl className={classNames(classes.margin, classes.textField, classes.w100)}>
                  {/*AHDA 24/05/2019: changed validations*/}
                  <TextValidator
                    label="Nueva contraseña"
                    id="newPassword"
                    name="newPassword"
                    value={this.state.newPassword}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    validators={['matchPolicy: (' + this.state.newPassword + ')']}
                    errorMessages={['La contraseña debe tener números, caracteres especiales, letras mayúsculas y minúsculas; y longitud mínima de 12 caracteres']}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowNewPassword}
                          >
                            {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { maxLength: 60 }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl className={classNames(classes.margin, classes.textField, classes.w100)}>
                  {/*AHDA 24/05/2019: changed validations*/}
                  <TextValidator
                    label="Confirmar Contraseña"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    validators={['isPasswordMatch: (' + this.state.confirmPassword + ')']}
                    errorMessages={['La contraseña no coincide con la anterior']}
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                          >
                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { maxLength: 60 }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item md={12}>
              </Grid>
            </Grid>
          </div>
        </EdithProfile>
      </div>
    );
  }
}

UserProfile.propTypes = {
  dataProps: PropTypes.object,
  fetchData: PropTypes.func,
};



export default withStyles(styles)(UserProfile);
