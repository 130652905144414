import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import ArrowForward from "@material-ui/icons/ArrowForward";
import styles from "./user-jss";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import CustomNotification from "../Notification/CustomNotification";
import Loader from "../Loader/Loader";
import Typography from "@material-ui/core/Typography";

import { changePassword } from "../../services/cognito_service";
import { LanguageConsumer } from "../../language/LanguageContext";
import { apiPOST, returnCurrentUserId } from "../../services/service_base";

class ChangePasswordForm extends React.Component {
  state = {
    showPassword: false,
    showConfirm: false,
    showNew: false,
    email: "",
    password: "",
    newPwd: "",
    confirmPwd: "",
    open: false,
    isLoading: false,
    //Errors
    errEmail: false,
    errMatch: false,
    errPattern: false,
    errPwd: false,
    errVariant: "error", //AHDA 23/05/2019
    errMsg: "errMsg", //AHDA 23/05/2019
  };

  //Controls
  handleClickShowPassword = (pwd) => {
    switch (pwd) {
      case "current":
        this.setState({ showPassword: !this.state.showPassword });
        break;
      case "new":
        this.setState({ showNew: !this.state.showNew });
        break;
      case "confirm":
        this.setState({ showConfirm: !this.state.showConfirm });
        break;
      default:
        break;
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      username: this.state.email,
      oldPassword: this.state.password,
      newPassword: this.state.newPwd,
    };

    if (
      !this.state.errEmail &&
      !this.state.errMatch &&
      !this.state.errPattern
    ) {
      this.setState({ isLoading: true });
      changePassword(user)
        .then((data) => {
          const body = {
            id_usuario: returnCurrentUserId(),
            user_action: 19,
            work_id: 0,
            portal: 'obras',
            screen: 'login'
          }
          apiPOST('/audit/add', body);
          window.location.href = "/reporte";
        })
        .catch((err) => {
          console.log(err);
          //AHDA 23/05/2019: Validation for password history
          if (err.status === 401) {
            this.setState({ errPwd: true, isLoading: false });
          } else if (err.status === 400) {
            this.setState({
              open: true,
              isLoading: false,
              errVariant: "error",
              errMsg: "pwdHistoryErr",
            });
          } else {
            //AHDA 23/05/2019: Added errVariant and errMsg
            this.setState({
              open: true,
              isLoading: false,
              errVariant: "error",
              errMsg: "errMsg",
            });
          }
        });
    }
  };

  handleBlur = (event) => {
    switch (event.target.name) {
      case "email":
        this.setState({
          errEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            event.target.value
          ),
        });
        break;
      case "confirmPwd":
        this.setState({
          errMatch: this.state.newPwd === event.target.value ? false : true,
        });
        break;
      //AHDA 14/05/2019
      case "newPwd":
        //! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~
        this.setState({
          errPattern: !/^(?=.*[\d])(?=.*[ÑA-Z])(?=.*[ña-z])(?=.*[@$!%*?.;,:-_{}[/#&'¡¿()+><|])[\wñÑ@$!%*?.;,:-_{}[/#&'¡¿()+><|]{12,}$/i.test(
            event.target.value
          ),
        });
        break;
      default:
        break;
    }
  };

  //AHDA 23/05/2019: Method for closing notifications
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <LanguageConsumer>
        {({ lang }) => (
          <React.Fragment>
            {this.state.isLoading ? (
              <Loader text={lang.login.logginIn} />
            ) : (
              <form onSubmit={this.handleSubmit}>
                <Typography variant="subtitle2" gutterBottom>
                  {lang.login.expiredPswdMsg}
                </Typography>
                <div>
                  <FormControl
                    className={classes.formControl}
                    error={this.state.errEmail}
                  >
                    <InputLabel htmlFor="email">{lang.common.email}</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      inputProps={{ maxLength: 50 }}
                    />
                    {this.state.errEmail ? (
                      <FormHelperText id="weight-helper-text">
                        {lang.common.emailValidation}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    className={classes.formControl}
                    error={this.state.errPwd}
                  >
                    <InputLabel htmlFor="password">
                      {lang.common.password}
                    </InputLabel>
                    <Input
                      id="password"
                      name="password"
                      value={this.state.password}
                      type={this.state.showPassword ? "text" : "password"}
                      required
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              this.handleClickShowPassword("current")
                            }
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={this.handleChange}
                      inputProps={{ maxLength: 60 }}
                    />
                    {this.state.errPwd ? (
                      <FormHelperText id="weight-helper-text" error>
                        {lang.login.incorrectUser}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    className={classes.formControl}
                    required={true}
                    error={this.state.errPattern}
                  >
                    <InputLabel htmlFor="new-pwd">
                      {lang.login.newPwd}
                    </InputLabel>
                    <Input
                      id="new-pwd"
                      value={this.state.newPwd}
                      type={this.state.showNew ? "text" : "password"}
                      name="newPwd"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() => this.handleClickShowPassword("new")}
                          >
                            {this.state.showNew ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ maxLength: 60 }}
                    />
                    {this.state.errPattern ? (
                      <FormHelperText id="weight-helper-text">
                        {lang.login.pwdPolicy}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    className={classes.formControl}
                    required={true}
                    error={this.state.errMatch}
                  >
                    <InputLabel htmlFor="confirm-pwd">
                      {lang.login.confirmPassword}
                    </InputLabel>
                    <Input
                      id="confirm-pwd"
                      name="confirmPwd"
                      value={this.state.confirmPwd}
                      type={this.state.showConfirm ? "text" : "password"}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              this.handleClickShowPassword("confirm")
                            }
                          >
                            {this.state.showConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ maxLength: 60 }}
                    />
                    {this.state.errMatch ? (
                      <FormHelperText id="weight-helper-text">
                        {lang.login.passNoMatch}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div className={classes.btnArea}>
                  {/*<FormControlLabel control={<Field name="remember" component={Checkbox} />} label={lang.login.remember} />*/}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="small"
                  >
                    {lang.common.continue}
                    <ArrowForward
                      className={classNames(
                        classes.rightIcon,
                        classes.iconSmall
                      )}
                    />
                  </Button>
                </div>
              </form>
            )}

            {/*for error notifications*/}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={this.state.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
            >
              {/*AHDA 23/05/2019: Changed for state variables*/}
              <CustomNotification
                onClose={this.handleClose}
                variant={this.state.errVariant}
                message={lang.common[this.state.errMsg]}
              />
            </Snackbar>
          </React.Fragment>
        )}
      </LanguageConsumer>
    );
  }
}

ChangePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangePasswordForm);
