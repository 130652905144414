import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles-jss';

import SweetAlert from 'sweetalert-react';
import '../../styles/components/vendors/sweetalert-react/sweetalert.css';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination'; //AHDA 24/05/2019
import Delete from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';//JCMY 06/06/2019
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton';
import ModeEdit from '@material-ui/icons/ModeEdit';
import Tooltip from '@material-ui/core/Tooltip';
//AHDA 05/05/2019
import Visibility from '@material-ui/icons/Visibility';

class TableCatalogue extends React.Component {
    state = {
        show: false,
        selected: null,
        //Pagination
        page: 0, //AHDA 24/05/2019
        rowsPerPage: 10 //AHDA 24/05/2019
    };

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.deleteItem(this.state.selected);
    }

    //AHDA 24/05/2019: Added pagination
    //--------------------------------- Pagination 
    handleChangePage = (event, page) => {
        //dataTable.body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    //--------------------------------- End pagination

    render() {

        //AHDA 05/05/2019
        //Added handleViewItem, showVisibility
        const { headers, classes, data, checkmarkList, canEditList, openForm, updateStatus,
            showDelete, workId, canEdit, canDelete, activeUser, showVisibility, handleViewItem, showActive } = this.props;
        const { page, rowsPerPage } = this.state; //AHDA 24/05/2019

        const renderRow = (item, canEdit) => {
            let dataBodyVal = Object.values(item);
            return dataBodyVal.map((da, id) => {
                return (
                    // JCMY 06/06/2019
                    // JCMY 11/07/2019 inciecnia 1019
                    da == "active" || da == "inactive" || da == "locked" ?
                        ''
                        :
                        (
                            <TableCell key={`cell_${id}`}>
                                {(() => {
                                    switch (da) {
                                        case 0:
                                            return <Switch disabled={!canEdit} checked={false} onClick={(event) => updateStatus(dataBodyVal, item, event)} id={`check_${Object.keys(item)[id]}`} value="checkedA" color="primary" />
                                        case 1:
                                            return <Switch disabled={!canEdit} checked={true} onClick={(event) => updateStatus(dataBodyVal, item, event)} id={`check_${Object.keys(item)[id]}`} value="checkedA" color="primary" />
                                        default:
                                            return da
                                    }
                                })()}
                            </TableCell>
                        )


                )
            })
        }

        //AHDA 24/05/2019: Added method 
        const renderBody = (data) => {
            return data.map((dat, idx) =>
            (
                <TableRow key={`row_${idx}`}>
                    {renderRow(dat, canEdit)}
                    <TableCell>

                        <div className={classes.actionsBtn}>
                            {/* JCMY 11/07/2019  incidencia 1019*/}
                            {/* {workId != 0 ? (  */}

                            {
                                canEdit ?
                                    dat.block === "locked" ? (
                                        // GEO 01/04/2019
                                        // Se agregan tooltips
                                        <Tooltip title="Editar">
                                            <IconButton disabled aria-label="Editar" onClick={() => openForm(dat)}>
                                                <ModeEdit />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!canEdit} aria-label="Editar" onClick={() => openForm(dat)}>
                                                <ModeEdit />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                    : null
                            }

                            {/* JCMY 11/07/2019 incidecnia 1019*/}
                            {
                                showDelete ?

                                    dat.block == "locked" ?
                                        <Tooltip title="Eliminar">
                                            <IconButton disabled aria-label="Eliminar" onClick={() => this.handleOpenSwal(dat)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Eliminar">
                                            <IconButton disabled={!canDelete} aria-label="Eliminar" onClick={() => this.handleOpenSwal(dat)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>

                                    : null
                            }

                            {/*AHDA 05/05/2019: Added visibility button*/}
                            {
                                showVisibility ?
                                    <Tooltip title="Ver">
                                        <IconButton disabled={!canDelete} aria-label="Ver" onClick={() => handleViewItem(dat)}>
                                            <Visibility />
                                        </IconButton>
                                    </Tooltip> : null
                            }


                            {/* JCMY 06/06/2019 */}
                            {
                                showActive ?
                                    <IconButton disabled={!canDelete} className={classes.cursorDefault}>
                                        {dat.active == "active" ? <CheckCircle /> : <Close />}

                                    </IconButton> : null
                            }

                        </div>
                    </TableCell>
                </TableRow>

            ))
        }

        return (

            <div className={classes.ajusteTabla}>
                <Table className={classNames(classes.table, classes.stripped)}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) =>
                            (
                                <TableCell key={`header_${idx}`}>{header}</TableCell>
                            ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*AHDA 24/05/2019: changed render body*/}
                        {data && renderBody(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}

                    </TableBody>

                    <SweetAlert
                        show={this.state.show}
                        customClass={classes.alertDialog}
                        title="¿Estás seguro?"
                        text="Una vez que elimines este elemento, no se podrá recuperar"
                        showCancelButton
                        onConfirm={() => {
                            this.handleConfirmSwal();
                        }}
                        onCancel={() => {
                            this.setState({ show: false, selected: null });
                        }}
                    />
                </Table>
                {/*AHDA 21/05/2019: Added pagination*/}
                <TablePagination
                    rowsPerPageOptions={[10, 15, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage={"Filas por página"}
                />
            </div>
        )
    }
}

//AHDA 05/05/2019
//Added handleViewItem
TableCatalogue.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    openForm: PropTypes.func,
    deleteItem: PropTypes.func,
    updateStatus: PropTypes.func,
    showDelete: PropTypes.bool,
    activeUser: PropTypes.bool,
    workId: PropTypes.number,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
    handleViewItem: PropTypes.func,
    showVisibility: PropTypes.bool,
    showActive: PropTypes.bool
};

//AHDA 05/05/2019
//Added handleViewItem
TableCatalogue.defaultProps = {
    openForm: () => { },
    deleteItem: () => { },
    updateStatus: () => { },
    showDelete: true,
    activeUser: true,
    handleViewItem: () => { },
    showVisibility: false,
    showActive: false
}

export default withStyles(styles)(TableCatalogue);

