import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from './cardStyle-jss';
import SweetAlert from 'sweetalert-react';

const optionsOpt = [
  'Eliminar',
];

const ITEM_HEIGHT = 48;

class NotifyCard extends React.Component {
  state = { anchorElOpt: null, show: false, selected: null };

  handleClickOpt = event => {
    this.setState({ anchorElOpt: event.currentTarget });
  };


  handleOpenSwal = (dat) => {
    this.setState({ show: true, selected: dat });
  }



  handleConfirmSwal = () => {
    this.setState({ show: false, anchorElOpt: null });
    this.props.deleteItem(this.state.selected);
  }

  handleCloseOpt = () => {

    this.setState({ anchorElOpt: null });
  };

  render() {
    const {
      classes,
      icon,
      name,
      notification,
      content
    } = this.props;
    const { anchorElOpt } = this.state;
    return (
      <Card className={classes.cardNotify}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              {icon}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="More"
              aria-owns={anchorElOpt ? 'long-menu' : null}
              aria-haspopup="true"
              className={classes.button}
              onClick={this.handleClickOpt}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={name}
          subheader={notification.date}
        />
        <Menu
          id="long-menu"
          anchorEl={anchorElOpt}
          open={Boolean(anchorElOpt)}
          onClose={this.handleCloseOpt}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          {optionsOpt.map(option => (
            <MenuItem key={option} selected={option === 'Edit Profile'} onClick={() => this.handleOpenSwal(notification)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <div className={classes.dividerCard}></div>
        <CardContent>
          <Typography component="p">
            {content}
          </Typography>
        </CardContent>

        <SweetAlert
          show={this.state.show}
          customClass={classes.alertDialog}
          title="¿Estás seguro?"
          text="Una vez que elimines este elemento, no se podrá recuperar"
          showCancelButton
          onConfirm={() => {
            this.handleConfirmSwal();
          }}
          onCancel={() => {
            this.setState({ show: false, selected: null });
          }}
        />


      </Card>

    );
  }
}

NotifyCard.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  content: PropTypes.string.isRequired,
  liked: PropTypes.number,
  shared: PropTypes.number,
  commented: PropTypes.number,
  deleteItem: PropTypes.func,
  notification: PropTypes.object
};

NotifyCard.defaultProps = {
  image: ''
};

export default withStyles(styles)(NotifyCard);
