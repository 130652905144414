const styles = (theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rootTable: {
    width: "100%",
    marginTop: 0,
    overflowX: "auto",
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: "inline-block",
  },
  contentStep: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  contentLegal: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  stepItem: {
    whiteSpace: "inherit",
  },
  detailContainer: {
    margin: "-16px auto 0",
    width: "100%",
    paddingTop: 40,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 960,
      minWidth: 960,
      paddingTop: 40,
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      //overflowX: 'hidden',
    },
  },
  bodyForm: {
    position: "relative",
    background: "#FFFFFF",
    padding: 10,
    maxHeight: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
    },
  },
  field: {
    width: "100%",
    marginBottom: 0,
    "& svg": {
      color: theme.palette.grey[400],
      fontSize: 18,
    },
  },
  fieldFlot: {
    width: "100%",
    marginBottom: 20,
    "& svg": {
      color: theme.palette.grey[400],
      fontSize: 18,
    },
  },
  headingExpand: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  finishText: {
    textAlign: "center",
    margin: theme.spacing.unit * 3,
    "& p": {
      marginBottom: 5,
      color: theme.palette.primary.main,
    },
  },
  // personajes
  paper: {
    padding: theme.spacing.unit * 2,
    height: "100%",
    color: theme.palette.text.secondary,
  },
  paperCharaters: {
    padding: theme.spacing.unit * 2,
    // height: '100%',
    color: theme.palette.text.secondary,
  },
  contentFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelTitle: {
    color: theme.palette.primary.main,
    //display: 'inline-block',
  },
  divider: {
    marginTop: 6,
    marginBottom: 6,
  },
  pt2: {
    paddingTop: "10px !important",
  },
  pb3: {
    paddingBottom: "20px !important",
  },
  rightAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  info: {
    color: "rgba(0, 0, 0, 0.38)",
    textAlign: "center",
  },
  listado: {
    listStyleType: "disc",
    marginLeft: "1em",
    fontSize: 14,
    "& li": {
      height: 50,
    },
    "& a": {
      color: "#424242",
    },
    "& a:hover": {
      borderBottom: "1px solid #6eb3d1",
    },
    "& a > span": {
      color: "#6eb3d1",
    },
    "& button": {
      float: "right",
      width: 30,
      height: 30,
      "& svg": {
        fontSize: 18,
      },
    },
  },
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  w100: {
    width: "100%",
  },
  videoContainer: {
    alignItems: "center",
    background: theme.palette.primary.main,
    display: "flex",
    flexFlow: "row nowrap",
    height: "120px",
    justifyContent: "center",
    objectFit: "cover",
    width: "100%",
    "& img": {
      margin: "auto",
      border: "none",
      cursor: "pointer",
      width: "50px",
    },
  },
  removePadding: {
    padding: "unset",
  },
  flexContainer: {
    flexFlow: "row wrap",
  },
  flexItem: {
    display: "flex",
    alignItems: "center !important",
  },
  colorSecondaryText: {
    color: "#a7a7a7",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  rootGrid: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "white",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  cajaDropZone: {
    "& div": {
      width: "100%",
    },
  },
  w100: {
    width: "100%",
  },
  paperTag: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  rightActionInline: {
    display: "inline-block",
    float: "right",
  },
  paperModal: {
    position: "absolute",
    width: "50%",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
  },
  bgTitle: {
    backgroundColor: theme.palette.primary.dark,
    height: 50,
    padding: "2px 15px",
    color: "#fff",
    "& h6": {
      display: "inline-block",
      paddingTop: 10,
    },
  },
  flexHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  colorTitle: {
    color: "white",
    lineHeight: "normal",
  },
  bgContenido: {
    padding: 15,
    maxHeight: 500,
    overflow: "auto",
  },
  titleContenido: {
    color: "#4a4949",
    fontSize: 12,
    fontWeight: "bold",
  },
  textContenido: {
    color: "#7d7d7d",
    fontSize: 12,
    textAlign: "justify",
  },
  textAlign: {
    textAlign: "right",
  },
  colorButton: {
    color: "white",
  },
  flexFooter: {
    display: "flex",
    justifyContent: "space-between",
    "& $button": {
      marginBottom: theme.spacing.unit,
    },
  },
  cajaDropZone: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: 16,
  },
  buttonSpacings: {
    marginLeft: theme.spacing.unit * 2,
  },
  actionButtons: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  flexRow: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  flexAlignCenter: {
    alignItems: "center",
  },
  pevCargarArchivo: {
    display: "flex",
    justifyContent: "flex-end",
  },
  pevRevisionTitle: {
    lineHeight: 1,
  },
  pevShare: {
    marginLeft: theme.spacing.unit,
  },
  pevTableAction: {
    marginLeft: theme.spacing.unit,
  },
  pevDataTable: {
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  alertDialog: {
    "& .confirm": {
      color: "white",
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  ellipsis: {
    "& span": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "calc(100% - 52px)",
      whiteSpace: 'nowrap',
    },
  },
  customList: {
    maxWidth: '100%',
    overflowY: 'auto',
    marginBottom: theme.spacing.unit * 1.5,
  }
});

export default styles;
