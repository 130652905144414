const drawerWidth = 240;
const styles = (theme) => ({
    usuario: {
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.dark,
        '& h4': {
            margin: 0,
            fontSize: '1em !important',
        },
        '& span': {
            fontSize: '.8rem !important',
        },
        [theme.breakpoints.up('lg')]: {
            color: theme.palette.primary.contrastText,
        },
    },
    user: {
        justifyContent: 'center',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    swipeDrawerPaper: {
        width: drawerWidth,
    },
    opened: {
        background: theme.palette.grey[200],
        '& $primary, & $icon': {
            color: theme.palette.secondary.main,
        },
    },
    drawerPaperClose: {
        width: 66,
        // antes absolute
        position: 'relative',
        // overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '& $user': {
            justifyContent: 'flex-start',
            //marginTop: 40,
        },
        '& $bigAvatar': {
            // width: 40,
            // height: 40,
            display: 'none',
        },
        '& $usuario': {
            display: 'none',
        },
        '& div div div div a': {
            display: 'none',
        },
        '& li ul': {
            display: 'none',
        },
        '&:hover': {
            width: '240px !important',
            //backgroundColor: '#ffffff',
            //boxShadow: theme.shadows[6],
            '& $bigAvatar': {
                margin: '0 auto',
                display: 'block',
            },
            '& li ul': {
                display: 'block',
            },
            '& $usuario': {
                display: 'block',
            },
            '& div div div div a': {
                display: 'block',
            },
        },
        '& $menuContainer': {
            paddingLeft: theme.spacing.unit * 1.5,
            paddingRight: theme.spacing.unit * 1.5,
        },
    },
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: drawerWidth,
        height: '100%',
    },
    drawerHeader: {
        //background: theme.palette.primary.main,
        //background: `url(${bgHeader1}) no-repeat right`,
        background: 'rgba(255, 255, 255, 0)',
        color: theme.palette.primary.contrastText,
        height: 184,
        padding: '0',
        ...theme.mixins.toolbar,
        '& h3': {
            color: theme.palette.primary.contrastText,
        },
    },
    avatar: {
        margin: 10,
        border: '2px solid #FF5A1A',
    },
    bigAvatar: {
        width: 80,
        height: 80,
    },
    brandBar: {
        transition: theme.transitions.create(
            ['width', 'margin', 'background'],
            {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            },
        ),
        '&:after': {
            transition: theme.transitions.create(['box-shadow'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    darker: {
        background: theme.palette.primary.dark,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit * 1.5,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& span, & svg': {
                color: 'white',
            },
            '& $primary, & $icon': {
                color: theme.palette.secondary.contrastText,
            },
        },
    },
    child: {
        '& a': {
            paddingLeft: theme.spacing.unit * 6,
        },
    },
    dense: {
        marginLeft: -15,
    },
    active: {
        backgroundColor: '#dadada',
        // '& $primary, & $icon': {
        //   color: theme.palette.secondary.contrastText,
        // },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& $primary, & $icon': {
                color: theme.palette.secondary.contrastText,
            },
        },
    },
    activeOrange: {
        '& span': {
            color: theme.palette.primary.main,
            fontWeight: 600,
        },
    },
    nolist: {
        listStyle: 'none',
    },
    primary: {},
    icon: {
        marginRight: 0,
        color: theme.palette.secondary.main,
    },
    head: {
        paddingLeft: 16,
    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 10px 5px',
        height: 64,
        position: 'relative',
        '& img': {
            width: 20,
        },
        '& h3': {
            fontSize: 16,
            margin: 0,
            paddingLeft: 10,
            fontWeight: 500,
        },
    },
    profile: {
        height: 120,
        display: 'flex',
        fontSize: 14,
        padding: 10,
        alignItems: 'center',
        '& h4': {
            fontSize: 18,
            marginBottom: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 110,
        },
        '& span': {
            fontSize: 12,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 110,
            display: 'block',
            overflow: 'hidden',
        },
    },
    menuContainer: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing.unit * 1.5,
        },
        paddingRight: theme.spacing.unit,
        overflow: 'auto',
        height: 'calc(100% - 185px)',
        position: 'relative',
        display: 'block',
    },
    divider: {
        marginTop: theme.spacing.unit,
    },
    paddingtext: {
        paddingLeft: '20px !important',
    },
    pUnset: {
        padding: '5px 0px 5px 16px !important',
    },
    unsetFlex: {
        display: 'unset',
    },
});

export default styles;
