import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';

class Construction extends React.Component{
    render(){
        const title = brand.name + ' - Página en construcción';
        const description = brand.desc;

        return(
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                {/*TODO: Maqueta para pagina en construccion*/}
            </div>
        )
    }
}

export default Construction; 