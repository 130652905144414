//Module by AHDA 19/02/2019
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from './../styles-jss';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Loader from '../../../../components/Loader/Loader';

//Table
import AddCatalogue from '../../../../components/Catalogue/AddCatalogue';
import Header from '../../../../components/Catalogue/Header';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';

//AHDA 15/04/2019: commented unused dependencies 
//Form
//import { InputLabel } from '@material-ui/core';
//import InputAdornment from '@material-ui/core/InputAdornment';
//import Sort from '@material-ui/icons/Sort';
import { TextValidator } from 'react-material-ui-form-validator';

//Services
import { apiPOST } from '../../../../services/service_base';
import { getFilteredData } from '../../../../helpers/general'; //AHDA 21/05/2019

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

class Catalogo extends React.Component {
    state = {
        //Open form
        openForm: false,
        //Table
        headers: ["ID", "Descripción", "Estatus", "Opciones"],
        data: [],
        rawData: [], //AHDA 24/05/2019
        //Selected catalogue item
        selected: null,
        proDescripcion: "",
        //Error 
        openError: false, 
        errType: "error", //AHDA 31/05/2019
        errMsg: "Ocurrió un error, intenta de nuevo más tarde", //AHDA 31/05/2019
        isLoading: false,
        chkDeleteCatalog: false,
        chkEditCatalog: false,
        chkNewCatalog: false
    };


    //Open and close form
    handledOpenForm = (item) => {
        if (item !== undefined) {
            this.setState({
                openForm: true,
                proDescripcion: item.descripcion,
                selected: item
            });
        }
        else {
            this.setState({ openForm: true, proDescripcion: "", selected: null });
        }
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    //Handle Form
    handleChange = (event) => {
        //AHDA 02/04/2019
        //Validation for single quote
        if(event.target.value.indexOf('\'') <= -1){
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleClear = (event) => {
        this.setState({ proDescripcion: "" });
    }

    //Notifications
    handleClose = () => {
        this.setState({openError: false}); 
    }

    //Catalogue CRUD 
    handleDeleteItem = (item) => {

        let body = {
            idCatalog: 5,
            id_record: item.id,
            new_description: item.descripcion,
            new_status: 2
        }

        apiPOST('/catalogue/update', body).then(
            response => {
                if(response.status === 200){
                    //AHDA 24/05/2019: Adjustments for search
                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id);
                    let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === item.id);

                    let auxData = [...this.state.data];
                    let auxRawData = [...this.state.rawData]; 

                    auxData.splice(auxIndex, 1);
                    auxRawData.splice(auxRawIdx, 1);

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                }
                else{
                    console.log(response)
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                console.log(err); 
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    handleUpdateStatus = (data, item) => {

        let body = {
            idCatalog: 5,
            id_record: item.id,
            new_description: item.descripcion,
            new_status: item.estatus === 1 ? 0 : 1
        }

        //Call to lambda 
        apiPOST('/catalogue/update', body).then(
            response => {
                if(response.status === 200){
                    //AHDA 24/05/2019: Adjustments for search
                    let auxData = [...this.state.data];
                    let auxRawData = [...this.state.rawData]; 

                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id);
                    let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === item.id);
                    
                    auxData[auxIndex] = response.data[0];
                    auxRawData[auxRawIdx] = response.data[0];
                    
                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                }
                else{
                    console.log(response)
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                console.log(err); 
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let body = {
            idCatalog: 5,
            id_record: this.state.selected ? this.state.selected.id : 0,
            new_description: this.state.proDescripcion,
            new_status: this.state.selected ? this.state.selected.estatus : 1
        }
        
        //Call to lambda -- when success it should do this 
        let auxData = [...this.state.data];
        let auxRawData = [...this.state.rawData];
         
        //AHDA 31/05/2019: Existing data validation
        const existsItem = auxRawData.filter(i => i.descripcion.toUpperCase() === this.state.proDescripcion.toUpperCase());
        if(existsItem.length > 0){
            this.setState({ openError: true, errType: "warning", errMsg: "Ya existe una opción con este nombre, favor de validar" });
            return false; 
        } 

        this.setState({isLoading: true}); 

        if (this.state.selected) {
            apiPOST('/catalogue/update', body).then(
                response => {
                    if(response.status === 200){
                        //AHDA 24/05/2019: Adjustments for search
                         
                        let auxIndex = this.state.data.findIndex(itm => itm.id === this.state.selected.id);
                        let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === this.state.selected.id);

                        auxData[auxIndex] = response.data[0];
                        auxRawData[auxRawIdx] = response.data[0];

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    }
                    else{
                        //console.log(response)
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //.log(err); 
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        } else {
            apiPOST('/catalogue/add', body).then(
                response => {
                    if(response.status === 200){
                        //AHDA 24/05/2019: Adjustments for search
                         
                        let auxItem = response.data[0]; 

                        auxData.push(auxItem);
                        auxRawData.push(auxItem); 
                        
                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    }else{
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        }
    }

    //AHDA 24/05/2019
    //------------------------------- Search
    handleSearch = (search) => {
        let key = search.toUpperCase(); 
        //Get all
        if(key === ""){
            this.setState({data: [...this.state.rawData]}); 
            return false; 
        }

        let auxData = [...this.state.rawData]; 

        auxData = getFilteredData(auxData, "descripcion", key); 
        this.setState({data: auxData}); 
    }
    //------------------------------- End Search

    componentDidMount(){
        //Set loading 
        let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 17)[0];
        let configPermission = JSON.parse(config.grant_configuration);
         
        this.setState({
            isLoading: true,
            chkDeleteCatalog: configPermission.chkDeleteCatalog,
            chkEditCatalog: configPermission.chkEditCatalog,
            chkNewCatalog: configPermission.chkNewCatalog
        }); 

        apiPOST('/catalogue/getComplete', { idCatalog: 5 }).then(
            response => {
                if(response.status === 200){
                    this.setState({isLoading: false, data: response.data, rawData: response.data});
                }else{
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde",});
                }
            }
        ).catch(
            err => {
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde",}); 
            }
        )
    }

    render() {

        const { classes } = this.props;

        return (
            <div className="marginTopLogo">

                <Paper className={classes.root}>
                    {/*AHDA 24/05/2019: Added search*/}
                    <Header
                        title="Géneros"
                        tooltip="Nuevo Género"
                        textBtn="Nuevo"
                        openForm={() => this.handledOpenForm(undefined)} 
                        workId = {0}
                        canAdd={this.state.chkNewCatalog}
                        search={this.handleSearch}

                        />
                    {
                        this.state.isLoading ? 
                        <Loader text={"Cargando"} /> : 
                        <TableCatalogue
                            headers={this.state.headers}
                            data={this.state.data}
                            openForm={this.handledOpenForm}
                            deleteItem={this.handleDeleteItem}
                            updateStatus={this.handleUpdateStatus}
                            workId = {0}
                            canEdit={this.state.chkEditCatalog}
                            canDelete={this.state.chkDeleteCatalog}
                        />
                    }
                </Paper>

                <AddCatalogue
                    openForm={this.state.openForm}
                    closeForm={this.handledCloseForm}
                    classes={classes}
                    submit={this.handleSubmit}
                    clearForm={this.handleClear}
                    title="Género"
                >
                    <div>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} md={12}>
                            {/*AHDA 02/04/2019
                            Empty string validation*/}
                                <TextValidator
                                    label="Descripción"
                                    placeholder="Ej."
                                    id="proDescripcion"
                                    name="proDescripcion"
                                    value={this.state.proDescripcion}
                                    className={classNames(classes.field, classes.w100)}
                                    onChange={this.handleChange}
                                    validators={['required', 'isSpaceEmpty']}
                                    errorMessages={['Campo requerido', 'Campo requerido']}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={
                                        {
                                          inputProps: { maxLength: 60 }
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                </AddCatalogue>
                {/*For notifications*/}
                {/*AHDA 31/05/2019: changed variant and message*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <CustomNotification
                        onClose={this.handleClose}
                        variant={this.state.errType}
                        message={this.state.errMsg} />
                </Snackbar>
            </div>
        )
    }
}

Catalogo.propTypes = {
    openNewPais: PropTypes.func,
};

export default withStyles(styles)(Catalogo);