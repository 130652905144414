const allDocuments = [
    {
        value: "actaNacimiento", 
        data: "Acta de nacimiento"
    }, 
    {
        value: "identificacionOficial", 
        data: "Identificación oficial con fotografía"
    }, 
    {
        value: "curp", 
        data: "CURP"
    }, 
    {
        value: "compDomicilio", 
        data: "Comprobante de domicilio (no mayor a tres meses)"
    }, 
    {
        value: "constRFC", 
        data: "Constancia de inscripción RFC"
    }, 
    {
        value: "regDerechosObra", 
        data: "Registro de los derechos de la obra"
    }, 
    {
        value: "compCuentaBancaria", 
        data: "Comprobante de cuenta bancaria"
    }, 
    {
        value: "recHonorarios", 
        data: "Recibo de honorarios y/o factura modelo"
    }, 
    {
        value: "constResidencia", 
        data: "Constancia de residencia fiscal vigente"
    }, 
    {
        value: "idApoderadosLegales", 
        data: "Identificación oficial con fotografía de los apoderados legales"
    }, 
    {
        value: "actaConstitutiva", 
        data: "Acta constitutiva"
    }, 
    {
        value: "facturaModelo", 
        data: "Factura Modelo"
    }
]

const physicalPerson = [
    {
        value: "actaNacimiento", 
        data: "Acta de nacimiento", 
        required: false
    }, 
    {
        value: "identificacionOficial", 
        data: "Identificación oficial con fotografía", 
        required: true
    }, 
    {
        value: "curp", 
        data: "CURP", 
        required: true
    }, 
    {
        value: "compDomicilio", 
        data: "Comprobante de domicilio (no mayor a tres meses)", 
        required: true
    }, 
    {
        value: "constRFC", 
        data: "Constancia de inscripción RFC", 
        required: true
    }, 
    {
        value: "regDerechosObra", 
        data: "Registro de los derechos de la obra", 
        required: true
    }, 
    {
        value: "compCuentaBancaria", 
        data: "Comprobante de cuenta bancaria", 
        required: false
    }, 
    {
        value: "recHonorarios", 
        data: "Recibo de honorarios y/o factura modelo", 
        required: false
    }
]

const foreignPhysicalPerson = [
    {
        value: "actaNacimiento", 
        data: "Acta de nacimiento", 
        required: false
    }, 
    {
        value: "identificacionOficial", 
        data: "Identificación oficial con fotografía", 
        required: true
    }, 
    {
        value: "compDomicilio", 
        data: "Comprobante de domicilio (no mayor a tres meses)", 
        required: true
    }, 
    {
        value: "constResidencia", 
        data: "Constancia de residencia fiscal vigente", 
        required: true
    },
    {
        value: "regDerechosObra", 
        data: "Registro de los derechos de la obra", 
        required: true
    }, 
    {
        value: "compCuentaBancaria", 
        data: "Comprobante de cuenta bancaria", 
        required: false
    }, 
    {
        value: "recHonorarios", 
        data: "Recibo de honorarios y/o factura modelo", 
        required: false
    }
]

const moralPerson  = [
    {
        value: "idApoderadosLegales", //
        data: "Identificación oficial con fotografía de los apoderados legales", 
        required: true
    }, 
    {
        value: "actaConstitutiva", //
        data: "Acta constitutiva", 
        required: true
    }, 
    {
        value: "compDomicilio", //
        data: "Comprobante de domicilio (no mayor a tres meses)", 
        required: true
    }, 
    {
        value: "constRFC", //
        data: "Constancia de inscripción RFC", 
        required: true
    }, 
    {
        value: "regDerechosObra", //
        data: "Registro de los derechos de la obra", 
        required: true
    }, 
    {
        value: "compCuentaBancaria", //
        data: "Comprobante de cuenta bancaria", 
        required: false
    }, 
    {
        value: "facturaModelo", //
        data: "Factura Modelo", 
        required: false
    }
]

const foreignMoralPerson = [ 
    {
        value: "idApoderadosLegales", //
        data: "Identificación oficial con fotografía de los apoderados legales", 
        required: true
    }, 
    {
        value: "actaConstitutiva", //
        data: "Acta constitutiva", 
        required: true
    }, 
    {
        value: "compDomicilio", //
        data: "Comprobante de domicilio (no mayor a tres meses)", 
        required: true
    }, 
    {
        value: "constResidencia", //
        data: "Constancia de residencia fiscal vigente", 
        required: true
    },
    {
        value: "regDerechosObra", //
        data: "Registro de los derechos de la obra", 
        required: true
    }, 
    {
        value: "compCuentaBancaria", //
        data: "Comprobante de cuenta bancaria", 
        required: false
    }, 
    {
        value: "facturaModelo", //
        data: "Factura Modelo", 
        required: false
    }
]

const returnDocument = (type) => {
    switch(type){
        case "fisica":
        return physicalPerson; 
        case "moral": 
        return moralPerson; 
        case "foreignFisica": 
        return foreignPhysicalPerson; 
        case "foreignMoral": 
        return foreignMoralPerson
        default: 
        return allDocuments; 
    }
}

export default returnDocument; 