import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PapperBlock from './../../../components/PapperBlock/PapperBlock';
import Typography from '@material-ui/core/Typography';

// tabs
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

// Notifi
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Alarm from '@material-ui/icons/Alarm';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Check from '@material-ui/icons/Check';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SimCardAlert from '@material-ui/icons/SimCardAlert'; //AHDA 08/05/2019
import FastForward from '@material-ui/icons/FastForward'; //aHDA 08/05/2019
import LoaderFull from '../../../components/Loader/LoaderFull';
import Pagination from './PaginationNotification'; 
import { withRouter } from 'react-router-dom'; 

//Services
import { apiPOST, returnCurrentUserId } from '../../../services/service_base';

const notifTypes = [
    "newWorkRegister", 
    "workEvaluationAssign", 
    "additionalMaterialRequest", 
    "additionalMaterialReception", 
    "dueTime", 
    "evaluationAssessment", 
    "purchaseAuthorization", 
    "legalDocumentsExpiration", 
    "registerWorkTime",
    "evaluatorSpecialistFile"
]
  
function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
        margin: '10px auto'
    },
    tabsContainer: {
        fontSize: 25,
        '& button': {
          color: 'white',
          opacity: 0.65
        },
        '& *[aria-selected=true]': {
          color: 'white',
          opacity: 1,
          transform: 'scale(1.1)'
        },
        '& *[role=tablist]': {
            overflowY: 'hidden',
        }
      }
});

class Notifications extends React.Component {

    state = {
        value: 0,
        notNewWorks: [],
        notAssignments: [],
        notMaterialRequest: [],
        notReceptionMaterial: [],
        notTimeRemaining: [],
        notOpinion: [],
        notPurchases: [],
        notLegalDocumentsExpiration: [], //AHDA 08/05/2019
        notRegisterWorkTime: [], //AHDA 08/05/2019,
        notEvaluatorSpecialistFile: [],
        isLoading: true, 

        notifications: [], 
        tabs: []
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };


    handleDeleteItem = (item) => {
        apiPOST('/notifications/delete', { idNotificacion: item.id_notificaciones }).then(
            response => {
                if (response.status === 200) {
                    let index = 0

                    switch (item.type) {
                        case "newWorkRegister":
                            index = this.state.notNewWorks.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notNewWorks = this.state.notNewWorks
                            notNewWorks.splice(index, 1);
                            this.setState({ notNewWorks: notNewWorks });
                            break;
                        case "workEvaluationAssign":
                            index = this.state.notAssignments.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notAssignments = this.state.notAssignments
                            notAssignments.splice(index, 1);
                            this.setState({ notAssignments: notAssignments });
                            break;
                        case "additionalMaterialRequest":
                            index = this.state.notMaterialRequest.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notMaterialRequest = this.state.notMaterialRequest
                            notMaterialRequest.splice(index, 1);
                            this.setState({ notMaterialRequest: notMaterialRequest });
                            break;
                        case "additionalMaterialReception":
                            index = this.state.notReceptionMaterial.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notReceptionMaterial = this.state.notReceptionMaterial
                            notReceptionMaterial.splice(index, 1);
                            this.setState({ notReceptionMaterial: notReceptionMaterial });
                            break;
                        case "dueTime":
                            index = this.state.notTimeRemaining.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notTimeRemaining = this.state.notTimeRemaining
                            notTimeRemaining.splice(index, 1);
                            this.setState({ notTimeRemaining: notTimeRemaining });
                            break;
                        case "evaluationAssessment":
                            index = this.state.notOpinion.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notOpinion = this.state.notOpinion
                            notOpinion.splice(index, 1);
                            this.setState({ notOpinion: notOpinion });
                            break;
                        case "purchaseAuthorization":
                            index = this.state.notPurchases.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notPurchases = this.state.notPurchases
                            notPurchases.splice(index, 1);
                            this.setState({ notPurchases: notPurchases });
                            break;
                        //AHDA 08/05/2019
                        //Added case for legalDocumentsExpiration
                        case "legalDocumentsExpiration": 
                            index = this.state.notLegalDocumentsExpiration.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notLegalDocumentsExpiration = [...this.state.notLegalDocumentsExpiration]
                            notLegalDocumentsExpiration.splice(index, 1);
                            this.setState({ notLegalDocumentsExpiration: notLegalDocumentsExpiration });
                            break;
                        //AHDA 08/05/2019
                        //Added case for legalDocumentsExpiration
                        case "registerWorkTime": 
                            index = this.state.notRegisterWorkTime.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notRegisterWorkTime = [...this.state.notRegisterWorkTime]
                            notRegisterWorkTime.splice(index, 1);
                            this.setState({ notRegisterWorkTime: notRegisterWorkTime });
                            break;
                        case "evaluatorSpecialistFile": 
                            index = this.state.notEvaluatorSpecialistFile.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notnotEvaluatorSpecialistFile = [...this.state.notEvaluatorSpecialistFile]
                            notnotEvaluatorSpecialistFile.splice(index, 1);
                            this.setState({ notnotEvaluatorSpecialistFile: notnotEvaluatorSpecialistFile });
                            break;
                        default: 
                        break; 
                    }
                } else {
                    this.setState({ openError: true });
                }
            }).catch(
                error => {
                    this.setState({ openError: true, isLoading: false });
            });
    }

    //Life cycle 
    componentWillReceiveProps(nextProps) {
        if(this.props.location.search !== nextProps.location.search){
            let url = nextProps.location.search.split('=')[1];
            url = url ? Number.parseInt(url) : 0; 
            this.setState({value: url >= 0 ? url : 0});
        }
    }

    componentDidMount() {
        
        //Get selected notification type 
        let url = this.props.location.search.split('=')[1];
        url = url ? Number.parseInt(url) : 0; 
        //this.setState({value: url >= 0 ? url : 0});

        //Obteniendo notificaciones
        const request = {
            user_id: returnCurrentUserId(),
            portal: "obras",
            stat: 2
        }

        this.setState({isLoading: true});
        apiPOST('/notifications/getbyuser', request).then(
            response => {
                 
                if (response.status === 200) {
                    
                    //AHDA 14/03/2019
                    if(response.data.length > 0){
                        const auxTabs = response.data.map(({notification_id, notification, type}) => ({notification_id, notification, type})); 
                        
                        //AHDA 08/05/2019
                        //Added notLegalDocumentsExpiration
                        let notNewWorks = [];
                        let notAssignments = [];
                        let notMaterialRequest = [];
                        let notReceptionMaterial = [];
                        let notTimeRemaining = [];
                        let notOpinion = [];
                        let notPurchases = [];
                        let notLegalDocumentsExpiration = []; 
                        let notRegisterWorkTime = []; 
                        let notEvaluatorSpecialistFile = []; 

                        const notifications = response.data; 
                        for(let x in notifications){
                            switch (notifications[x].type) {
                                case "newWorkRegister":
                                    notNewWorks = notifications[x].notifications
                                    break;
                                case "workEvaluationAssign":
                                    notAssignments = notifications[x].notifications
                                    break;
                                case "additionalMaterialRequest":
                                    notMaterialRequest = notifications[x].notifications
                                    break;
                                case "additionalMaterialReception":
                                    notReceptionMaterial = notifications[x].notifications
                                    break;
                                case "dueTime":
                                    notTimeRemaining = notifications[x].notifications
                                    break;
                                case "evaluationAssessment":
                                    notOpinion = notifications[x].notifications
                                    break;
                                case "purchaseAuthorization":
                                    notPurchases = notifications[x].notifications
                                    break;
                                //AHDA 08/05/2019
                                //Added case for legalDocumentsExpiration
                                case "legalDocumentsExpiration":
                                    notLegalDocumentsExpiration = notifications[x].notifications
                                    break;
                                //AHDA 08/05/2019
                                //Added case for registerWorkTime
                                case "registerWorkTime":
                                    notRegisterWorkTime = notifications[x].notifications
                                    break;
                                case "evaluatorSpecialistFile":
                                    notEvaluatorSpecialistFile = notifications[x].notifications
                                    break;
                                default: 
                                break; 
                            }
                        }
                        
                        //TODO: add loadding
                        this.setState({
                            tabs: auxTabs,
                            notNewWorks: notNewWorks,
                            notAssignments: notAssignments,
                            notMaterialRequest: notMaterialRequest,
                            notReceptionMaterial: notReceptionMaterial,
                            notTimeRemaining: notTimeRemaining,
                            notOpinion: notOpinion,
                            notPurchases: notPurchases, 
                            //AHDA 08/05/2019
                            //Added notLegalDocumentsExpiration and notRegisterWorkTime
                            notLegalDocumentsExpiration: notLegalDocumentsExpiration,
                            notRegisterWorkTime: notRegisterWorkTime,
                            notEvaluatorSpecialistFile: notEvaluatorSpecialistFile,
                            isLoading: false, 
                            value: url ? url : notifTypes.findIndex(t => t === auxTabs[0].type)
                        }); 

                    }else{
                        //TODO: Add loading 
                        this.setState({tabs: [], value: 0, isLoading: false}); 
                    }

                } else {
                    this.setState({ openError: true, value: 0, isLoading: false });
                }
            }).catch(
                error => {
                    this.setState({ openError: true, value: 0, isLoading: false });
            });
    }

    render() {
        const { theme, classes } = this.props;

        const returnIcon = (type) => {
            switch(type){
                case "newWorkRegister": 
                return <LibraryAdd />
                case "workEvaluationAssign": 
                return <LocalLibrary />
                case "additionalMaterialRequest": 
                return <NoteAdd />
                case "additionalMaterialReception": 
                return <PlaylistAddCheck />
                case "dueTime": 
                return <Alarm />
                case "evaluationAssessment": 
                return <Check />
                case "purchaseAuthorization": 
                return <AttachMoney />
                case "legalDocumentsExpiration": 
                return <SimCardAlert />
                case "registerWorkTime": 
                return <FastForward />
                case "evaluatorSpecialistFile": 
                return <NoteAdd />
                default: 
                return null; 
            }
        }

        return (
            <div className="marginTopLogo">
                <PapperBlock title="Notificaciones" desc="" >
                    {this.state.isLoading ? <LoaderFull /> : ''}
                    
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {
                                this.state.tabs.length > 0 ? 
                                <React.Fragment>
                                    <AppBar position="static" color="default">
                                        <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" className={classes.tabsContainer} variant="scrollable" scrollButtons="auto">
                                            {
                                                this.state.tabs.map(tab => (
                                                <Tab 
                                                icon={returnIcon(tab.type)} 
                                                label={tab.notification} 
                                                value={notifTypes.findIndex(t => t === tab.type)}
                                                key={`tab_${tab.notification_id}`}/>))
                                            }
                                        </Tabs>
                                    </AppBar>
                                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={this.state.value} onChangeIndex={this.handleChangeIndex}>


                                        <TabContainer dir={theme.direction}>

                                            {
                                                this.state.notNewWorks.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notNewWorks}
                                                type="newWorkRegister"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<LibraryAdd />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>

                                            {
                                                this.state.notAssignments.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notAssignments}
                                                type="workEvaluationAssign"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<LocalLibrary />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>

                                            {
                                                this.state.notMaterialRequest.length > 0 ?
                                                <Pagination 
                                                content={this.state.notMaterialRequest}
                                                type="additionalMaterialRequest"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<NoteAdd />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notReceptionMaterial.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notReceptionMaterial}
                                                type="additionalMaterialReception"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<PlaylistAddCheck />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>

                                            {
                                                this.state.notTimeRemaining.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notTimeRemaining}
                                                type="dueTime"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<Alarm />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notOpinion.length > 0 ?
                                                <Pagination 
                                                content={this.state.notOpinion}
                                                type="evaluationAssessment"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<Check />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>


                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notPurchases.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notPurchases}
                                                type="purchaseAuthorization"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<AttachMoney />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>

                                        {/*AHDA 08/05/2019: Added tab for legalDocuments */}
                                        <TabContainer dir={theme.direction}>
                                            
                                            {
                                                this.state.notLegalDocumentsExpiration.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notLegalDocumentsExpiration}
                                                type="legalDocumentsExpiration"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<SimCardAlert />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>

                                        {/*AHDA 08/05/2019: Added tab for registerWorkTime */}
                                        <TabContainer dir={theme.direction}>
                                            
                                            {
                                                this.state.notRegisterWorkTime.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notRegisterWorkTime}
                                                type="registerWorkTime"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<FastForward />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>
                                        <TabContainer dir={theme.direction}>
                                            
                                            {
                                                this.state.notEvaluatorSpecialistFile.length > 0 ? 
                                                <Pagination 
                                                content={this.state.notEvaluatorSpecialistFile}
                                                type="evaluatorSpecialistFile"
                                                handleDeleteItem={this.handleDeleteItem}
                                                icon={<FastForward />}
                                                /> : 
                                                <div>
                                                    <Typography>Sin notificaciones</Typography>
                                                </div>
                                            }
                                            
                                        </TabContainer>

                                    </SwipeableViews>
                                </React.Fragment> : 
                                <React.Fragment>
                                    {/*TODO: Add design*/}
                                    <Typography>No tienes ninguna notificacion configurada</Typography>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </PapperBlock>
            </div>
        );
    }
}

Notifications.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Notifications));

