import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { S3Image } from 'aws-amplify-react';
import styles from './contact-jss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';



//Iconos
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Edit from '@material-ui/icons/Edit';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
//import Launch from '@material-ui/icons/Launch';
import Block from '@material-ui/icons/Block';
import Check from '@material-ui/icons/Check';
import Email from '@material-ui/icons/Email';

/*const optionsOpt = [
  'Bloquear Contacto',
  'Eliminar Contacto',
];*/

const ITEM_HEIGHT = 48;

class ContactDetail extends React.Component {
  state = {
    anchorElOpt: null,
  };

  ////////////////////////// Options
  handleClickOpt = event => {
    this.setState({ anchorElOpt: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorElOpt: null });
  };

  //Also for deleting 
  handleBlockUser = (item, newStatus) => {
    this.setState({ anchorElOpt: null });
    item.estatus = newStatus ? newStatus : 1;
    this.props.blockUser(item, newStatus);
  }
  ////////////////////////// End options 

  render() {
    const {
      classes,
      edit,
      //favorite,
      showMobileDetail,
      dataContact,
      item,
      canEdit,
      canBlock,
      canUnblock,
      canDelete
    } = this.props;
    const { anchorElOpt } = this.state;

    //AHDA 26/03/2019
    //Blocked action method, the other way was replacing field estatus with the boolean 
    const returnBlocked = (status) => {
      if (status === 1)
        return !canBlock; //AHDA 31/05/2019
      else if (status === 0)
        return !canUnblock; //AHDA 31/05/2019
      else
        return false
    }

    if (dataContact.length === 0) {
      return (
        <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
          <section className={classes.cover}>

          </section>
          <div>
            <Typography variant="body1">No hay usuarios disponibles</Typography>
          </div>
        </main>
      );
    }

    return (
      <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
        {
          item ?
            <React.Fragment>
              <section className={classes.cover}>
                <div className={classes.opt}>
                  {/* GEO 01/04/2019 Se agregan Tooltips a botones */}
                  <Tooltip title="Editar">
                    <IconButton  className={classes.iconBtn} disabled={!canEdit} aria-label="Edit" onClick={() => edit(item)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Opciones">
                    <IconButton  
                      aria-label="More"
                      aria-owns={anchorElOpt ? 'long-menu' : null}
                      aria-haspopup="true"
                      className={[classes.button, classes.iconBtn]}
                      onClick={this.handleClickOpt}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorElOpt}
                    open={Boolean(anchorElOpt)}
                    onClose={this.handleCloseOpt}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                      },
                    }}
                  >
                    {/*AHDA 26/03/2018
                    DELETED: disabled={item.estatus === 1 ? !canBlock : !canUnblock}*/}
                    <MenuItem disabled={returnBlocked(item.estatus)} onClick={() => this.handleBlockUser(item, item.estatus === 1 ? 0 : 1)}>
                      {item.estatus === 1 ? "Bloquear Usuario" : "Desbloquear Usuario"}
                    </MenuItem>

                    {/*TODO: manage delete user*/}
                    {
                      item.id_rol === 12 ?
                        <MenuItem disabled={!canDelete} onClick={() => this.handleBlockUser(item, 2)}>
                          Eliminar Usuario
                       </MenuItem> : null
                    }

                  </Menu>
                </div>
                <Avatar alt="Foto" src={!item.avatar ? "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png" : ""} className={classNames(classes.avatar, classes.unsetFlex)}>
                  {
                    item.avatar ? <S3Image theme={{ photoImg: { width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={item.avatar} /> : ""
                  }
                </Avatar>

                <Typography className={classes.userName} variant="display1">
                  {`${item.nombre}`}
                  <Typography variant="caption">
                    {item.unidad_negocio}
                  </Typography>
                </Typography>
              </section>
              <div>
                {/* Usuarios internos */}
                <div>
                  <List className={classes.contenedorDatos}>
                    <ListItem>
                      <Avatar className={classes.themeSecondaryIcon}>
                        <AssignmentInd />
                      </Avatar>
                      {/* JCMY 02/07/2019 insidencia 473*/}
                      <ListItemText primary={item.nombre} secondary="Nombre" />
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <Avatar className={classes.themeSecondaryIcon}>
                        <AssignmentInd />
                      </Avatar>
                      <ListItemText primary={item.apellidos} secondary="Apellido" />
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <Avatar className={classes.themeSecondaryIcon}>
                        <Email />
                      </Avatar>
                      <ListItemText primary={item.usuario_cognito} secondary="Correo electrónico" />
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <Avatar className={classes.themeSecondaryIcon}>
                        <BusinessCenter />
                      </Avatar>
                      <ListItemText primary={item.portal_profile} secondary="Perfil asignado en el portal" />
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <Avatar className={classes.themeSecondaryIcon}>
                        {item.estatus === 1 ? <Check /> : <Block />}
                      </Avatar>
                      <ListItemText primary={item.estatus === 1 ? "Activo" : "Bloqueado"} secondary="Estatus" />
                    </ListItem>
                    <Divider variant="inset" />
                  </List>
                </div>
              </div>
            </React.Fragment> :
            <React.Fragment>
              <section className={classes.cover}>
              </section>
              <div className={classes.alignCard}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.w50}
                >
                  <Grid item xs={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="body1" className={classes.textSelecciona}>Selecciona un usuario</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
        }
      </main>
    );
  }
}

ContactDetail.propTypes = {
  classes: PropTypes.object,
  showMobileDetail: PropTypes.bool,
  dataContact: PropTypes.array,
  itemSelected: PropTypes.number,
  edit: PropTypes.func,
  remove: PropTypes.func,
  favorite: PropTypes.func,
  item: PropTypes.object
};

export default withStyles(styles)(ContactDetail);
