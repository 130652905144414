import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddContactForm from './AddContactForm';
import FloatingPanel from './../Panel/FloatingPanel';
import styles from './contact-jss';

class AddContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: []
    };
  }

  sendValues = (values, files) => {
    setTimeout(() => {
      this.props.submit(values, files);
    }, 500);
  }

  render() {
    const {
      openForm,
      closeForm,
      avatarInit,
      dataInit, 
      rolesCatalogue, 
      dropValidations
    } = this.props;
    
    const { img } = this.state;
    const branch = '';
    
    return (
      <div>
        <FloatingPanel openForm={openForm} branch={branch} closeForm={closeForm} title="Editar Usuario">
          <AddContactForm
            onSubmit={this.sendValues}
            onDrop={this.onDrop}
            imgAvatar={img === '' ? avatarInit : img}
            handleSubmit={this.sendValues}
            dataInit={dataInit}
            rolesCatalogue={rolesCatalogue}
            dropValidations={dropValidations}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddContact.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  addContact: PropTypes.func,
  openForm: PropTypes.bool.isRequired,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  dataInit: PropTypes.object
};

export default withStyles(styles)(AddContact);
