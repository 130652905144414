import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styles from './nuevaObra-jss';

import { InputLabel } from '@material-ui/core';
import PapperBlock from '../PapperBlock/PapperBlock';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';

// Expandible
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//Modal
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MediaModal from '../../components/Works/MediaModal';

// Tabla Catalogo
import AddCatalogue from '../Catalogue/AddCatalogue';
import Header from '../Catalogue/Header';
import TableCatalogue from '../Catalogue/TableCatalogue';
import { apiPOST, returnCurrentUserId, fetchCall, apiPOSTNotAuth } from '../../services/service_base';
import { saveMultipleFiles, saveFile, getFile, saveMultipleFilesPromise } from '../../services/storage';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../components/Notification/CustomNotification';
import ChipInput from 'material-ui-chip-input'
import MaterialDropZone from '../Forms/MaterialDropZone';
import LoaderFull from '../../components/Loader/LoaderFull';

import FormHelperText from '@material-ui/core/FormHelperText';

import { Document, Paragraph, TextRun, Packer, Numbering, Indent } from 'docx';


// EDITOR
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState, convertToRaw, Modifier, ContentState } from 'draft-js';
import { getSelectedBlock } from "draftjs-utils";
import { stateFromHTML } from 'draft-js-import-html';
import EditorStyle from '../../styles/components/TextEditor.scss';
import '../../styles/components/vendors/react-draft-wysiwyg/react-draft-wysiwyg.css';
import AcceptedMaterialTypes from '../../utils/acceptedMaterialTypes';


//AHDA 27/06/2019
function getSteps() {
  return ['Datos del Usuario', 'Generales de la Obra', 'Material'];
}

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    width: '90%',
    height: '90%',
    transform: 'translate(-50%, -50%)',
  };
}
var materialesArray = [];

const content = {
  blocks: [{
    key: '637gr',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }],
  entityMap: {}
};

class NuevaObra extends React.Component {

  constructor(props) {
    super(props);

    const contentBlock = convertFromRaw(content);
    let loglineEditorState = null;
    let shortSynopsisEditorState = null;
    let longSynopsisEditorState = null;

    if (contentBlock) {
      loglineEditorState = EditorState.createWithContent(contentBlock);
      shortSynopsisEditorState = EditorState.createWithContent(contentBlock);
      longSynopsisEditorState = EditorState.createWithContent(contentBlock)
    }

    this.state = {
      //Modal
      open: false,
      dense: false,
      secondary: false,
      // Inpunt step 0
      companyProvider: "",
      representative: "",
      address: "",
      telephone: "",
      email: "",
      // Catalogue
      countries: [],
      genders: [],
      subgenres: [],
      formats: [],
      durations: [],
      sources: [],
      authors: [],
      languages: [],
      materials: [],
      companysProviders: [],
      // Inpunt step 1
      title: "",
      typeWork: "",
      country: "",
      gender: "",
      subgenre: "",
      format: "",
      typeProyect: "",
      chapters: "",
      duration: "",
      source: "",
      labels: [],
      comments: "",
      // Inputs step 1 authors
      name: "",
      participation: "",
      // Inputs step 1 materials
      typeMaterial: "",
      language: "",
      titleMaterial: "",
      chapterNumberMaterial: "",
      // Table Authors
      headers: ["Id", "Nombre", "Participación", "Acción"],

      // EGS - 15/05/2019
      // Autores
      authsUsr: [],
      authorsRawData: [], //AHDA 29/05/2019
      authsCatalog: [],
      authorsName: '',
      selectedAuthor: {},
      authorsData: [],
      itsNewAuth: false,
      autFromUsr: false,
      // Alert
      openAlert: false,
      variant: "",
      message: "",
      // Table material
      headersMaterial: ["Id", "Título", "Tipo de Material", "Número de Capítulo", "Idioma", "Opciones"],
      dataMaterial: [],
      dataMaterialTable: [],
      openFormAuthor: false,
      openFormMaterial: false,
      idObra: 0,
      idWorkContact: 0,
      checked: false,
      // Inpunt step 2

      // Componente img
      files: [],
      selectFile: {},
      activeStep: 0,
      expanded: 'panel1',
      altLabel: true,
      inputValue: '',
      selectedItem: [],
      completed: {},

      loading: false,

      currentUsr: {},

      update: 0,

      // Validations
      // JCMY 03/07/2019 incidencia 1022
      errorauthorsName: false,
      errorparticipation: false,
      errortypeMaterial: false,
      errorlanguage: false,


      // JCMY 22/04/2019
      statusWorkFlow: 0,
      // JCMY 01/04/2019 
      idAuxAutor: 0,
      idAuxMaterial: 0,
      //isanchez 13.05.2019
      openModalDocAcpt: false,
      urlDocAcept: '',

      logline: "",
      loglineLength: 0,
      loglineEditorState,

      shortSynopsis: "",
      shortSynopsisLength: 0,
      shortSynopsisEditorState,

      longSynopsis: "",
      longSynopsisLength: 0,
      longSynopsisEditorState,
      rolId: null,

      uploadProgress: null
    };
  }


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, openModalDocAcpt: false });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  //Inputs
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  // Functions Autors
  handleClear = (event) => {
    this.setState({ name: "", participation: "" });
  }

  //-------------------------------- Authors 
  handleSubmitAuthor = (event) => {

    //AHDA 29/05/2019
    //Validate empty 
    if (!this.state.participation || !this.state.authorsName || this.state.authorsName === "") {

      this.setState({
        errorauthorsName: !this.state.authorsName || this.state.authorsName === "" ? true : false,
        errorparticipation: this.state.participation ? false : true
      });

    } else {
      //Validate errors
      if (!this.state.errorauthorsName && !this.state.errorparticipation) {

        let auxAuthorsData = [...this.state.authorsData], auxAuthorsRawData = [...this.state.authorsRawData];

        //Insert new
        if (this.state.itsNewAuth) {
          //For author registered by external user 
          if (this.props.currentRol === 12) {
            const auxItem = { authors_id: (auxAuthorsData.length + 1).toString(), name: this.state.authorsName, rol: this.state.participation, }
            auxAuthorsData.push(auxItem);
            auxAuthorsRawData.push(auxItem);
          }
          //For catalogue user 
          else {
            const name = this.state.authors.find(a => a.value === this.state.authorsName);

            // JCMY 02/07/2019 insidencia 998
            let authorExist = auxAuthorsRawData.find(a => a.authors_id === name.value);


            if (authorExist == undefined) {

              auxAuthorsRawData.push({ id: (auxAuthorsRawData.length + 1).toString(), authors_id: this.state.authorsName, name: name ? name.label : "", rol: this.state.participation });

              auxAuthorsData.push({ authors_id: (auxAuthorsData.length + 1).toString(), name: name ? name.label : "", rol: this.state.participation, });
            } else {
              this.setState({ openAlert: true, variant: "warning", message: "Autor ya existente en el listado" });
            }

          }
        }
        //Update existing
        else {
          //For author registered by external user 
          if (this.props.currentRol === 12) {
            let auxIndex = auxAuthorsData.findIndex(a => a.authors_id === this.state.selectedAuthor.authors_id);
            let auxRawIndex = auxAuthorsRawData.findIndex(a => a.authors_id === this.state.selectedAuthor.authors_id);

            const newItem = {
              authors_id: this.state.selectedAuthor.authors_id, name: this.state.authorsName, rol: this.state.participation
            }

            auxAuthorsData[auxIndex] = newItem;
            auxAuthorsRawData[auxRawIndex] = newItem;
          }
          //For catalogue user 
          else {

            const name = this.state.authors.find(a => a.value === this.state.authorsName);

            // JCMY 02/07/2019 insidencia 998
            let authorExist = auxAuthorsRawData.find(a => parseInt(a.authors_id) === parseInt(name.value));

            if (authorExist == undefined) {

              let auxIndex = auxAuthorsData.findIndex(a => a.authors_id === this.state.selectedAuthor.authors_id);
              let auxRawIndex = auxAuthorsRawData.findIndex(a => a.id === this.state.selectedAuthor.authors_id);

              const newRawItem = {
                id: this.state.selectedAuthor.authors_id,
                authors_id: this.state.authorsName,
                name: name ? name.label : "", rol: this.state.participation,
              }

              const newItem = {
                authors_id: this.state.selectedAuthor.authors_id, name: name ? name.label : "", rol: this.state.participation,
              }

              auxAuthorsData[auxIndex] = newItem;
              auxAuthorsRawData[auxRawIndex] = newRawItem;

            } else {
              this.setState({ openAlert: true, variant: "warning", message: "Autor ya existente en el listado" });
            }



          }
        }

        this.setState({
          openFormAuthor: false, itsNewAuth: false,
          authorsName: "", participation: undefined,
          authorsData: auxAuthorsData, authorsRawData: auxAuthorsRawData, selectedAuthor: undefined
        });
      }
    }
  }


  handleDeleteAuthor = (item) => {
    //AHDA 29/05/2019
    if (item) {
      let auxAuthorsData = [...this.state.authorsData], auxAuthorsRawData = [...this.state.authorsRawData];

      if (this.props.currentRol === 12) {
        let auxIndex = auxAuthorsData.findIndex(a => a.authors_id === item.authors_id);
        let auxRawIndex = auxAuthorsRawData.findIndex(a => a.authors_id === item.authors_id);

        auxAuthorsData.splice(auxIndex, 1);
        auxAuthorsRawData.splice(auxRawIndex, 1);
      } else {
        let auxIndex = auxAuthorsData.findIndex(a => a.authors_id === item.authors_id);
        let auxRawIndex = auxAuthorsRawData.findIndex(a => a.id === item.authors_id);

        auxAuthorsData.splice(auxIndex, 1);
        auxAuthorsRawData.splice(auxRawIndex, 1);
      }

      this.setState({
        openFormAuthor: false, itsNewAuth: false,
        authorsName: undefined, participation: undefined,
        authorsData: auxAuthorsData, authorsRawData: auxAuthorsRawData, selectedAuthor: undefined
      });
    }
  }

  handledOpenFormAuthor = (item) => {
    //AHDA 29/05/2019

    if (item) {
      if (this.props.currentRol === 12) {
        this.setState({
          openFormAuthor: true, itsNewAuth: false, authorsName: item.name, participation: item.rol, selectedAuthor: item
        });
      } else {
        let auxItem = this.state.authorsRawData.find(a => a.id == item.authors_id);
        this.setState({
          openFormAuthor: true,
          itsNewAuth: false,
          authorsName: auxItem ? auxItem.authors_id : undefined,
          participation: item.rol,
          selectedAuthor: item
        });
      }
    } else {
      this.setState({ openFormAuthor: true, itsNewAuth: true, authorsName: undefined, participation: undefined, selectedAuthor: undefined });
    }
  }

  handledCloseForm = () => {
    this.setState({ openFormAuthor: false });
  }
  //-------------------------------- End Authors 
  handleMaterialChange = ({ target: { value } }) => {
    let acceptedFiles = [];

    if (AcceptedMaterialTypes[value] && Array.isArray(AcceptedMaterialTypes[value])) {
      acceptedFiles = AcceptedMaterialTypes[value];
    } else {
      acceptedFiles = AcceptedMaterialTypes.default;
    }

    this.setState({
      typeMaterial: value,
      filesMaterial: [],
      files: [],
      acceptedFiles
    });
  }

  //   handleSubmitMaterial = () => {
  // console.log(this.state.materials, this.state.rawMaterias)
  //     let auxMaterial = [...this.state.materials], auxRawMaterial = [...this.state.];
  //     const rawItem = {
  //       id_material_multimedia: auxRawMaterial.length + 1,
  //       tipo_archivo: this.state.typeMaterial,
  //       descripcion: this.state.catLanguage.find(l => l.value == this.state.languageMaterial).data,
  //       id_idioma: this.state.languageMaterial,
  //       isNew: true,
  //       file: this.state.filesMaterial[0]
  //     }

  //     const item = {
  //       id_material_multimedia: `${auxRawMaterial.length + 1}`,
  //       tipo_archivo: this.state.materialTypes.filter(m => m.value === this.state.typeMaterial)[0].data,
  //       descripcion: this.state.catLanguage.find(l => l.value == this.state.language).data
  //     }
  //     console.log(item)
  //     auxMaterial.push(item);
  //     auxRawMaterial.push(rawItem);

  //     this.setState({ materials: auxMaterial, rawMaterials: auxRawMaterial, openFormMaterial: false });
  //   }


  // Functions Materials

  handleSubmitMaterial = (event) => {

    event.preventDefault();

    const regChapter = /^[0-9.,-\s]*$/;
    const regDouble = /[.,-]{2,}/;

    if (!regChapter.test(this.state.proChapterMaterial) && regDouble.test(this.state.proChapterMaterial)) {
      return;
    }

    if (!this.state.errortypeMaterial && !this.state.errorlanguage) {
      // JCMY 01/04/2019 Se mejora las valdiaciones 
      const typeMaterial = this.state.typeMaterial;
      const language = this.state.language;

      if (!typeMaterial || !language) {
        if (typeMaterial == "" && language == "") {
          this.setState({ errortypeMaterial: true, errorlanguage: true });
        } else if (typeMaterial == "") {
          this.setState({ errortypeMaterial: true });
        } else if (language == "") {
          this.setState({ errorlanguage: true });
        }

        return false;
      } else {


        const selectFile = this.state.selectFile

        if (this.state.isMaterialMultipleFiles && selectFile.length > 1) {
          const materials = this.state.dataMaterial
          const materialsTable = this.state.dataMaterialTable

          this.state.selectFile.forEach((file, fileIndex) => {

            // JCMY 01/04/2019
            if (this.state.idAuxMaterial !== 0) {
              const indexMaterials = materials.findIndex(obj => obj.id === this.state.idAuxMaterial);
              const indexMaterialsTable = materialsTable.findIndex(obj => obj.id === this.state.idAuxMaterial);
              materials.splice(indexMaterials, 1);
              materialsTable.splice(indexMaterialsTable, 1);
            }

            let index = 1
            if (materials.length > 0) {
              index = (parseInt(materials[materials.length - 1].id)) + 1
            }

            const material = {};
            const materialTable = {};
            //data to show in table
            const objTypeMaterial = this.state.materials.find(obj => obj.value === this.state.typeMaterial);
            const objLanguage = this.state.languages.find(obj => obj.value === this.state.language);

            materialTable.id = index.toString()
            materialTable.titleMaterial = this.state.titleMaterial
            materialTable.typeMaterial = objTypeMaterial.data
            materialTable.chapterNumberMaterial = this.state.chapterNumberMaterial ? `${this.state.chapterNumberMaterial}` : "";
            materialTable.language = objLanguage.data

            materialsTable.push(materialTable)

            // Add data db
            material.id = index.toString()
            material.material_nombre = this.state.titleMaterial
            material.tipo_archivo = objTypeMaterial.value
            material.ruta = ""
            material.id_obra = 0
            material.id_idioma = this.state.language
            material.idTipoMaterial = this.state.typeMaterial
            material.numero_capitulo = this.state.chapterNumberMaterial || ""
            material.version = "v0"
            material.archivo = file
            materials.push(material)

          })

          if (materials.length !== materialesArray.length) {
            materialesArray = materials;
          }

          this.setState({
            idAuxMaterial: 0,
            dataMaterialTable: materialsTable,
            dataMaterial: materials,
            isMaterialMultipleFiles: false,
            titleMaterial: "",
            chapterNumberMaterial: "",
            openFormMaterial: false,
            selectFile: {},
            files: []
          });

        } else if (Object.keys(selectFile).length !== 0) {
          const materials = this.state.dataMaterial
          const materialsTable = this.state.dataMaterialTable
          // JCMY 01/04/2019
          if (this.state.idAuxMaterial !== 0) {
            const indexMaterials = materials.findIndex(obj => obj.id === this.state.idAuxMaterial);
            const indexMaterialsTable = materialsTable.findIndex(obj => obj.id === this.state.idAuxMaterial);
            materials.splice(indexMaterials, 1);
            materialsTable.splice(indexMaterialsTable, 1);
          }

          let index = 1
          if (materials.length > 0) {
            index = (parseInt(materials[materials.length - 1].id)) + 1
          }

          const material = {};
          const materialTable = {};
          //data to show in table
          const objTypeMaterial = this.state.materials.find(obj => obj.value === this.state.typeMaterial);
          const objLanguage = this.state.languages.find(obj => obj.value === this.state.language);

          materialTable.id = index.toString()
          materialTable.titleMaterial = this.state.titleMaterial
          materialTable.typeMaterial = objTypeMaterial.data
          materialTable.chapterNumberMaterial = this.state.chapterNumberMaterial ? `${this.state.chapterNumberMaterial}` : "";
          materialTable.language = objLanguage.data

          materialsTable.push(materialTable)

          // Add data db
          material.id = index.toString()
          material.material_nombre = this.state.titleMaterial
          material.tipo_archivo = objTypeMaterial.value
          material.ruta = ""
          material.id_obra = 0
          material.id_idioma = this.state.language
          material.idTipoMaterial = this.state.typeMaterial
          material.numero_capitulo = this.state.chapterNumberMaterial || ""
          material.version = "v0"
          material.archivo = selectFile
          materials.push(material)
          //JCMY 01/04/2019

          if (materials.length !== materialesArray.length) {
            materialesArray = materials;
          }

          this.setState({
            idAuxMaterial: 0,
            dataMaterialTable: materialsTable,
            dataMaterial: materials,
            isMaterialMultipleFiles: false,
            titleMaterial: "",
            chapterNumberMaterial: "",
            openFormMaterial: false,
            selectFile: {},
            files: []
          });

        } else {
          this.setState({ openAlert: true, variant: "warning", message: "Agrega un archivo" });
        }

      }
    }
  }

  openGoogleViewer = (ruta)=>{
    getFile(ruta, "public")
    .then((response) => {
      const auxUrl = encodeURIComponent(response);
      window.open(`https://docs.google.com/viewer?url=${auxUrl}&embedded=true`, "_blank");
    })
    .catch((err) => {
      this.setState({
        openAlert: true,
        variant: "error",
        message: "No hay vista previa disponible",
      });
    });
  }

  handleViewItem = (item) => {
    const auxItem = item.id_material_multimedia ? this.state.dataMaterial.find(m => m.id_material_multimedia == item.id_material_multimedia)
      : this.state.dataMaterial.find(m => m.id == item.id);
    debugger
    if (auxItem) {
      if (auxItem.id_material_multimedia || auxItem.ruta) {
        let aTipo = auxItem.tipo_archivo.toString().toLowerCase();
        let type = auxItem.ruta ? auxItem.ruta.split('.')[auxItem.ruta.split('.').length - 1] : '';
        if (!type) {
          this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
          return false;
        }

        if (aTipo === "video" || aTipo === "trailer" || aTipo === "episodios(video)") {
          //TODO: open modal and get url
          switch (type.toLowerCase()) {
            case "rar":
            case "zip":
            case "wmv":
            case "3gp":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "mp4":
            case "mov":
              getFile(auxItem.ruta, 'public').then(result => {
                let material = [{ type: 'video', url: result }];
                this.setState({ openMediaModal: true, modalFiles: material });
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;

          }
        }
        else if (aTipo === "img") {
          let material = [{ type: 'img', url: auxItem.ruta }]
          this.setState({ openMediaModal: true, modalFiles: material });
        } else if (aTipo === 'capítulo 1' || aTipo === "capitulo1" || aTipo === "capitulo 1") {
          // let type = auxItem.ruta.split('.')[auxItem.ruta.split('.').length - 1];
          switch (type.toLowerCase()) {
            case "png":
            case "jpg":
            case "jpeg":
              let material = [{ type: 'img', url: auxItem.ruta }]
              this.setState({ openMediaModal: true, modalFiles: material });
              break;
            case "rar":
            case "wmv":
            case "3gp":
            case "zip":
            case "mp3":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "mp4":
            case "mov":
              getFile(auxItem.ruta, 'public').then(result => {
                let material = [{ type: 'video', url: result }];
                this.setState({ openMediaModal: true, modalFiles: material });
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;

          }
        } else if (aTipo === 'libretos' || aTipo === "libreto" || aTipo === 'biblia' || aTipo === 'sinopsisCorta' || aTipo === 'sinopsisLarga') {
          // let type = auxItem.ruta.split('.')[auxItem.ruta.split('.').length - 1];
          switch (type.toLowerCase()) {
            case "mp4":
            case "mov":
              getFile(auxItem.ruta, 'public').then(result => {
                let material = [{ type: 'video', url: result }];
                this.setState({ openMediaModal: true, modalFiles: material });
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "rar":
            case "zip":
            case "3gp":
            case "wmv":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "pdf":
            case "ppt":
            case "doc":
            case "docx":
            case "pptx":
            case "xls":
            case "txt":
            case "xlsx":
              this.openGoogleViewer(auxItem.ruta)
              break;

          }
        } else if (aTipo === 'evaluación') {
          // let type = auxItem.ruta.split('.')[auxItem.ruta.split('.').length - 1];
          switch (type.toLowerCase()) {
            case "rar":
            case "zip":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
          }
        } else if (aTipo === 'otro') {
          // let type = auxItem.ruta.split('.')[auxItem.ruta.split('.').length - 1];
          switch (type.toLowerCase()) {
            case "png":
            case "jpg":
            case "jpeg":
              let material = [{ type: 'img', url: auxItem.ruta }]
              this.setState({ openMediaModal: true, modalFiles: material });
              break;
            case "mp4":
            case "mov":
              getFile(auxItem.ruta, 'public').then(result => {
                let material = [{ type: 'video', url: result }];
                this.setState({ openMediaModal: true, modalFiles: material });
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "rar":
            case "zip":
            case "3gp":
            case "wmv":
            case "mp3":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "pdf":
            case "ppt":
            case "doc":
            case "docx":
            case "pptx":
            case "xls":
            case "txt":
            case "xlsx":
              this.openGoogleViewer(auxItem.ruta)
              break;
          }
        }
        else {
          //TODO: send to new page 
          switch (type.toLowerCase()) {
            case "png":
            case "jpg":
            case "jpeg":
              let material = [{ type: 'img', url: auxItem.ruta }]
              this.setState({ openMediaModal: true, modalFiles: material });
              break;
            case "mp4":
            case "mov":
              getFile(auxItem.ruta, 'public').then(result => {
                let material = [{ type: 'video', url: result }];
                this.setState({ openMediaModal: true, modalFiles: material });
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;
            case "rar":
            case "zip":
            case "3gp":
            case "wmv":
              getFile(auxItem.ruta, 'public').then(result => {
                window.open(result, "_blank");
              }).catch(err => {
                this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
              })
              break;

            case "pdf":
            case "ppt":
            case "doc":
            case "docx":
            case "pptx":
            case "xls":
            case "txt":
            case "xlsx":
              this.openGoogleViewer(auxItem.ruta)
              break;
          }

        }
      } else {
        if (auxItem.archivo && auxItem.archivo.preview) {
          window.open(auxItem.archivo.preview, "_blank");
        } else {
          this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
        }
      }
    } else {
      this.setState({ openAlert: true, variant: "error", message: "No hay vista previa disponible" });
    }
  }

  handleCloseMediaModla = () => {
    this.setState({ openMediaModal: false });
  }

  // funcinoes de envio de datos de materiales 
  handleDeleteMaterial = (item) => {
    const materials = this.state.dataMaterial
    const materialsTable = this.state.dataMaterialTable
    const auxRawItem = materials.find(m => m.id_material_multimedia == item.id_material_multimedia);

    if (auxRawItem.id_material_multimedia) {
      apiPOSTNotAuth("/works/deletemulti media", { multimedia_id: auxRawItem.id_material_multimedia }).then(response => {
        const indexMaterials = materials.findIndex(obj => obj.id_material_multimedia === item.id_material_multimedia);
        const indexMaterialsTable = materialsTable.findIndex(obj => obj.id_material_multimedia === item.id_material_multimedia);
        materials.splice(indexMaterials, 1);
        materialsTable.splice(indexMaterialsTable, 1);
        this.setState({ dataMaterialTable: materialsTable, dataMaterial: materials, openAlert: true, variant: "success", message: "Se eliminó el archivo" });
      }).catch(err => {
        this.setState({ openAlert: true, variant: "error", message: "No se pudo eliminar el archivo" });
      })
    } else {
      const indexMaterials = materials.findIndex(obj => obj.id === item.id);
      const indexMaterialsTable = materialsTable.findIndex(obj => obj.id === item.id);
      materials.splice(indexMaterials, 1);
      materialsTable.splice(indexMaterialsTable, 1);
      this.setState({ dataMaterialTable: materialsTable, dataMaterial: materials });
    }
  }

  handledOpenFormMaterial = (item) => {
    // console.log(item)
    if (item !== undefined) {
      const materials = this.state.dataMaterial
      //JCMY 01/04/2019
      // const materialsTable = this.state.dataMaterialTable
      // console.log(materials)
      const obj = materials.find(obj => obj.id === item.id);
      // console.log(obj)
      const files = []
      let selectFile = null
      if (obj.ruta !== "") {

      } else if (obj.archivo[0] === undefined) {
        files.push(obj.archivo);
        selectFile = obj.archivo
      } else {
        files.push(obj.archivo[0]);
        selectFile = obj.archivo[0]
      }

      // const indexMaterials = materials.findIndex(obj => obj.id === item.id);
      // const indexMaterialsTable = materialsTable.findIndex(obj => obj.id === item.id);
      // materials.splice(indexMaterials, 1);
      // materialsTable.splice(indexMaterialsTable, 1);
      this.setState({ openFormMaterial: true, idAuxMaterial: obj.id, typeMaterial: obj.idTipoMaterial, language: obj.id_idioma, files: files, selectFile: selectFile });
      // this.setState({ openFormMaterial: true, typeMaterial: obj.idTipoMaterial, language: obj.id_idioma, files: files, selectFile: selectFile, dataMaterialTable: materialsTable, dataMaterial: materials });
    }
    else {
      this.setState({ openFormMaterial: true, typeMaterial: "", language: "", selectFile: {}, files: [] });
    }
  }

  handledCloseFormMaterial = () => {
    this.setState({ openFormMaterial: false, selectFile: {}, });
  }

  handleCleanMaterial = (event) => {
    this.setState({
      titleMaterial: '',
      typeMaterial: '',
      language: '',
      chapterNumberMaterial: '',
      selectFile: {},
      files: []
    });
  }

  handleAddChip = (item) => {
    const list = this.state.labels
    list.push(item)
    this.setState({ labels: list });
  }


  handleDeleteChip = (chip, index) => {
    const list = this.state.labels
    list.splice(index, 1);
    this.setState({ labels: list });
  }

  Send = async (skip) => {
    let auxErrors = [];
    let isValid = true;

    try {
      isValid = await this.form.walk(this.form.childs);
    } catch (error) {
      console.log(error);
    }

    if (!isValid) {
      let obj = this.form.childs.find(f => !f.state.isValid)
      // this.form.childs.map(f => {
      //   if (!f.state.isValid) {
      //     // auxErrors.push(f);
      //     console.log(f)
      //   }

      // })
      switch (obj.props.id) {
        case "title":
        case "typeWork":
        case 'country':
        case 'gender':
        case 'format':
        case 'typeProyect':
        case "proRegistroAutor":
        case "source":
          this.setState({ expanded: 'panel1' })
          break;
        default: break;
      }

    }

    // console.log(auxErrors)
  }

  updateProgress = (progress) => {
    if (!this.state.uploadProgress || progress > this.state.uploadProgress)
      this.setState({ uploadProgress: progress.toFixed(2) })
  }

  handleComplete = (event) => {
    event.preventDefault()
    const { completed } = this.state;
    // this.setState({ loading: true })


    //AHDA 10/05/2019
    //Added audit call 
    const auditBody = {
      id_usuario: returnCurrentUserId(),
      user_action: this.state.idObra === 0 ? 10 : 9,
      work_id: this.state.idObra,
      portal: 'obras',
      screen: this.props.screen
    }

    switch (this.state.activeStep) {
      case 0:
        this.setState({ loading: true })


        const fieldsStep0 = {};
        fieldsStep0.companyProviderCatalog = this.state.companyProviderCatalog;
        fieldsStep0.companyProvider = this.state.companyProvider;
        fieldsStep0.representative = this.state.representative
        fieldsStep0.address = this.state.address;
        fieldsStep0.telephone = this.state.telephone;
        fieldsStep0.email = this.state.email;

        if (this.state.update == 0) {
          localStorage.setItem('fieldsStep0', JSON.stringify(fieldsStep0));
        } else {
          localStorage.setItem('fieldsStep0Update', JSON.stringify(fieldsStep0));
        }
        completed[this.state.activeStep] = true;
        this.setState({ completed });

        this.handleNext();
        // JCMY 13/05/2019
        this.setState({ loading: false })

        break;
      case 1:
        const len = this.state.authorsData; //AHDA 17/05/2019
        const check = this.state.checked
        this.setState({ loading: true })


        //AHDA 17/05/2019
        //Added validation for authorization letter
        if (!check) {
          this.setState({
            openAlert: true, variant: "warning", message: "Por favor, acepta la carta de autorización", loading: false
          });
          return false;
        }

        if (len.length > 0) {
          // JCMY 10/05/2019
          // if (check === true) {
          const requestWork = {
            empresa_proveedor: this.state.companyProviderCatalog ? this.state.companyProviderCatalog : null,
            titulo_original: this.state.title,
            tipo_obra: this.state.typeWork,
            numero_capitulos: this.state.chapters == "" ? 0 : this.state.chapters,
            duracion_min_capitulos: this.state.duration == "" ? 0 : this.state.duration,
            logline: this.state.logline,
            comentarios: this.state.comments,
            id_usuario: returnCurrentUserId(),
            typeProyect: this.state.typeProyect,
            id_fuente: this.state.source,
            id_pais: this.state.country,
            id_formato: this.state.format,
            id_genero: this.state.gender,
            id_subgenero: this.state.subgenre == "" ? null : this.state.subgenre,
            author_register: this.state.author_register //AHDA 29/05/2019
          }
          if (this.state.idObra === 0) {
            // Case add
            // Create work
            apiPOST('/works/addwork', requestWork).then(
              response => {
                if (response.status === 200) {
                  this.setState({ idObra: response.data[0].idObra });
                  const fieldsStep0 = JSON.parse(localStorage.getItem('fieldsStep0'));
                  //AHDA 10/05/2019
                  //Added audit call
                  //AHDA 28/05/2019: Changed validation
                  auditBody.work_id = response.data[0].idObra;
                  if (Object.keys(completed).length <= 0 || (Object.keys(completed).length == 1 && completed["0"])) {
                    apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));
                  }

                  //Document
                  this.generateDocx(false);

                  //AHDA 29/05/2019
                  //Authors 
                  if (this.props.currentRol === 12) {
                    // EGS - 16/05/2019
                    const aut = {
                      work_id: response.data[0].idObra,
                      authors: this.state.authorsData
                    };

                    apiPOST('/works/addauthortmp', aut).then(
                      response => {
                        if (response.status !== 200) {
                        }
                      }).catch(error => {
                        console.log(error);
                      }
                      );
                  } else {
                    const rawAuthors = [...this.state.authorsRawData];
                    if (rawAuthors.length > 0) {
                      let auxAuthors = [];
                      for (let x in rawAuthors) {
                        auxAuthors.push({
                          rol: rawAuthors[x].rol,
                          id_autor: rawAuthors[x].authors_id,
                          id_obra: response.data[0].idObra
                        });
                      }

                      apiPOST('/works/addauthor', { authors: auxAuthors })
                        .then()
                        .catch(error => {
                          console.log(error);
                        }
                        );
                    }
                  }

                  // Create workContact
                  const requestWorkContact = {
                    companyProvider: fieldsStep0.companyProvider,
                    representative: fieldsStep0.representative,
                    address: fieldsStep0.address,
                    telephone: fieldsStep0.telephone,
                    email: fieldsStep0.email,
                    idWork: response.data[0].idObra
                  }
                  apiPOST('/works/addworkcontact', requestWorkContact).then(
                    response => {
                      if (response.status === 200) {
                        console.log(response)
                        this.setState({ idWorkContact: response.data[0].idWorkContact });
                        localStorage.removeItem('fieldsStep0');
                      } else {
                        this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al guardar contacto" });
                      }
                    }).catch(error => {
                      console.log(error);
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                    }
                    );

                  // Add labels
                  const requestAddLabels = {
                    work_id: response.data[0].idObra,
                    label_value: this.state.labels
                  }

                  apiPOST('/works/addlabel', requestAddLabels).then(
                    response => {
                      if (response.status !== 200) {
                        this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al guardar etiquetas" });
                      }
                    }
                  ).catch(error => {
                    console.log(error);
                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                  }
                  );
                  //////////////////////////////////
                  // Create materials
                  // Subida a S3
                  // let materials = this.state.dataMaterial
                  let materials = materialesArray
                  let filesUpload = []
                  for (const x in materials) {
                    materials[x].id_obra = response.data[0].idObra
                    filesUpload.push(materials[x].archivo)
                  }
                  if (filesUpload.length > 0) {
                    const folder = 'obras/prospectos/' + response.data[0].idObra + "" + returnCurrentUserId()

                    const updateProgress = (progress) => {
                      if (!this.state.uploadProgress || progress > this.state.uploadProgress)
                        this.setState({ uploadProgress: progress.toFixed(2) })
                    }
                    // TODO ADD OTHER METHOD
                    saveMultipleFilesPromise({
                      path: folder,
                      files: filesUpload,
                      level: 'public',
                    }, updateProgress).then( //error de carga de archivos  a partir de aqui <------
                      response => {
                        response.keys.map(resp => {
                          var indexMaterial = materials.findIndex(material => material.archivo.name === resp.name)
                          // console.log(indexMaterial)
                          // if (indexMaterial) {
                          materials[indexMaterial].ruta = resp.ruta;
                          // }
                        })
                        // for (let x in materials) {
                        //   materials[x].ruta = response.keys[x].ruta
                        // }
                        // materials.map(resp=> )
                        // console.log(materials)

                        materials.map(material => material.ruta.length === 0 ? material.ruta = material.archivo.ruta : null)


                        let mat = {};
                        mat.material = materials

                        // Add db
                        apiPOST('/works/addmultimedia', mat).then(
                          response => {
                            if (response.status !== 200) {
                              this.setState({ loading: false, openAlert: true, variant: "error", message: "Ocurrió un error al guardar materiales" });
                            } else {
                              for (let z in materials) {
                                materials[z].archivo = null
                              }
                              this.setState(
                                {
                                  loading: false,
                                  dataMaterials: materials
                                }, () => {
                                  completed[this.state.activeStep] = true;
                                  this.setState({ completed, uploadProgress: null });
                                  this.handleNext();
                                }
                              )
                            }
                          }).catch(error => {
                            console.log(error);
                            this.setState({ loading: false, openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                          });


                      }).catch(
                        err => {
                          this.setState({ loading: false, openError: true, message: "Ocurrió un al subir la imagen" });
                        })


                  } else {
                    this.setState(
                      {
                        loading: false
                      }, () => {
                        localStorage.setItem('fieldsStep2', true);
                        completed[this.state.activeStep] = true;
                        this.setState({ completed });
                        this.handleNext();
                      }
                    )
                  }
                  // JCMY 13/05/2019
                  // completed[this.state.activeStep] = true;
                  // this.setState({ completed });
                  // this.handleNext();
                  // this.setState({ loading: false })

                } else {
                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al guardar obra", loading: false });
                }



              }).catch(error => {
                console.log(error);
                this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", loading: false });
              });

          } else {
            const requestUpdateWork = {
              titulo_original: this.state.title,
              tipo_obra: this.state.typeWork,
              numero_capitulos: this.state.chapters == "" ? 0 : this.state.chapters,
              duracion_min_capitulos: this.state.duration == "" ? 0 : this.state.duration,
              logline: this.state.logline.trim() == "" ? "" : JSON.stringify(this.state.logline).replace('"{', '{').replace('}"', '}'),
              comentarios: this.state.comments,
              id_usuario: returnCurrentUserId(),
              typeProyect: this.state.typeProyect,
              id_fuente: this.state.source,
              id_pais: this.state.country,
              //TODO: Add id categoria

              id_formato: this.state.format,
              id_genero: this.state.gender,
              id_subgenero: this.state.subgenre == "" ? null : this.state.subgenre,
              id_obras: this.state.idObra,
              sinopsis_corta: this.state.shortSynopsis.trim() == "" ? "" : JSON.stringify(this.state.shortSynopsis).replace('"{', '{').replace('}"', '}'),
              sinopsis_larga: this.state.longSynopsis.trim() == "" ? "" : JSON.stringify(this.state.longSynopsis).replace('"{', '{').replace('}"', '}'),
              author_register: this.state.author_register, //AHDA 29/05/2019

              // #region [rgba(0,100,30,0.1)] EGS - 22/04/2019
              empresa_proveedor: this.state.companyProviderCatalog ? this.state.companyProviderCatalog : null,
              /*titulo_inglés: this.state.WFtitulo_inglés,//Título en inglés *
              titulo_otro_idioma: this.state.WFtitulo_otro_idioma,//Título en otro idioma *
              obra_primigenia: this.state.WFobra_primigenia,//Título de la obra primigenia* *
              clasificacion: this.state.WFclasificacion,//*Destino*
              horario: this.state.WFhorario,// Horario*
              version: this.state.WFproNAdaptacion,//Número de adaptación*
              producida: this.state.WFproProducida,//Producida*
              anio_produccion: new Date(this.state.WFanio_produccion).getFullYear(), // Año de prducción
              ep_alAire: this.state.WFep_alAire,  //Episodis al aire
              aired_duration: this.state.WFaired_duration, //Duración total
              id_tematica: this.state.WFid_tematica,// Temática*
              id_desarrollo: this.state.WFid_desarrollo,// Ambiente*
              id_motivacion_conflicto: this.state.WFid_motivacion_conflicto,// Motivación*
              id_audiencia: this.state.WFid_audiencia,// Audiencia*
              id_temporalidad: this.state.WFid_temporalidad,// Temporalidad*
              id_ventana: parseInt(this.state.WFid_ventana),// Ventana**/

              // #endregion

            }

            // Case update
            apiPOST('/works/updatework', requestUpdateWork).then(
              response => {
                if (response.status === 200) {

                  // Create workContact
                  const requestWorkContact = {
                    idWC: this.state.idObra,
                    companyProvider: this.state.companyProvider,
                    representative: this.state.representative,
                    address: this.state.address,
                    telephone: this.state.telephone,
                    email: this.state.email
                  }

                  //AHDA 28/05/2019
                  //Added audit call
                  auditBody.work_id = this.state.idObra;
                  if (Object.keys(completed).length <= 0 || (Object.keys(completed).length == 1 && completed["0"])) {
                    apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));
                  }

                  //AHDA 29/05/2019
                  //Authors 
                  if (this.props.currentRol === 12) {
                    // JCMY 11/07/2019 incidencia 1019
                    let auxAut = []
                    for (let x in this.state.authorsData) {
                      const block = this.state.authorsData[x].block;
                      if (block == undefined) {
                        auxAut.push(this.state.authorsData[x])
                      }
                    }



                    // EGS - 16/05/2019
                    const aut = {
                      work_id: this.state.idObra,
                      authors: auxAut
                    };

                    apiPOST('/works/deleteauthortmp', { work_id: this.state.idObra }).then(
                      response => {
                        if (response.status === 200) {
                          apiPOST('/works/addauthortmp', aut).then(
                            response => {
                              if (response.status !== 200) {
                              }
                            }).catch(error => {
                              console.log(error);
                            });
                        }
                      }).catch(error => {
                        console.log(error);
                      }
                      );
                  } else {
                    const rawAuthors = [...this.state.authorsRawData];
                    if (rawAuthors.length > 0) {
                      let auxAuthors = [];
                      for (let x in rawAuthors) {
                        auxAuthors.push({
                          rol: rawAuthors[x].rol,
                          id_autor: rawAuthors[x].authors_id
                        });
                      }

                      apiPOST('/works/updateauthor', { work_id: this.state.idObra, authors: auxAuthors })
                        .then()
                        .catch(error => {
                          console.log(error);
                        }
                        );
                    }
                  }

                  // EGS - 16/05/2019  
                  // AUTORES CAPTURADOS POR EL USUARIO
                  /*const aut = {
                    work_id: this.state.idObra,
                    authors: this.state.authsUsr
                  };
                  
                  apiPOST('/works/deleteauthortmp', { work_id: this.state.idObra }).then(
                    response => {
                      if (response.status === 200) {
                        apiPOST('/works/addauthortmp', aut).then(
                          response => {
                            if (response.status !== 200) {
                            }
                          }).catch(error => {
                            console.log(error);
                          });
                      }
                    }).catch(error => {
                      console.log(error);
                    }
                  );

                  // AUTORES DE CATALOGO
                  if (this.state.authsCatalog.length > 0) {
                    let reqAuth = [];
                    let authorsCat = [...this.state.authsCatalog]
                    for (let x in authorsCat) {
                      let tempVar = {
                        id_autor: parseInt(authorsCat[x].authors_id),
                        rol: authorsCat[x].rol
                      }
                      reqAuth.push(tempVar)
                    }

                    let autRequest = {
                      work_id: this.state.idObra,
                      authors: reqAuth
                    };

                    apiPOST('/works/updateauthor', autRequest).then(
                      response => {
                        if (response.status === 200) {

                        }
                      }).catch(error => {
                        console.log(error);
                      });
                  }*/


                  apiPOST('/works/updateworkcontact', requestWorkContact).then(
                    response => {
                      if (response.status === 200) {
                        localStorage.removeItem('fieldsStep0Update');
                      } else {
                        this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar contacto" });
                      }
                    }).catch(error => {
                      console.log(error);
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                    });

                  // Delete label
                  apiPOST('/works/deletelabel', { work_id: this.state.idObra }).then(
                    response => {
                      if (response.status === 200) {

                        const requestAddLabels = {
                          work_id: this.state.idObra,
                          label_value: this.state.labels
                        }


                        apiPOST('/works/addlabel', requestAddLabels).then(
                          response => {
                            if (response.status !== 200) {
                              this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar etiquetas" });
                            }
                            // isanchez loading +
                            // else{
                            //   this.setState(
                            //     {
                            //       loading : false
                            //     }
                            //   )
                            // }
                          }).catch(error => {
                            console.log(error);
                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                          });

                      } else {
                        this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar etiquetas" });
                      }
                    }).catch(error => {
                      console.log(error);
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                    });




                  // Delete multimedia
                  // let materials = this.state.dataMaterial//materialesArray
                  // let filesUpload = []
                  // for (const x in materials) {
                  //   materials[x].id_obra = response.data[0].idObra
                  //   if(materials[x].archivo)
                  //     filesUpload.push(materials[x].archivo)
                  // }
                  // console.log(filesUpload)

                  let materials = this.state.dataMaterial


                  let filesUpload = []
                  for (const x in materials) {
                    if (materials[x].archivo !== null) {
                      materials[x].id_obra = this.state.idObra
                      filesUpload.push(materials[x].archivo)
                    }

                  }

                  if (filesUpload.length > 0) {
                    const folder = 'obras/prospectos/' + this.state.idObra + "" + returnCurrentUserId()
                    const updateProgress = (progress) => {
                      if (!this.state.uploadProgress || progress > this.state.uploadProgress)
                        this.setState({ uploadProgress: progress.toFixed(2) })
                    }
                    saveMultipleFilesPromise({
                      path: folder,
                      files: filesUpload,
                      level: 'public',
                    }, updateProgress).then(response => {
                      if (response.message !== "No files") {
                        let listkeys = []
                        if (materials.length === response.keys.length) {
                          for (let y in response.keys) {
                            listkeys.push(response.keys[y].key)
                          }
                        } else {

                          // for (let y in materials) {
                          //   if (materials[y].ruta !== "") {
                          //     listkeys.push(materials[y].ruta)
                          //   }
                          // }

                          materials.map(
                            item => {
                              if (item.ruta !== '') {
                                listkeys.push(item.ruta)
                              }
                            }
                          )

                          // for (let y in response.keys) {
                          //   listkeys.push(response.keys[y].key)
                          // }
                          response.keys.map(
                            item => {
                              if (item.ruta !== '') {
                                listkeys.push(item.ruta)
                              }
                            }
                          )

                        }


                        const version = parseInt((materials[0].version).charAt((materials[0].version).length - 1))

                        for (let z in materials) {
                          if (materials[z].archivo !== null) {
                            materials[z].ruta = listkeys[z]
                            materials[z].version = "v" + (version + 1)
                          }
                        }
                      }


                      let mat = {};
                      mat.material = materials.filter(e => e.archivo != null)

                      // Add db
                      apiPOST('/works/addmultimedia', mat).then(
                        response => {
                          if (response.status !== 200) {
                            this.setState({ loading: false, openAlert: true, variant: "error", message: "Ocurrió un error al guardar materiales" });
                          } else {
                            for (let z in materials) {
                              materials[z].archivo = null
                            }
                            this.setState(
                              {
                                loading: false,
                                dataMaterials: materials
                              }, () => {
                                completed[this.state.activeStep] = true;
                                this.setState({ completed, uploadProgress: null });
                                this.handleNext();
                              }
                            )
                            const statusWorkFlow = this.state.statusWorkFlow
                            if (statusWorkFlow == "Más material") {
                              const requestGet = {
                                portal: "obras",
                                notificationId: 4
                              }
                              apiPOST('/notifications/getemailanduser', requestGet).then(
                                response => {
                                  if (response.status === 200) {
                                    const users = response.data[1]


                                    const emails = response.data[0]
                                    let emailList = []
                                    for (const x in emails) {
                                      emailList.push(emails[x].usuario_cognito)
                                    }
                                    let authors = ""
                                    const dataAuthors = this.state.authorsData
                                    for (const x in dataAuthors) {
                                      if (x == dataAuthors.length - 1) {
                                        authors = authors + dataAuthors[x].name
                                      } else {
                                        authors = authors + dataAuthors[x].name + ", "
                                      }
                                    }
                                    if (emailList.length > 0) {
                                      const requestEmail = {
                                        emails: emailList.filter(e => e != ""),
                                        type: 'additionalMaterialReception',
                                        work: {
                                          titulo_original: this.state.title
                                        },
                                        portal: "obras"
                                      }
                                      apiPOST('/notifications/sendemail', requestEmail).then(
                                        response => {
                                          if (response.status !== 200) {
                                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar correo electrónico" });
                                          }
                                        }).catch(error => {
                                          console.log(error);
                                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                        });
                                    }


                                    let userList = []
                                    for (const x in users) {
                                      userList.push(users[x].id_usuario)
                                    }
                                    // Envio de notificacion
                                    for (const x in userList) {
                                      const requestNotification = {
                                        notification_type: "additionalMaterialReception",
                                        id_user: userList[x],
                                        id_work: this.props.workId,
                                        portal: "obras",
                                        specifiedProductor: this.state.responsibleUser,
                                        id_notification_type: 4
                                      }
                                      apiPOST('/notifications/add', requestNotification).then(
                                        response => {
                                          if (response.status !== 200) {
                                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar la notificación" });
                                          }
                                        }).catch(error => {
                                          console.log(error);
                                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                        });
                                    }
                                  } else {
                                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al obtener correos y usuarios a notificar" });
                                  }
                                }).catch(error => {
                                  console.log(error);
                                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                });
                            }
                          }
                        }).catch(error => {
                          console.log(error);
                          this.setState({ loading: false, openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                        });

                    }).catch(
                      err => {
                        this.setState({ loading: false, openError: true, message: "Ocurrió un al subir la imagen" });
                      })

                  } else {
                    this.setState(
                      {
                        loading: false
                      }, () => {
                        completed[this.state.activeStep] = true;
                        this.setState({ completed });
                        this.handleNext();
                      }
                    )
                  }

                  // JCMY 13/05/2019
                  // completed[this.state.activeStep] = true;
                  // this.setState({ completed });
                  // this.handleNext();
                  // // isanchez 02.08.2019 comment this loading
                  // this.setState({ loading: false })

                } else {
                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la obra", loading: false });
                }
              }).catch(error => {
                console.log(error);
                this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", loading: false });
              });

          }


          // JCMY 10/05/2019
          //  } else {
          //    this.setState({ openAlert: true, variant: "warning", message: "Favor de aceptar politicas" });
          //  }

        } else {
          this.setState({ openAlert: true, variant: "warning", message: "Favor de capturar un Autor o Adaptador de la obra", loading: false, expanded: "panel2" });
        }


        break;
      case 2:
        this.setState({ loading: true })
        //AHDA 10/05/2019
        //Added audit call
        //AHDA 28/05/2019: Changed validation
        if (Object.keys(completed).length <= 0 || (Object.keys(completed).length == 1 && completed["0"])) {
          apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));
        }

        let requestWork = {
          id_obras: this.state.idObra,
          titulo_original: this.state.title,
          tipo_obra: this.state.typeWork,
          numero_capitulos: this.state.chapters == "" ? 0 : this.state.chapters,
          duracion_min_capitulos: this.state.duration == "" ? 0 : this.state.duration,
          sinopsis_corta: this.state.shortSynopsis.trim() == "" ? "" : JSON.stringify(this.state.shortSynopsis).replace('"{', '{').replace('}"', '}'),
          logline: this.state.logline.trim() == "" ? "" : JSON.stringify(this.state.logline).replace('"{', '{').replace('}"', '}'),
          comentarios: this.state.comments,
          id_usuario: returnCurrentUserId(),
          sinopsis_larga: this.state.longSynopsis.trim() == "" ? "" : JSON.stringify(this.state.longSynopsis).replace('"{', '{').replace('}"', '}'),
          typeProyect: this.state.typeProyect,
          id_fuente: this.state.source,
          id_pais: this.state.country,
          id_formato: this.state.format,
          id_genero: this.state.gender,
          id_subgenero: this.state.subgenre == "" ? null : this.state.subgenre,
          author_register: this.state.author_register, //isanchez 28/06/2019
          // #region [rgba(0,100,30,0.1)] EGS - 22/04/2019
          empresa_proveedor: this.state.companyProviderCatalog != null ? this.state.companyProviderCatalog : this.state.WFcompanyProvCat,
          titulo_inglés: this.state.WFtitulo_inglés,//Título en inglés *
          titulo_otro_idioma: this.state.WFtitulo_otro_idioma,//Título en otro idioma *
          obra_primigenia: this.state.WFobra_primigenia,//Título de la obra primigenia* *
          clasificacion: this.state.WFclasificacion,//*Destino*
          horario: this.state.WFhorario,// Horario*
          //version: this.state.WFproNAdaptacion,//Número de adaptación*
          producida: this.state.WFproProducida,//Producida*
          anio_produccion: new Date(this.state.WFanio_produccion).getFullYear(), // Año de prducción
          ep_alAire: this.state.WFep_alAire,  //Episodis al aire
          aired_duration: this.state.WFaired_duration, //Duración total
          id_tematica: this.state.WFid_tematica,// Temática*
          id_desarrollo: this.state.WFid_desarrollo,// Ambiente*
          id_motivacion_conflicto: this.state.WFid_motivacion_conflicto,// Motivación*
          id_audiencia: this.state.WFid_audiencia,// Audiencia*
          id_temporalidad: this.state.WFid_temporalidad,// Temporalidad*
          id_ventana: parseInt(this.state.WFid_ventana),// Ventana*

          // #endregion
        }

        // Create work
        apiPOST('/works/updatework', requestWork).then(
          response => {
            if (response.status === 200) {

              // JCMY 30/04/2019
              // Si esta en estus de mas material se pasa a revisar mas material
              const statusWorkFlow = this.state.statusWorkFlow
              if (statusWorkFlow == "Más material") {

                const request = {
                  workId: this.state.idObra,
                  approved: 1,
                  ownerId: 0,
                  assigneeId: 0,
                  dueDate: "0",
                  description: "",
                  causeNonViability: 0
                }


                fetchCall("POST", "/changeStepWorkFlow", request).then(
                  response => {
                    if (response.status === 200) {

                      this.setState({ statusWorkFlow: response.data.step });

                      const requestGet = {
                        portal: "obras",
                        notificationId: 4
                      }


                      apiPOST('/notifications/getemailanduser', requestGet).then(
                        response => {
                          if (response.status === 200) {
                            const users = response.data[1]

                            let userList = []


                            for (const x in users) {
                              userList.push(users[x].id_usuario)
                            }

                            // Envio de notificacion
                            for (const x in userList) {
                              const requestNotification = {
                                notification_type: "additionalMaterialReception",
                                id_user: userList[x],
                                id_work: this.props.workId,
                                portal: "obras",
                                specifiedProductor: this.state.responsibleUser,
                                id_notification_type: 4
                              }

                              apiPOST('/notifications/add', requestNotification).then(
                                response => {
                                  if (response.status !== 200) {
                                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar la notificación" });
                                  }
                                }).catch(error => {
                                  console.log(error);
                                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                });
                            }
                          } else {
                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al obtener correos y usuarios a notificar" });
                          }
                        }).catch(error => {
                          console.log(error);
                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                        });


                      // JCMY 13/05/2019
                      completed[this.state.activeStep] = true;
                      this.setState({ completed });
                      this.handleNext();
                      this.setState({ loading: false })

                    } else {
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al cambiar de estado", loading: false });
                    }
                  }).catch(error => {
                    console.log(error);
                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", loading: false });
                  });
              }

              if (this.state.update === 0 || this.state.statusWorkFlow === null) {

                // JCMY 25/04/2019
                // Creation workflow
                const requestWorkflow = {
                  workId: this.state.idObra,
                  userId: returnCurrentUserId(),
                  title: this.state.title,
                  "assigneeId": returnCurrentUserId(),
                  "description": this.state.comments
                }


                fetchCall("POST", "/createWorkFlow", requestWorkflow).then(
                  response => {
                    if (response.status === 200) {
                      localStorage.removeItem('fieldsStep2');
                      const requestStage = {
                        idObra: this.state.idObra,
                        idWorkflow: response.data.workFlowId,
                        idStatus: 1
                      }

                      apiPOST('/works/updatestage', requestStage).then(
                        response => {
                          if (response.status === 200) {

                          } else {
                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar obra" });
                          }
                        }).catch(error => {
                          console.log(error);
                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                        });



                      // Send email
                      const requestGet = {
                        portal: "obras",
                        notificationId: 1
                      }


                      apiPOST('/notifications/getemailanduser', requestGet).then(
                        response => {
                          if (response.status === 200) {
                            const emails = response.data[0]
                            const users = response.data[1]

                            // Get correos y usuarios
                            let emailList = []
                            let userList = []

                            for (const x in emails) {
                              emailList.push(emails[x].usuario_cognito)
                            }

                            for (const x in users) {
                              userList.push(users[x].id_usuario)
                            }


                            // Envio de correo a telvisa

                            const countries = this.state.countries
                            const country = this.state.country
                            const objContry = countries.find(obj => obj.value === country);
                            let authors = ""

                            const dataAuthors = this.state.authorsData
                            for (const x in dataAuthors) {
                              if (x == dataAuthors.length - 1) {
                                authors = authors + dataAuthors[x].name
                              } else {
                                authors = authors + dataAuthors[x].name + ", "
                              }

                            }

                            const durations = this.state.durations
                            const duration = this.state.chapters == "" ? 0 : this.state.duration
                            const objDurations = durations.find(obj => obj.value === duration);

                            const formats = this.state.formats
                            const format = this.state.format
                            const objFormats = formats.find(obj => obj.value === format);

                            const genders = this.state.genders
                            const gender = this.state.gender
                            const objGenders = genders.find(obj => obj.value === gender);

                            // JCMY 01/04/2019 Se valida que la lista tenga correos
                            if (emailList.length > 0) {
                              const requestEmail = {
                                emails: emailList.filter(e => e != ""),
                                type: "newWorkRegister",
                                work: {
                                  titulo_original: this.state.title,
                                  tipo_obra: this.state.typeWork,
                                  pais: objContry.data,
                                  autores: authors,
                                  capitulos: this.state.chapters == "" ? "-" : this.state.chapters,
                                  duracion_cap: objDurations == undefined ? "-" : objDurations.data,
                                  formato: objFormats.data,
                                  genero: objGenders.data,
                                  empresa: this.state.companyProvider
                                },
                                portal: "obras"
                              }


                              apiPOST('/notifications/sendemail', requestEmail).then(
                                response => {
                                  if (response.status !== 200) {
                                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar correo electrónico" });
                                  }
                                }).catch(error => {
                                  console.log(error);
                                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                });
                            }


                            // Envio de notificacion
                            for (const x in userList) {
                              const requestNotification = {
                                notification_type: "newWorkRegister",
                                id_user: userList[x],
                                id_work: this.state.idObra,
                                portal: "obras",
                                specifiedProductor: this.state.idWorkContact,
                                id_notification_type: 1
                              }


                              apiPOST('/notifications/add', requestNotification).then(
                                response => {
                                  if (response.status !== 200) {
                                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar la notificación" });
                                  }
                                }).catch(error => {
                                  console.log(error);
                                  this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                                });
                            }



                          } else {
                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al obtener correos y usuarios a notificar" });
                          }
                        }).catch(error => {
                          console.log(error);
                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                        });



                      // Envio de correo a user
                      const user = JSON.parse(localStorage.getItem('current_user'));
                      // console.log(user.rdsUser.asigned_profile);

                      const requestEmailUser = {
                        emails: [user.rdsUser.usuario_cognito],
                        type: "newWorkRegisterUser",
                        user: {
                          name: user.rdsUser.nombre + " " + user.rdsUser.apellidos
                        },
                        portal: "obras"
                      }


                      apiPOST('/notifications/sendemail', requestEmailUser).then(
                        response => {
                          if (response.status !== 200) {
                            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al enviar correo electrónico" });
                          }
                        }).catch(error => {
                          console.log(error);
                          this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                        });




                      // JCMY 13/05/2019
                      completed[this.state.activeStep] = true;
                      this.setState({ completed });
                      this.handleNext();
                      this.setState({ loading: false })

                    } else {
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al crear workflow", loading: false });
                    }
                  }).catch(error => {
                    console.log(error);
                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", loading: false });
                  }).finally(res => {
                    this.setState({ loading: false });
                  });


              }
              //AHDA 17/05/2019
              else {
                completed[this.state.activeStep] = true;
                this.setState({ completed, loading: false });
                this.handleNext();
              }

            } else {
              this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la obra" });
            }

          }).catch(error => {
            console.log(error);
            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la obra" });
          })


        break;
      default:
        break;
    }


  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  //Expandables

  // handleNext = (skip) => {

  //   this.form.walk(this.form.childs).then(isValid => { //validador de formulario para eventos y pase

  //   console.log(this.form)
  //   let auxErro = []
  //   this.form.childs.map(f=>{
  //     if(!f.state.inValid){
  //       auxErro.push(f)
  //     }
  //   })
  //   auxErro.map(item=>console.log(item.props.name))
  //     if (isValid) {
  //       console.log('valid')
  //       let activeStep;
  //       if (this.isLastStep() && !this.allStepsCompleted()) {
  //         const steps = getSteps();
  //         activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
  //       } else {
  //         activeStep = this.state.activeStep + 1;
  //       }

  //       this.setState({ activeStep });

  //     } else {
  //       console.log('novali')
  //     }
  //   })
  // };

  handleChangeExpand = panel => (event, expanded) => {
    switch (panel) {
      case 'panel1':
        // this.handleCatalogue('')
        break
    }
    this.setState({ expanded: expanded ? panel : false, });

  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  totalSteps = () => {
    return (
      getSteps().length
    )
  };

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  handleNext = (skip) => {

    let activeStep;
    if (this.isLastStep() && !this.allStepsCompleted()) {
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }

    this.setState({ activeStep });
  };

  handleBack = () => {
    const { activeStep, completed } = this.state;

    delete completed[activeStep - 1]


    this.setState({ activeStep: activeStep - 1, completed: completed });
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleCatalogue = (catalogue, idCatalogue) => {
    if (this.state[catalogue].length <= 0) {
      //AHDA 17/05/2019: added validation for refresh 
      apiPOST('/catalogos/getCatalogo', { idCatalogo: idCatalogue }, true).then(
        response => {
          if (catalogue === 'materials') {
            let find = response.data.findIndex(x => x.value === 'evaluacion');
            if (find !== -1) {
              response.data.splice(find, 1);
            }
          }
          if (response.status === 200) {
            this.setState({ [catalogue]: response.data });
          }
        }
      )
    }
  }

  componentDidMount() {
    materialesArray = [];
    const user = JSON.parse(localStorage.getItem('current_user'));
    const rdsUser = user ? user.rdsUser : undefined;

    if (rdsUser) {
      this.setState({ rolId: rdsUser.id_rol, rolName: rdsUser.asigned_profile });
    }

    this.handleCatalogue('materials', 5);//JCMY 09/07/2019incidecnia 1066

    //AHDA 27/05/2019
    //Validation for phone
    ValidatorForm.addValidationRule('isPhoneMin', (value) => {
      if ((value.length >= 10) || value.length === 0) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('isPhoneMax', (value) => {
      if ((value.length <= 20) || value.length === 0) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('isChapterNumber', (value) => {
      const regChapter = /^[0-9.,-\s]*$/;
      const regDouble = /[.,-]{2,}/;
      return regChapter.test(value) && !regDouble.test(value);
    });

    const workId = this.props.workId
    // EGS - 24/04/2019  Carga de catalogo de empresa proveedor para los usuarios internos
    this.handleCatalogue('companysProviders', 7);

    //AHDA 20/05/2019 - Commented
    /*this.state.currentUsr = JSON.parse(localStorage.getItem('current_user'));
    console.log(this.state.currentUsr["cognito:groups"]);*/

    if (workId != 0) {
      //AHDA 28/05/2019: Added loading
      this.setState({ loading: true });

      //AHDA 29/05/2019: Commented unnecesary method
      // #region [rgba(0,100,30,0.1)] EGS - 22/04/2019
      /*apiPOST('/works/getdetailworkfull', { idObra: workId }).then(
        response => {
          if (response.status === 200) {
            const work = response.data[0][0];
            let allAuths = [...response.data[7]];

            for (let i in response.data[2]) {
              allAuths.push(response.data[2][i]);
            }
            
            this.setState({
              // Daatos Generales
              //Deleted unnecesary state
              // Autores
              authsUsr: response.data[2],
              authsCatalog: response.data[7],
              authorsData: allAuths
            })
          } else {
            console.log('Error al cargar la información de la obra');
          }

        }).catch(error => {
          console.log(error);
        });*/
      // #endregion

      apiPOST('/works/getdetailwork', { idObra: workId }).then(
        response => {

          if (response.status === 200) {
            const work = response.data[0][0]
            let lab = []
            const listLabels = response.data[1]
            for (const x in listLabels) {
              lab.push(listLabels[x].value)
            }

            let materials = []
            let dataMaterials = []
            const listMaterial = response.data[3]
            for (const x in listMaterial) {

              let material = {}
              material.id_material_multimedia = listMaterial[x].id_material_multimedia || (parseInt(x) + 1).toString()
              material.titleMaterial = listMaterial[x].material_nombre || ''
              material.typeMaterial = listMaterial[x].tipo_archivo
              material.chapterNumberMaterial = listMaterial[x].numero_capitulo || '0'
              material.language = listMaterial[x].descripcion
              materials.push(material)

              // Add data db
              material = {}
              material.id = (parseInt(x) + 1).toString()
              material.material_nombre = listMaterial[x].material_nombre || ''
              material.tipo_archivo = listMaterial[x].tipo_archivo
              material.ruta = listMaterial[x].ruta
              material.numero_capitulo = listMaterial[x].numero_capitulo || '0'
              material.id_obra = workId
              material.id_idioma = listMaterial[x].id_idioma
              material.idTipoMaterial = listMaterial[x].id_tipo_material
              material.version = listMaterial[x].version
              material.archivo = null
              material.id_material_multimedia = listMaterial[x].id_material_multimedia || (parseInt(x) + 1).toString()
              dataMaterials.push(material)
            }

            //AHDA 29/05/2019: Authors
            // JCMY 11/07/2019 inciencia 1019

            let allAuths = [], allRawAuths = [];
            // if(this.props.currentRol === 12){
            allAuths = [...response.data[2]];
            allRawAuths = [...response.data[2]];
            // }else{
            allRawAuths = [...response.data[4]];
            for (let x in allRawAuths) {
              let auxItem = {
                authors_id: allRawAuths[x].id,
                name: allRawAuths[x].name,
                rol: allRawAuths[x].rol,
                block: this.state.rolId !== 20 ? "locked" : null
              }
              allAuths.push(auxItem);
            }
            // }
            const [loglineLength, loglineEditorState] = this.convertContent('logLine', work.logline);
            const [shortSynopsisLength, shortSynopsisEditorState] = this.convertContent('shortSynopsis', work.sinopsis_corta);
            const [longSynopsisLength, longSynopsisEditorState] = this.convertContent('longSynopsis', work.sinopsis_larga);

            this.setState({
              //Company
              companyProvider: work.companyProvider ? work.companyProvider : "",
              companyProviderCatalog: work.companyProviderCatalog,
              representative: work.representative,
              address: work.address,
              telephone: work.telephone,
              email: work.email,
              //General data
              title: work.titulo_original,
              typeWork: work.tipo_obra,
              country: work.id_pais,
              gender: work.id_genero,
              subgenre: work.id_subgenero,
              format: work.id_formato,
              typeProyect: work.typeProyect,
              chapters: work.numero_capitulos == null ? "" : work.numero_capitulos,
              duration: work.duracion_min_capitulos,
              source: work.id_fuente,
              labels: lab,
              logline: work.logline == null ? "" : work.logline,
              loglineEditorState,
              loglineLength,

              comments: work.comentarios == null ? "" : work.comentarios,
              author_register: work.author_register,//Registro de Autor*
              //Authors 
              authorsRawData: allRawAuths, //AHDA 28/05/2019
              authorsData: allAuths, //AHDA 28/05/2019
              //Material
              dataMaterialTable: materials,
              dataMaterial: dataMaterials,
              //Synopsis
              shortSynopsis: work.sinopsis_corta == null ? "" : work.sinopsis_corta,
              shortSynopsisLength,
              shortSynopsisEditorState,

              longSynopsis: work.sinopsis_larga == null ? "" : work.sinopsis_larga,
              longSynopsisLength,
              longSynopsisEditorState,
              //Others
              checked: true,
              idObra: workId,
              update: 1,
              statusWorkFlow: work.statusWorkFlow, // JCMY 22/04/2019
              loading: false //AHDA 28/05/2019
            })
          }
        }).catch(error => {
          console.log(error);
          this.setState(
            {
              loading: false
            }
          )
        });
    } else {
      if (localStorage.getItem('fieldsStep0') != null) {
        const fieldsStep0 = JSON.parse(localStorage.getItem('fieldsStep0'));
        this.setState({ companyProviderCatalog: fieldsStep0.companyProviderCatalog, companyProvider: fieldsStep0.companyProvider, representative: fieldsStep0.representative, address: fieldsStep0.address, telephone: fieldsStep0.telephone, email: fieldsStep0.email });
      }
    }


    // EGS - 24/04/2019 - Carga de catalogos por metodo handle
    this.handleCatalogue('countries', 3);
    this.handleCatalogue('genders', 4);
    this.handleCatalogue('subgenres', 25);
    this.handleCatalogue('formats', 9);
    this.handleCatalogue('durations', 23);
    this.handleCatalogue('sources', 11);
    this.handleCatalogue('authors', 18); //AHDA 28/05/2019: Se hizo cambio de catálogo
    this.handleCatalogue('languages', 32);

  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {},
      // Clean step 0
      companyProvider: "",
      representative: "",
      address: "",
      telephone: "",
      email: "",
      // Clean step 1
      title: "",
      typeWork: "",
      country: "",
      gender: "",
      subgenre: "",
      format: "",
      typeProyect: "",
      chapters: "",
      duration: "",
      source: "",
      labels: "",
      logline: "",
      comments: "",
      checked: false,


      name: "",
      participation: "",

      // Inputs step 1 materials
      typeMaterial: "",
      language: "",
      titleMaterial: "",
      chapterNumberMaterial: 0,
      dataMaterial: [],
      dataMaterialTable: [],
      isMaterialMultipleFiles: false,

      idObra: 0,

      // Inpunt step 2
      shortSynopsis: "",
      longSynopsis: "",

      update: 0

    });
  };

  handleChangeChecked = name => event => {
    //AHDA 17/05/2019
    this.setState({ checked: event.target.checked });
  };

  onDrop = (files) => {

    const { typeMaterial, acceptedFiles } = this.state;

    if (
      (typeMaterial === undefined || typeMaterial === "") ||
      (!Array.isArray(acceptedFiles) && (Array.isArray(acceptedFiles) && acceptedFiles.length < 1))
    ) {
      this.setState({ openAlert: true, variant: "warning", message: "Tipo de archivo no seleccionado" });
      return;
    }

    if (!files) {
      this.setState({ openAlert: true, variant: "warning", message: "Tipo de archivo no valido" });
    } else {
      let oldFiles = this.state.files;

      if (files.length > 1) {
        let unsupportedFiles = [];
        for (let file of files) {

          const isFileTypeValid = acceptedFiles.find(x => x === file.type);
          const extension = file.name.split(".").pop();
          const isExtensionValid = file.type === "" && extension !== "" && extension === "rar";
          if (isExtensionValid || (file.type !== "" && !!isFileTypeValid)) {
            oldFiles = oldFiles.concat(file);
            this.setState({ selectFile: oldFiles, files: oldFiles, filesMaterial: oldFiles, isMaterialMultipleFiles: true });
          } else {
            unsupportedFiles.push(file.name);
          }
        }

        if (unsupportedFiles.length > 0) {
          this.setState({
            openAlert: true,
            variant: "warning",
            message: "Uno o más archivos no son validos"
          });
        }

      } else if (files.length === 1) {
        const [file] = files;
        const isFileTypeValid = acceptedFiles.find(x => x === file.type);
        const extension = file.name.split(".").pop();
        const isExtensionValid = file.type === "" && extension !== "" && extension === "rar";
        if (isExtensionValid || (file.type !== "" && !!isFileTypeValid)) {
          oldFiles = oldFiles.concat(file);
          this.setState({ selectFile: file, files: oldFiles, filesMaterial: oldFiles, isMaterialMultipleFiles: false });
        } else {
          this.setState({ openAlert: true, variant: "warning", message: "Tipo de archivo no valido" });
        }
      }


    }

  }

  handleRemove = (file, fileIndex) => {
    let oldFiles = this.state.files;
    window.URL.revokeObjectURL(file.preview);
    oldFiles.splice(fileIndex, 1);
    this.setState({ selectFile: {}, files: oldFiles });
  }

  handleBlur = (event) => {
    const error = `error${event.target.name}`;
    // JCMY 02/04/18 Valida espacios en blanco
    if (!event.target.value || event.target.value === "" || !(/^(?!\s*$).+/g).test(event.target.value)) {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  }

  //isanchez 13.05.2019 upload to s3 docx
  generateDocx = (band) => {

    if (this.state.title === undefined || this.state.title === '') {
      this.setState({ openAlert: true, variant: "warning", message: "Ingresa el titulo de la obra" });
      return false;
    } else if (this.state.authorsData.length === 0) {
      //AHDA 17/05/2019
      //Changed message
      this.setState({ openAlert: true, variant: "warning", message: "Favor de capturar un Autor o Adaptador de la obra" });
      return false;
    }

    const doc = new Document({
      title: `Carta Derechos Autor - ${this.state.title}`
    });

    doc.Styles.createParagraphStyle('Heading1', 'Heading 1')
      .basedOn("Normal")
      .next("Normal")
      .quickFormat()
      .underline()
      .size(28)
      .center()
      .spacing({ after: 120 });

    doc.Styles.createParagraphStyle('wellSpaced', 'Well Spaced')
      .basedOn('Normal')
      .spacing({ line: 276, before: 20 * 72 * .1, after: 20 * 72 * .05 });

    doc.Styles.createParagraphStyle('ListParagraph', 'List Paragraph')
      .quickFormat()
      .basedOn('Normal');
    doc.Styles.createParagraphStyle('resalted', 'Well Spaced')
      .basedOn('Normal')
      .underline();
    doc.Styles.createParagraphStyle('resaltedBold', 'Well Spaced')
      .basedOn('Normal')
      .bold()
      .underline();

    const numberedAbstract = doc.Numbering.createAbstractNumbering();
    numberedAbstract.createLevel(0, "lowerLetter", "%1)", "left");

    doc.createParagraph('Acervo Literario de Televisa ').heading1();
    // 
    const p1 = new Paragraph()
    const author = new TextRun(`${this.state.authorsData[0].name}, `).style('resalted');
    const parrafo = new TextRun(`por mi propio derecho, pongo a disposición en el sitio https://portalobras.televisa.com.mx la obra titulada`)
      .style('wellSpaced');
    const tituloObra = new TextRun(` ${this.state.title} `).style('resalted');
    const parrafo2 = new TextRun(`(En adelante “La Obra”), y autorizó a Televisa, S.A. de C.V. y cualquiera de sus subsidiarias, filiales o controladoras, para que sólo lleven a cabo el uso de “La Obra” con fines de `)
      .style('wellSpaced');
    const finParrafo = new TextRun('evaluación interna.').style('resaltedBold')
    p1.addRun(author)
    p1.addRun(parrafo)
    p1.addRun(tituloObra)
    p1.addRun(parrafo2)
    p1.addRun(finParrafo)
    // doc.createParagraph(parrafo)
    //   .justified()
    //   .style('wellSpaced');
    doc.addParagraph(p1)


    // const secondParagraph = doc.createParagraph().justified();

    // secondParagraph.createTextRun(`Como consecuencia de lo anterior, el suscrito autoriza a las empresas Televisa, S.A. de C.V., y cualquiera de sus 
    // subsidiarias, filiales o controladoras, para que lleven a cabo el uso y reproducción de “La Obra” con fines de evaluación para la creación y 
    // desarrollo de obras derivadas en lo futuro, según lo establecido en las reglas que a continuación se detallan: `)
    //   .style('wellSpaced');

    const numbering = new Numbering();

    const abstractNum = numbering.createAbstractNumbering();
    abstractNum.createLevel(1, "decimal", "%2.", "start").addParagraphProperty(new Indent({ left: 1440, hanging: 980 }));
    const concrete = numbering.createConcreteNumbering(abstractNum);
    doc.createParagraph('')
    doc.createParagraph('')
    doc.createParagraph("Reconociendo que “La Obra” será almacenada de manera segura en el Acervo de Televisa.").justified().style('wellSpaced')
    // const Step1 = new Paragraph("Reconociendo que “La Obra” será almacenada de manera segura en el Acervo de Televisa.").justified().style('wellSpaced');
    doc.createParagraph('')

    const Step2 = new Paragraph("******").justified().style('wellSpaced');
    // const subP = new Paragraph(`Posible solicitud de más material (Se interrumpen los 60 días de plazo para emitir el dictamen, hasta que 
    //   Televisa reciba el material solicitado)`).justified().style('wellSpaced');
    // const Step3 = new Paragraph(`Emisión de dictamen viable o no viable`).justified().style('wellSpaced');
    // const Step4 = new Paragraph(`En caso de resultar viable el dictamen, Televisa se pondrá en contacto con el proveedor para iniciar el 
    // proceso de compra (90 días).`).justified().style('wellSpaced');

    // Step1.setNumbering(concrete, 0);
    Step2.setNumbering(concrete, 0);
    // subP.setNumbering(concrete, 1);
    // Step3.setNumbering(concrete, 0);
    // Step4.setNumbering(concrete, 0);

    // doc.addParagraph(Step1);
    doc.addParagraph(Step2);
    doc.createParagraph('')
    // var checkbox = String.fromCharCode( '&#9746;')
    doc.createParagraph("[x] He leído, estoy de acuerdo y reconozco el contenido de este documento.")
    // doc.addParagraph(subP);
    // doc.addParagraph(Step3);
    // doc.addParagraph(Step4);
    // doc.createParagraph(`En caso de que “La Obra” resulte seleccionada, el autor y Televisa, S.A. de C.V. negociarán de buena fe los términos y 
    // condiciones de la licencia de derechos patrimoniales correspondiente.`).justified().style('wellSpaced');
    // doc.createParagraph(`De igual forma, declaro contar con las respectivas licencias, autorizaciones y/o derechos para la utilización de la 
    // obra u obras literarias y/o artísticas que se encuentran incorporadas en “La Obra” en términos de lo establecido por la ley Federal del 
    // Derecho de Autor.`).justified().style('wellSpaced');

    // const para = doc.createParagraph().justified();
    // para.createTextRun(`En consecuencia, eximo de cualquier tipo de responsabilidad y me obligo a sacar en paz y a salvo a Televisa, S.A. de C.V. y 
    // cualquiera de sus subsidiarias, filiales o controladoras, como consecuencia de eventuales demandas y/o reclamos, ya sean de naturaleza civil, 
    // mercantil, administrativo y/o penal, incluyendo daños y/o perjuicios, que pudieran derivarse por el uso no autorizado de obras y/o derechos 
    // conexos de terceros.`).style('wellSpaced');
    // doc.createParagraph(`Por su parte. “La Obra” será almacenada de manera segura en el Acervo de Televisa ubicado en el citado sitio de 
    // internet web all.televisa.com`).justified().style('wellSpaced');

    const packer = new Packer();
    const folder = `obras/prospectos/${this.state.idObra}${returnCurrentUserId()}/docAceptación`;
    packer.toBlob(doc).then(blob => {
      if (blob) {
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // a.href = window.URL.createObjectURL(blob);
        // a.download = 'test.docx';
        // a.click();
        // window.URL.revokeObjectURL(window.URL.createObjectURL(blob));
        saveFile(folder, blob, "public", `${this.state.title}.docx`).then(
          response => {
            getFile(response.key, "public").then(
              url => {
                this.setState(
                  {
                    urlDocAcept: `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`,
                    openModalDocAcpt: band
                  }
                )
              }
            )
          }
        ).catch(
          error => {
            console.log("error upload s3", error);
          }
        );
      }
    });
  }

  handleOpenModalDocAcpt = () => {
    this.setState({
      openModalDocAcpt: true
    })
  }

  handlePastedText = (text, html, editorState, onChange) => {

    const selectedBlock = getSelectedBlock(editorState);
    if (selectedBlock && selectedBlock.type == "code") {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text,
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));
      return true;
    }
    else if (html) {
      const blockMap = stateFromHTML(html).blockMap;
      const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blockMap,
      );
      onChange(EditorState.push(editorState, newState, 'insert-fragment'));
      return true;
    }
    return false;
  }

  convertContent = (name, data) => {
    let a = "";
    if (!data) {
      return [0, null];
    }
    try {
      a = JSON.parse(`${data}`);
    } catch (error) {
      console.log(error);
    }

    if (a && (typeof a === "object")) {
      const contentBlock = convertFromRaw(a);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);
      return [editorLength, editorState];
    } else if (data.length > 0) {
      if (data.includes('{"blocks"'))
        data = data.split(",")[1].split(":")[1].replace(/"/g, "");
      const rawContent = {
        "blocks": [
          { "key": "4tt3f", "text": data, "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }
        ],
        "entityMap": {}
      }
      const contentBlock = convertFromRaw(rawContent);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);

      return [editorLength, editorState];
    }

    return [0, null];
  }

  onEditorStateChange = (name, editorState) => {
    const currentContent = editorState.getCurrentContent();

    const converted = convertToRaw(currentContent);
    const editorString = JSON.stringify(converted).replace(/'/g, "");
    let length = this.getEditorContentLength(converted);

    if (name !== 'sinopsis_larga' && length > 1000) {
      return;
    }
    this.setState({
      [`${name}EditorState`]: editorState,
      [name]: editorString,
      [`${name}Length`]: length,
    })
  }

  getEditorContentLength = (data) => {
    let length = 0;
    if (Array.isArray(data.blocks)) {
      data.blocks.forEach(b => (b.text) ? length += b.text.length : null);
    }

    return length;
  }

  render() {
    const { classes, onClose, workId, canAddWork, canEditWork, canUpgradeWork, currentRol } = this.props;
    const steps = getSteps();
    const { activeStep, expanded, altLabel } = this.state;
    //const { dense, secondary } = this.state;
    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (<div>
            <section className={classes.bodyForm}>
              <Grid
                container
                spacing={16}>
                <Grid item md={6} sm={12} xs={12}>
                  {
                    /**AHDA 20/05/2019: Changed validation */
                    !currentRol ? null : currentRol === 12 ?
                      <TextValidator
                        label="Empresa/Proveedor*"
                        placeholder="Ej. Televisa"
                        id="companyProvider"
                        name="companyProvider"
                        value={this.state.companyProvider}
                        className={classNames(classes.field, classes.w100)}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={['*Campo requerido']}
                        InputLabelProps={{ shrink: true }}
                      />
                      :
                      <FormControl className={classNames(classes.formControl, classes.w100)}>
                        <SelectValidator
                          label="Empresa/Proveedor*"
                          placeholder="Ej. Televisa"
                          id="companyProviderCatalog"
                          name="companyProviderCatalog"
                          value={this.state.companyProviderCatalog}
                          className={classNames(classes.field, classes.w100)}
                          onChange={this.handleChange}
                          validators={['required']}
                          errorMessages={['*Campo requerido*']}
                          InputLabelProps={{ shrink: true }}
                        >
                          {
                            this.state.companysProviders.map((comapny, idx) => (
                              <MenuItem value={comapny.value} key={`country${idx}`}>
                                {comapny.data}
                              </MenuItem>))
                          }
                        </SelectValidator>
                      </FormControl>

                  }
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  {/*AHDA 27/05/2019: Changed valaidators for InputProps*/}
                  <TextValidator
                    label="Representante"
                    placeholder="Ej. Ricardo Torres"
                    id="representative"
                    name="representative"
                    value={this.state.representative}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    InputProps={
                      {
                        inputProps: { maxLength: 100 }
                      }
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {/*AHDA 27/05/2019: Changed valaidators for InputProps*/}
                  <TextValidator
                    label="Dirección"
                    placeholder="Ej. Paseo del Moral 1565"
                    id="address"
                    name="address"
                    value={this.state.address}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    InputProps={
                      {
                        inputProps: { maxLength: 200 }
                      }
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  {/*AHDA 27/05/2019: Changed validators, errorMessages, InputProps*/}
                  <TextValidator
                    label="Teléfono*"
                    placeholder="Ej. 47712545655"
                    id="telephone"
                    name="telephone"
                    value={this.state.telephone}
                    className={classNames(classes.field, classes.w100)}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleChange}
                    validators={['required', 'isNumber', 'isPhoneMin', 'isPhoneMax']}
                    errorMessages={[
                      '*Campo requerido',
                      'Solo se permiten números',
                      'El número debe tener mínimo 10 dígitos',

                    ]}
                    InputProps={
                      {
                        inputProps: { maxLength: 20 }
                      }
                    }
                  />
                  {this.state.telephone.length >= 20 && (
                    <p className={classes.textError}>
                      El número debe tener máximo 20 dígitos
                    </p>
                  )}
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  {/*AHDA 27/05/2019: Changed validators, errorMessages, InputProps*/}
                  <TextValidator
                    label="Correo Electrónico"
                    placeholder="Ej. ejemplo@ejemplo.com"
                    id="email"
                    name="email"
                    value={this.state.email}
                    className={classNames(classes.field, classes.w100)}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleChange}
                    // JCMY 19/06/2019 Insidencia AC-935
                    validators={['isEmail', 'matchRegexp:^([A-Za-z0-9-._@]+)$']}
                    errorMessages={['Correo electrónico inválido', 'Caracteres no validos']}//JCMY 05/06/2019
                    InputProps={
                      {
                        inputProps: { maxLength: 50 }
                      }
                    }
                  />
                </Grid>
              </Grid>
            </section>
          </div>);

        case 1:
          return (<div>
            <section className={classes.bodyForm}>
              <div>
                <ExpansionPanel defaultExpanded expanded={expanded === 'panel1'} onChange={this.handleChangeExpand('panel1')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.headingExpand}>Datos Generales</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      spacing={16}>
                      <Grid item md={8} sm={12} xs={12}>
                        {/*AHDA 27/05/2019: Changed validators, errorMessages, InputProps*/}
                        <TextValidator
                          label="Título de la obra*"
                          placeholder="Ej."
                          id="title"
                          name="title"
                          value={this.state.title}
                          className={classNames(classes.field, classes.w100)}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChange}
                          // JCMY 03/07/2019 incidencia 1021
                          validators={['required', 'trim']}
                          errorMessages={['*Campo requerido', 'Espacios en blanco invalidos']}
                          InputProps={
                            {
                              inputProps: { maxLength: 100 }
                            }
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="Tipo de obra*"
                            id="typeWork"
                            name="typeWork"
                            value={this.state.typeWork}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            <MenuItem value={'primigenia'}>Primigenia</MenuItem>
                            <MenuItem value={'adaptacion'}>Adaptación</MenuItem>
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="País de origen*"
                            id="country"
                            name="country"
                            value={this.state.country}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            {
                              this.state.countries.map((country, idx) => (
                                <MenuItem value={country.value} key={`country${idx}`}>
                                  {country.data}
                                </MenuItem>))
                            }
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="Género*"
                            id="gender"
                            name="gender"
                            value={this.state.gender}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            {
                              this.state.genders.map((gender, idx) => (
                                <MenuItem value={gender.value} key={`gender${idx}`}>
                                  {gender.data}
                                </MenuItem>))
                            }
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="Subgénero"
                            id="subgenre"
                            name="subgenre"
                            value={this.state.subgenre}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                          >
                            {
                              this.state.subgenres.map((subgenre, idx) => (
                                <MenuItem value={subgenre.value} key={`subgenre${idx}`}>
                                  {subgenre.data}
                                </MenuItem>))
                            }
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="Formato*"
                            id="format"
                            name="format"
                            value={this.state.format}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            {
                              this.state.formats.map((format, idx) => (
                                <MenuItem value={format.value} key={`format${idx}`}>
                                  {format.data}
                                </MenuItem>))
                            }
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="Tipo de proyecto*"
                            id="typeProyect"
                            name="typeProyect"
                            value={this.state.typeProyect}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            <MenuItem value="terminada">Terminada</MenuItem>
                            <MenuItem value="futura">Futura</MenuItem>
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="chapters"></InputLabel>
                        {/*AHDA 27/05/2019: changd validators, errorMessages, InputProps*/}
                        <TextValidator
                          label="Número de capítulos"
                          placeholder="Ej. 120"
                          id="chapters"
                          name="chapters"
                          value={this.state.chapters}
                          className={classNames(classes.field, classes.w100)}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChange}
                          // JCMY 01/04/2019 se ajust a solo numeros positivos y a solo 5 caracteres
                          validators={['isNumber', 'isPositive']}
                          errorMessages={['Solo se permiten números', 'Solo se permiten números positivos']}
                          InputProps={
                            {
                              inputProps: { maxLength: 5 }
                            }
                          }
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <InputLabel shrink htmlFor="duration">Duración</InputLabel>
                          <Select
                            id="duration"
                            name="duration"
                            value={this.state.duration}
                            onChange={this.handleChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }} >
                            {
                              this.state.durations.map((duration, idx) => (
                                <MenuItem value={duration.value} key={`duration${idx}`}>
                                  {duration.data}
                                </MenuItem>))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* CH 29/05/2019 Se cambia de lugar y se ajustan tamaños */}
                      <Grid item md={3} sm={6} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          {/* cramirez 26/8/2019 AC-1069 */}
                          <SelectValidator
                            label="Registro de Autor*"
                            id="proRegistroAutor"
                            name="author_register"
                            value={this.state.author_register}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            <MenuItem value={'registrada'}>Registrada</MenuItem>
                            <MenuItem value={'noregistrada'}>No registrada</MenuItem>
                            <MenuItem value={'proceso'}>En proceso de registro</MenuItem>

                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <SelectValidator
                            label="¿Cómo te enteraste del registro de obras?*"
                            id="source"
                            name="source"
                            value={this.state.source}
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                            displayEmpty
                            onChange={this.handleChange}
                            validators={['required']}
                            errorMessages={['(Campo requerido)']}
                          >
                            {
                              this.state.sources.map((source, idx) => (
                                <MenuItem value={source.value} key={`source${idx}`}>
                                  {source.data}
                                </MenuItem>))
                            }
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                          {/*AHDA 31/05/2019: changed newChipcodes*/}
                          <ChipInput
                            label="Etiquetas"
                            placeholder="Ej. amor, drama, cdmx"
                            className={classNames(classes.fieldChip, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.labels}
                            onAdd={(chip) => this.handleAddChip(chip)}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                            newChipKeyCodes={[13, 9]}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangeExpand('panel2')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Autor(es) / Adaptador(es)</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.root}>
                      {/*Author Table*/}
                      <Paper
                        className={classes.rootTable}>
                        {/*AHDA 24/05/2019: Added showSearch*/}
                        {/* GEO 04/06/2019 Se agrega ellipsis */}
                        <Header
                          title="Autor(es) / Adaptador(es)"
                          tooltip="Agregar Autor"
                          textBtn="Nuevo"
                          openForm={() => this.handledOpenFormAuthor()}
                          workId={0}
                          canAdd={canAddWork}
                          showSearch={false}
                        />
                        <TableCatalogue
                          headers={this.state.headers}
                          data={this.state.authorsData}
                          openForm={this.handledOpenFormAuthor}
                          deleteItem={this.handleDeleteAuthor}
                          workId={0}
                          canDelete={canEditWork}
                          canEdit={canEditWork}
                        />
                      </Paper>
                      {/*Add new Author*/}
                      <AddCatalogue
                        validate={1}
                        openForm={this.state.openFormAuthor}
                        closeForm={this.handledCloseForm}
                        classes={classes}
                        submit={this.handleSubmitAuthor}
                        clearForm={this.handleClear}
                        // JCMY 02/07/2019 incidencia 347
                        title="Autor / Adaptador">
                        <Grid
                          container
                          spacing={24}>

                          {/* GEO 04/06/2019 Longitud de input */}
                          <Grid item md={12} className={classes.w100}>
                            {/*AHDAa 28/05/2019: Validation for author when internal*/}
                            {
                              !currentRol ? null : currentRol === 12 ?
                                <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errorauthorsName}>
                                  <InputLabel shrink htmlFor="new_author_name">Nombre*</InputLabel>
                                  {/*AHDA 23/04/2019: changed to normal controls*/}
                                  <TextField
                                    error={this.state.errorauthorsName}
                                    label="Nombre*"
                                    placeholder="Ej. Paulina Duarte"
                                    id="authorsName"
                                    name="authorsName"
                                    value={this.state.authorsName}
                                    className={classNames(classes.field, classes.w100)}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  {this.state.errorauthorsName ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null}
                                </FormControl> :
                                <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errorauthorsName}>
                                  <InputLabel shrink htmlFor="new_author_name">Nombre*</InputLabel>
                                  <Select
                                    id="authorsName"
                                    name="authorsName"
                                    value={this.state.authorsName}
                                    className={classes.selectEmpty}
                                    InputLabelProps={{ shrink: true }}
                                    displayEmpty
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    disabled={(this.state.idObra !== 0 && this.state.rolId !== 20)}
                                  >
                                    {
                                      this.state.authors.map((author, idx) => (
                                        <MenuItem value={author.value} key={`material${idx}`}>
                                          {author.label}
                                        </MenuItem>))
                                    }
                                  </Select>
                                  {this.state.errorauthorsName ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null}
                                </FormControl>
                            }
                          </Grid>
                          {/* GEO 04/06/2019 Longitud de input */}
                          <Grid item md={12} className={classes.w100}>
                            <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errorparticipation}>
                              <InputLabel shrink htmlFor="new_author_name">Participación*</InputLabel>
                              <Select
                                label="Participación*"
                                id="participation"
                                name="participation"
                                value={this.state.participation}
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                displayEmpty
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                              >
                                <MenuItem value="Autor">Autor</MenuItem>
                                <MenuItem value="Adaptador">Adaptador</MenuItem>
                              </Select>

                              {this.state.errorparticipation ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AddCatalogue>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChangeExpand('panel3')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Logline</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                      <Grid item md={12} sm={12} xs={12}>
                        {/*AHDA 27/05/2019: Changed validators for InputProps*/}
                        {/* <TextValidator 
                          id="logline" 
                          name="logline" 
                          value={this.state.logline} 
                          className={classes.field}
                          multiline
                          rows="7"
                          placeholder="Escribe aquí"
                          onChange={this.handleChange}
                          InputProps={{inputProps: { maxLength: 1000 }}}
                        /> */}
                        <Editor
                          editorState={this.state.loglineEditorState}
                          // disabled={!this.state.chkEditParameter}
                          editorClassName="TextEditor"
                          toolbarClassName="ToolbarEditor"
                          onEditorStateChange={(state) => this.onEditorStateChange('logline', state)}
                        // handlePastedText={this.handlePastedText}

                        />
                        <div className={classes.noteSpace}>
                          <Typography><small>Máximo 1000 caracteres</small></Typography>
                          <Typography><small><strong>{this.state.loglineLength}</strong> de 1000</small></Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel4'} onChange={this.handleChangeExpand('panel4')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Otros Materiales</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.root}>
                      <Paper
                        className={classes.rootTable}>
                        {/*AHDA 24/05/2019: Added showSearch*/}
                        <Header
                          title="Otros Materiales"
                          tooltip="Agregar Material"
                          textBtn="Nuevo"
                          openForm={() => this.handledOpenFormMaterial(undefined)}
                          workId={0}
                          canAdd={canAddWork}
                          showSearch={false}
                        />
                        <TableCatalogue
                          headers={this.state.headersMaterial}
                          data={this.state.dataMaterialTable}
                          openForm={this.handledOpenFormMaterial}
                          deleteItem={this.handleDeleteMaterial}
                          workId={workId}
                          canDelete={canEditWork}
                          canEdit={false}
                          showVisibility={true}
                          handleViewItem={this.handleViewItem}
                        />
                      </Paper>

                      <AddCatalogue
                        validate={1}
                        openForm={this.state.openFormMaterial}
                        closeForm={this.handledCloseFormMaterial}
                        classes={classes}
                        submit={this.handleSubmitMaterial}
                        clearForm={this.handleCleanMaterial}
                        // JCMY 02/07/2019 incidencia 348
                        title="Material">

                        <Grid
                          container
                          spacing={24}>
                          <Grid item xs={12}>
                            <FormControl className={classNames(classes.formControl, classes.w100)}>
                              {/* <InputLabel shrink htmlFor="proNameMaterial">Número de Capitulo</InputLabel> */}
                              <TextField
                                id="titleMaterial"
                                name="titleMaterial"
                                value={this.state.titleMaterial}
                                label={'Título'}
                                className={classNames(classes.w100)}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                type="text"
                                autoComplete="off"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errortypeMaterial}>
                              <SelectValidator
                                label="Tipo de Material*"
                                id="typeMaterial"
                                name="typeMaterial"
                                value={this.state.typeMaterial}
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                displayEmpty
                                onChange={this.handleMaterialChange}
                                validators={['required']}
                                errorMessages={['(Campo requerido)']}
                                onBlur={this.handleBlur}
                              >
                                {
                                  this.state.materials.map((material, idx) => (
                                    <MenuItem value={material.value} key={`material${idx}`}>
                                      {material.data}
                                    </MenuItem>))
                                }
                              </SelectValidator>
                              {this.state.errortypeMaterial ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errorlanguage}>
                              <SelectValidator
                                label="Idioma*"
                                id="language"
                                name="language"
                                value={this.state.language}
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                displayEmpty
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['(Campo requerido)']}
                                onBlur={this.handleBlur}
                              >
                                {
                                  this.state.languages.map((language, idx) => (
                                    <MenuItem value={language.value} key={`language${idx}`}>
                                      {language.data}
                                    </MenuItem>))
                                }
                              </SelectValidator>
                              {this.state.errorlanguage ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null}
                            </FormControl>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl className={classNames(classes.formControl, classes.w100)}>
                              {/* <InputLabel shrink htmlFor="proChapterMaterial">Número de Capitulo</InputLabel> */}
                              {/* <TextField
                                id="chapterNumberMaterial"
                                name="chapterNumberMaterial"
                                value={this.state.chapterNumberMaterial}
                                label={'Número de Capitulo'}
                                className={classNames(classes.w100)}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                type="text"
                                autoComplete="off"
                              /> */}
                              <TextValidator
                                id="chapterNumberMaterial"
                                name="chapterNumberMaterial"
                                label={'Número de Capitulo'}
                                value={this.state.chapterNumberMaterial}
                                className={classes.w100}
                                onChange={this.handleChange}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputProps: { maxLength: 250 } }}
                                validators={['isChapterNumber']}
                                errorMessages={['No se permiten capturar letras: Ejemplo (1,2.5)']}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <MaterialDropZone
                              onDrop={this.onDrop}
                              onRemove={this.handleRemove}
                              files={this.state.files}
                              showPreviews
                              // isanchez 03.08.2019 increase file size limit
                              maxSize={54760833024}
                              filesLimit={1}
                              text="Arrastra los archivo o da click aquí"
                            />
                          </Grid>
                        </Grid>
                      </AddCatalogue>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel6'} onChange={this.handleChangeExpand('panel6')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Comentarios</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                      <Grid item md={12} sm={12} xs={12}>
                        {/*AHDA 27/05/2019: Changed validators for inputprops*/}
                        <TextValidator
                          id="comments"
                          name="comments"
                          value={this.state.comments}
                          className={classes.field}
                          multiline
                          rows="7"
                          placeholder="Escribe aquí"
                          onChange={this.handleChange}
                          InputProps={
                            {
                              inputProps: { maxLength: 10000 }
                            }
                          }
                        />
                        <div className={classes.noteSpace}>
                          <Typography><small>Máximo 10,000 caracteres</small></Typography>
                          <Typography><small><strong>{this.state.comments.length}</strong> de 10,000</small></Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>

              {/*AHDA 17/05/2019: Added checkbox and commented previous*/}
              <div className={classes.contentLegal}>
                <Typography className={classes.headingExpand}>Autorización Derechos Autor</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="policies"
                      checked={this.state.checked}
                      onChange={this.handleChangeChecked('checked')}
                      color="primary"
                    />
                  }
                  label="Al registar la obra, usted acepta la siguiente:"
                />

                <Button onClick={() => this.generateDocx(true)} size="small" color="secondary" className={classes.button}>
                  CARTA DE AUTORIZACIÓN
                </Button>
              </div>

              {/*<ExpansionPanel expanded={true}>
                <ExpansionPanelSummary >
                  <Typography className={classes.headingExpand}>Autorización Derechos Autor</Typography>
                </ExpansionPanelSummary>

                <div className={{ paddingLeft: '23 px' }}>
                  Al registar la obra, usted acepta la siguiente
                  <Button onClick={() => this.generateDocx(true) }  size="small" color="secondary" className={classes.button}>
                  CARTA DE AUTORIZACIÓN
                </Button>
                </div>
                </ExpansionPanel>*/}

              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.openModalDocAcpt}
                onClose={this.handleClose}
              >
                <div style={getModalStyle()} className={classes.paper}>
                  <Button className={classNames(classes.button, classes.closeModal)} variant="fab" mini aria-label="Delete" color="primary" onClick={this.handleClose}>
                    <Close />
                  </Button >
                  <iframe width="100%" height="100%" src={this.state.urlDocAcept}></iframe>
                </div>
              </Modal>

            </section>
          </div>);
        case 2:
          return (<div>
            <section className={classes.bodyForm}>
              <div>
                <ExpansionPanel expanded={expanded === 'panel21'} onChange={this.handleChangeExpand('panel21')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Sinopsis corta</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                      <Grid item md={12} sm={12} xs={12}>
                        {/*AHDA 27/05/2019: Changed validators for inputprops*/}
                        {/* <TextValidator
                          id="shortSynopsis"
                          name="shortSynopsis"
                          value={this.state.shortSynopsis}
                          className={classes.field}
                          multiline
                          rows="7"
                          placeholder="Escribe una sinopsis corta"
                          onChange={this.handleChange}
                          InputProps={
                            {
                              inputProps: { maxLength: 15000 }
                            }
                          }
                        /> */}
                        <Editor
                          editorState={this.state.shortSynopsisEditorState}
                          editorClassName="TextEditor"
                          toolbarClassName="ToolbarEditor"
                          onEditorStateChange={(state) => this.onEditorStateChange('shortSynopsis', state)}
                          // handlePastedText={this.handlePastedText}
                          readOnly={!this.props.canEditWorkSynopsisData}
                        />
                        <div className={classes.noteSpace}>
                          <Typography><small>Máximo 15,000 caracteres</small></Typography>
                          <Typography><small><strong>{this.state.shortSynopsisLength}</strong> de 15,000</small></Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel22'} onChange={this.handleChangeExpand('panel22')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography className={classes.headingExpand}>Sinopsis Larga</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Editor
                          editorState={this.state.longSynopsisEditorState}
                          editorClassName="TextEditor"
                          toolbarClassName="ToolbarEditor"
                          onEditorStateChange={(state) => this.onEditorStateChange('longSynopsis', state)}
                          readOnly={!this.props.canEditWorkSynopsisData}
                        // handlePastedText={this.handlePastedText}
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </section>
          </div>);
        default:
          return 'Unknown step';
      }
    }

    //AHDA 28/05/2019: Method for getting button
    const getButton = () => {
      //TODO: Add reactivation validation
      let returnButton = !this.state.statusWorkFlow ? true : false;
      returnButton = returnButton ? true : this.state.statusWorkFlow === "Más material" ? true : false;

      return (
        <React.Fragment>
          {
            returnButton &&
            <Button
              disabled={!canEditWork}
              variant="contained"
              color="primary"
              type="submit"> {this.completedSteps() === this.totalSteps() - 1 ? 'Finalizar' : 'Actualizar Paso'}
            </Button>
          }
        </React.Fragment>
      )
    }
    return (
      <div className={classes.detailContainer}>
        <PapperBlock title="Registro de Obra Literaria " desc="" className={classes.centerProgress}>
          {this.state.loading && <LoaderFull process={this.state.uploadProgress} />}
          <Grid container className={classes.root} spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <Stepper nonLinear activeStep={activeStep} alternativeLabel={altLabel}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      className={classes.stepItem}
                      // onClick={this.handleStep(index)}
                      completed={this.state.completed[index]}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              {/* <Divider /> */}
              <div>
                {this.allStepsCompleted() ? (
                  <div>
                    {this.state.update == 0 ? (

                      <div className={classes.finishText}>
                        <Check color="primary" />
                        <p>{`Se ha cargado tu obra ${!!this.state.title ? `"${this.state.title}"` : ""} con éxito`}</p>
                        <small>Recibirás un correo de confirmación durante las próximas 24 horas.</small>
                      </div>
                    ) : (

                      <div className={classes.finishText}>
                        <Check color="primary" />
                        {/* JCMY 01/04/19 */}
                        <p>{`Se ha actualizado tu obra "${!!this.state.title && this.state.title}" con éxito`}</p>
                      </div>

                    )}

                    <div className={classes.rightAction}>
                      <Button onClick={this.handleReset}>Registrar Nueva Obra</Button>
                      <Button onClick={onClose}>Cerrar</Button>
                    </div>
                  </div>
                ) : (
                  <ValidatorForm ref={(r) => { this.form = r }} onSubmit={this.handleComplete} >
                    {/* // <ValidatorForm ref={(r) => { this.form = r }} onSubmit={()=>this.preventDefault()} > */}

                    <div>
                      <div className={classes.contentStep}>{getStepContent(activeStep)}</div>
                      <div>
                        <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button} > Atrás </Button>

                        {/*AHDA 28/05/2019: Changed validations*/}
                        {
                          this.props.workId !== 0 &&
                          <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button} >Siguiente</Button>
                        }

                        {
                          this.props.workId === 0 ?
                            <Button
                              disabled={!canEditWork}
                              variant="contained"
                              color="primary"
                              onClick={() => this.Send(false)}
                              type="submit"> {this.completedSteps() === this.totalSteps() - 1 ? 'Finalizar' : 'Completar Paso'}
                            </Button> : getButton()
                        }
                      </div>
                    </div>
                  </ValidatorForm>
                )}
              </div>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={this.state.openAlert}
            autoHideDuration={6000}
            onClose={this.handleCloseAlert}>
            <CustomNotification
              onClose={this.handleCloseAlert}
              variant={this.state.variant}
              message={this.state.message} />
          </Snackbar>
          {
            this.state.modalFiles && this.state.modalFiles.length > 0 ?
              <MediaModal
                openModal={this.state.openMediaModal}
                handleCloseModal={this.handleCloseMediaModla}
                media={this.state.modalFiles} /> : null
          }
        </PapperBlock>

      </div>
    );
  }
}


NuevaObra.propTypes = {
  classes: PropTypes.object.isRequired,
  openStep: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  workId: PropTypes.number,

  canAddWork: PropTypes.bool,
  canEditWork: PropTypes.bool,
  canUpgradeWork: PropTypes.bool,
  currentRol: PropTypes.number.isRequired //AHDA 20/05/2019
};

//AHDA 20/05/2019
//Added defaultprops 
NuevaObra.defaultProps = {
  currentRol: 12
}

export default withStyles(styles)(NuevaObra);