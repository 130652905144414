import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './user-jss';
import PapperBlock from './../PapperBlock/PapperBlock';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';
import Loader from '../Loader/Loader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

//Language
import { LanguageConsumer } from '../../language/LanguageContext';

//services
import { SignUp } from './../../services/cognito_service';
import { apiPOSTNotAuth } from './../../services/service_base'; 

//Modal
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

//AHDA 13/05/2019
const defaultPrivacy = `<p className={classes.titleContenido}>RESPONSABLE DE LOS DATOS PERSONALES.</p>
<p className={classes.textContenido}>
  “Televisa, S.A. de C.V.” (en adelante “Televisa”) con domicilio en Av. Vasco de Quiroga 2000, Colonia Santa Fe, Delegación Álvaro Obregón, C.P. 01210, México, D.F., tiene la convicción de proteger la información personal proporcionada por sus usuarios (en adelante “Datos Personales”) y es el responsable de su Tratamiento (término que se define más adelante) cuando sean recabados a través del sitio de Internet www.televisa.com (en adelante el “Sitio”), medios impresos y/o vía telefónica (en adelante “Otros Medios”).
</p>
<p className={classes.titleContenido}>FINALIDAD DEL TRATAMIENTO.</p>
<p className={classes.textContenido}>
  Televisa podrá solicitar y/o recabar a través del Sitio y Otros Medios, Datos Personales de los usuarios para los fines abajo señalados; así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien, cuando sea solicitado por autoridades competentes (en adelante “Tratamiento”). Televisa y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales, guardará confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).
</p>
<p className={classes.textContenido}>
  Los Datos Personales que los usuarios proporcionen al momento de su ingreso y/o registro al Sitio tienen como finalidad (i) hacer posible que diversos anunciantes promuevan sus productos y/o servicios; (ii) así como generar bases de datos que seutilizarán con fines comerciales, de publicidad y promoción.
</p>
<p className={classes.titleContenido}>DATOS PERSONALES A RECABAR.</p>
<p className={classes.textContenido}>
  Los Datos Personales que se solicitan del usuario son:
</p>
<p className={classes.textContenido}>
  El usuario no podrá acceder a los servicios para los que se requieren sus Datos Personales cuando estos no sean proporcionados, sin embargo sí podrá tener acceso a los demás Servicios que no los requieran.
</p>
<p className={classes.titleContenido}>
  MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
</p>
<p className={classes.textContenido}>
  Televisa cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por los usuarios. No obstante lo señalado en el apartado de Consentimiento, Televisa tomará las acciones, medidas y previsiones especiales que considere necesarias, a fin de salvaguardar el derecho a la protección de Datos Personales de menores de edad e incapaces.
</p>
<p className={classes.textContenido}>
  Si usted desea dejar de recibir mensajes promocionales vía correo electrónico y/o de forma impresa y/o vía telefónica por parte de Televisa puede solicitarlo a través de: <a href="mailto:dudaslegal@televisatim.com">dudaslegal@televisatim.com</a>
</p>
<p className={classes.titleContenido}>
  EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.
</p>
<p className={classes.textContenido}>
  El Sitio podría contener hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en la “World Wide Web” que al ser utilizados por los usuarios transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que los usuarios llegaren a proporcionar a través de estos portales o sitios de Internet no se encuentran contemplados por este Aviso de Privacidad y su Tratamiento no es responsabilidad de Televisa. Recomendamos a los usuarios verificar los Avisos de Privacidad desplegados y aplicables a estos portales y sitios de Internet.
</p>
<p className={classes.textContenido}>
  Los usuarios podrán encontrar dentro del Sitio, páginas, promociones, micrositios, tiendas virtuales, encuestas, bolsas de trabajo y otros servicios que pueden estar compartidos con terceros y que podrían solicitar sus Datos Personales (en adelante “Sitios de Terceros”). Cualquier información relacionada con los Datos Personales que se proporcionen a través de los Sitios de Terceros se sujetará a los respectivos avisos de privacidad que se contengan en cada uno de ellos, salvo que Televisa señale lo contrario.
</p>
<p className={classes.textContenido}>
  Televisa advierte a sus usuarios que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, foros de discusión, páginas de Internet personales, avisos clasificados, correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas. Televisa no será responsable por el uso, publicación, revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos a los usuarios ser cuidadosos y responsables sobre toda información de carácter personal que proporcionen en dichos medios.
</p>
<p className={classes.titleContenido}>TRANSFERENCIA DE DATOS PERSONALES.</p>
<p className={classes.textContenido}>Televisa podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los usuarios, a sus empresas filiales, subsidiarias y/o relacionadas, así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.</p>
<p className={classes.textContenido}>De igual forma podrá revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales a proveedores, patrocinadores, publicistas, contratistas y/o socios para fines comerciales, como proporcionar datos estadísticos a anunciantes potenciales, enviar publicidad a los usuarios de acuerdo a sus intereses específicos y conducir investigaciones de mercado. Los terceros que reciban la información relacionada con los Datos Personales de los usuarios están obligados a cumplir con lo previsto en este Aviso de Privacidad, así como con los Términos y Condiciones del Sitio y de la legislación aplicable en México.</p>
<p className={classes.textContenido}>El titular de los Datos Personales podrá otorgar su consentimiento a la transferencia de sus Datos Personales en los términos descritos en el presente Aviso al momento de registrarse al Sitio.</p>
<p className={classes.titleContenido}>MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO.</p>
<p className={classes.textContenido}>Los usuarios podrán solicitar el ejercicio de sus derechos a:</p>
<p className={classes.textContenido}>
  <ol>
    <li>Accesar a sus Datos Personales;</li>
    <li>Rectificar sus Datos Personales cuando sean inexactos o incompletos;</li>
    <li>Cancelar sus Datos Personales;</li>
    <li>Oponerse por causa legítima al Tratamiento de sus Datos Personales, y/o</li>
    <li>Revocar su consentimiento para el Tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje de hacer uso de los mismos.</li>
  </ol>
</p>
<p className={classes.textContenido}>Sin embargo es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al Tratamiento o revocar su consentimiento, ya que es posible que por alguna obligación legal requiramos seguir tratando sus Datos Personales. El usuario deberá considerar que para ciertos fines, la revocación de su consentimiento implicará la suspensión del servicio o la conclusión de su relación con Televisa.</p>
<p className={classes.textContenido}>El ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación del consentimiento que realicen los usuarios deberá realizarse previa solicitud a Televisa y contener al menos lo siguiente:</p>
<p className={classes.textContenido}>
  <ol>
    <li>El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;</li>
    <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
    <li>La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición);</li>
    <li>Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</li>
  </ol>
</p>
<p className={classes.textContenido}>Para efecto de lo anterior Televisa pone a disposición de los usuarios el siguiente correo electrónico: <a href="mailto:dudaslegal@televisatim.com">dudaslegal@televisatim.com</a></p>
<p className={classes.textContenido}>Televisa dará respuesta a la solicitud de los usuarios en un plazo no mayor a 20 días hábiles contados a partir de la fecha en que reciba la petición correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto.</p>
<p className={classes.textContenido}>El ejercicio por parte de los usuarios de los derechos antes mencionados deberá sujetarse a las leyes y reglamentos aplicables vigentes en México.</p>
<p className={classes.titleContenido}>COOKIES Y WEB BEACONS.</p>
<p className={classes.textContenido}>Con el objetivo de mejorar la experiencia de sus usuarios en el Sitio, Televisa podrá utilizar “cookies”. Para efectos del presente Aviso de Privacidad “cookies” se identificará como los archivos de texto de información que un sitio web transfiere al disco duro de la computadora de los usuarios con el objeto de almacenar ciertos registros y preferencias. Televisa puede utilizar “cookies” para mejor entendimiento de la interacción de los usuarios con el Sitio y los servicios que se proporcionan. El Sitio puede permitir publicidad o funciones de terceros que envíen “cookies” a las computadoras de los usuarios. Mediante el uso de “cookies” no se identifica personalmente a los usuarios, únicamente a sus computadoras.</p>
<p className={classes.textContenido}>Por su parte las “web beacons” son imágenes insertadas en una página de Internet o correo electrónico que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, horario de navegación, duración del tiempo de interacción en el Sitio, secciones consultadas, páginas de Internet accedidas previo a la nuestra y el tipo de navegador utilizado.</p>
<p className={classes.textContenido}>Los usuarios podrán cambiar sus opciones a través de sus equipos de cómputo y/o navegadores para dejar de aceptar “cookies” y/o “web beacons” o bien confirmar si aceptan o no las mismas.</p>
<p className={classes.titleContenido}>DATOS PERSONALES SENSIBLES.</p>
<p className={classes.textContenido}>De conformidad con la legislación mexicana vigente, se conocen como “Datos Personales Sensibles” a “aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas, preferencia sexual”.</p>
<p className={classes.textContenido}>En caso de que Televisa llegue a requerir Datos Personales Sensibles de los usuarios, estos deberán proporcionar su consentimiento expreso.</p>
<p className={classes.titleContenido}>CAMBIOS AL AVISO DE PRIVACIDAD.</p>
<p className={classes.textContenido}>Televisa se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado a sus usuarios a través del Sitio. Una vez que se publique el Aviso de Privacidad en el Sitio entrará en vigor automáticamente.</p>
<p className={classes.textContenido}>La fecha de última actualización del presente aviso es el 1 de septiembre de 2013.</p>
`

class RegisterForm extends React.Component {
  
  //AHDA 13/05/2019
  //Added Modal 
  state = {
    tab: 0,
    showPassword: false,
    showConfirmPassword: false,
    //User data
    username: '',
    lastname: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    //Errors
    errEmail: false,
    errMatch: false,
    errMatchEmail: false,
    errPattern: false,
    errUser: false,
    errMessage: 'errMsg',
    errusername: false, 
    errlastname: false, 
    //Notifications
    open: false,
    //Loading: 
    step: 'register', 
    //Modal
    openModal: false, 
    privacyAnnouncement: "", 
    checkPrivacy: false
  };

  //Inputs
  handleClickShowPassword = (pwd) => {
    switch (pwd) {
      case 'current':
        this.setState({ showPassword: !this.state.showPassword });
        break;
      case 'confirm':
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
        break;
      default:
        break;
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleBlur = (event) => {
    switch(event.target.name){
      case 'username':
      case 'lastname': 
      //AHDA 01/04/2019
      //Validation for whitespaces in Name and Last Name 
      if(/^\s+$/i.test(event.target.value) || event.target.value.length <= 0)
        this.setState({[`err${event.target.name}`]: true}); 
      else
        this.setState({[`err${event.target.name}`]: false});
      break; 
      case 'email' : 
      this.setState({errEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)})
      break; 
      case 'confirmEmail' : 
      this.setState({errMatchEmail: this.state.email === event.target.value ? false : true}); 
      break;
      case 'confirmPassword' : 
      this.setState({errMatch: this.state.password === event.target.value ? false : true}); 
      break; 
      case 'password':
        //this.setState({ errPattern: !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.;,:-_{}[/#&'¡¿()+><|])[0-9A-Za-zñÑ@$!%*?.;,:-_{}[/#&'¡¿()+><|]{12,}$/i.test(event.target.value) })
        break;
      default:
        break;
    }
  }

  /*handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };*/

  //AHDA 13/05/2019
  //Changed method to recieve modal 
  //Notifications
  handleClose = (modal) => {
    this.setState({ [modal]: false });
  }

  //Submit
  handleSubmit = (event) => {
    event.preventDefault();

    //USER TO COGNITO
    const user = {
      username: this.state.email,
      password: this.state.password,
      attributes: {
        email: this.state.email,
        name: this.state.username,
        profile: "", //AHDA 14/05/2019
        family_name: this.state.lastname,
        "custom:pwdLastUpdateTime": new Date().getTime().toString()
      }
    }

    //AHDA 22/04/2019
    //Validation for empty user or lastname 
    if(this.state.username.length <= 0 || this.state.lastname.length <= 0){
      this.setState({
        errusername: this.state.username.length <= 0 ? true : false, 
        errlastname: this.state.lastname.length <= 0 ? true : false
      }); 

      return false; 
    }
    
    //USER TO RDS

    const userDB = {
      usuario: this.state.email,
      idEmpresa: null,
      //Verificar que ese sea el id de external 
      idRol: 12,
      verificado: 0,
      name: this.state.username,
      lastName: this.state.lastname
    }

    //AHDA 01/04/2019
    //Validation for withespaces in Name and Last Name
    if(!this.state.errEmail && !this.state.errMatchEmail && !this.state.errPattern 
      && !this.state.errMatch && !this.state.errusername && !this.state.errlastname){
      this.setState({step:'loading'})
      SignUp(user, userDB).then(
        response => {
          //TODO: manage response
          this.setState({ step: 'message' })
        }
      ).catch(
        err => {
          if (err.status === 400) {
            this.setState({ open: true, errMessage: 'existingUser', step: 'register' })
          }
          else {
            this.setState({ open: true, errMessage: 'errMsg', step: 'register' })
          }
        }
      )
    }
  }

  //Cancel submit
  handleClick = () => {
    window.location.href = "/"
  }

  //AHDA 13/05/2019
  //Changed method to recieve modal 
  // Modal
  handleOpen = (modal) => {
    this.setState({ [modal]: true });
  };

  // Checbox
  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };


  //Lifecycle 
  componentDidMount(){

    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
      if (/^\s+$/i.test(value)) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isSameEmail', (value) => {
      if (value === this.state.email) {
          return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('isValidPassword', (value) => {
      if (/^(?=.*[\d])(?=.*[ÑA-Z])(?=.*[ña-z])(?=.*[@$!%*?.;,:-_{}[/#&'¡¿()+><|])[\wñÑ@$!%*?.;,:-_{}[/#&'¡¿()+><|]{12,}$/i.test(value)) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('isSamePassword', (value) => {
      if (value === this.state.password) {
          return true;
      }
      return false;
    });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOSTNotAuth("/params/get", {portal: 2}, true).then(response => {
      if(response.status === 200){
        const auxPrivacy = response.data.find(p => p.id_parameter === 3); 
        
        if(auxPrivacy){
          const rawContent = convertFromRaw(JSON.parse(auxPrivacy.value)); 
          const content = EditorState.createWithContent(rawContent);

          const finalPrivacy = draftToHtml(convertToRaw(content.getCurrentContent())); 

          this.setState({
            privacyAnnouncement: finalPrivacy
          }); 
          
        }else{
          this.setState({ privacyAnnouncement: defaultPrivacy });
        }
      }else{
        this.setState({ privacyAnnouncement: defaultPrivacy });
      }
    }).catch(err =>{
      console.log("Error in parameters: ", err);
      this.setState({  privacyAnnouncement: defaultPrivacy });
    }); 
  }

  render() {
    const {
      classes
    } = this.props;
    //const { tab } = this.state;
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.formWrap} >
              <PapperBlock whiteBg title={lang.login.register} desc="" >
                {{
                  'register':
                    <ValidatorForm onSubmit={this.handleSubmit} className={classes.sheetForm}>
                      {/* GEO 01/04/2019
                      Se ajustan inputs al mismo estilo que todos los que tiene el sitio y se agrega tooltip a botón de mostrar/ocultar contraseña. */}
                      <div>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          spacing={16}
                        >
                          {/* CH 08/05/2019
                          Se cambia tamaño de input */}
                          <Grid item xs={6}>
                            {
                              /*
                              AHDA 01/04/2019 
                              Added: error={this.state.errusername}, onBlur={this.handleBlur} and FormHelperText
                              For all whitespaces validation
                              */
                            }
                            
                            <TextValidator
                              label={lang.common.name}
                              placeholder="Ej. Carolina"
                              id="username"
                              name="username"
                              value={this.state.username}
                              className={classNames(classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              InputProps={
                                {
                                  inputProps: { maxLength: 50 }
                                }
                              }
                              validators={['required', 'isSpaceEmpty']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio']}
                            />
                          </Grid>

                          {/* CH 08/05/2019
                          Se cambia tamaño de input */}
                          <Grid item xs={6}>
                            {
                              /*
                              AHDA 01/04/2019 
                              Added: error={this.state.errusername}, onBlur={this.handleBlur} and FormHelperText
                              For all whitespaces validation
                              */
                            }

                            <TextValidator
                              label={lang.common.lastName}
                              placeholder="Ej. Torres Castro"
                              id="lastname"
                              name="lastname"
                              value={this.state.lastname}
                              className={classNames(classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              InputProps={
                                {
                                  inputProps: { maxLength: 100 }
                                }
                              }
                              validators={['required', 'isSpaceEmpty']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio']}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextValidator
                              label={lang.common.email}
                              placeholder="Ej. correo@ejemplo.com"
                              id="email"
                              name="email"
                              value={this.state.email}
                              className={classNames(classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              
                              onBlur={this.handleBlur}
                              
                              InputLabelProps={{ shrink: true }}
                              validators={['required', 'isSpaceEmpty', 'isEmail']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio', lang.common.emailValidation]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            
                            <TextValidator
                              label={lang.common.confirmEmail}
                              placeholder="Ej. correo@ejemplo.com"
                              id="confirmEmail"
                              name="confirmEmail"
                              value={this.state.confirmEmail}
                              className={classNames(classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              validators={['required', 'isSpaceEmpty', 'isSameEmail']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio', lang.login.emailNoMatch]}

                              onBlur={this.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextValidator
                              label={lang.common.password}
                              id="password"
                              name="password"
                              type={this.state.showPassword ? 'text' : 'password'}
                              value={this.state.password}
                              className={classNames(classes.margin, classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              validators={['required', 'isSpaceEmpty', 'isValidPassword']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio', lang.login.pwdPolicy]}
                              InputLabelProps={{ shrink: true }}
                              InputProps={
                                {
                                  endAdornment: (<InputAdornment position="end">
                                  <Tooltip title="Ver/Ocultar Contraseña">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handleClickShowPassword('current')}
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>)
                                }
                              }
                              
                              onBlur={this.handleBlur}
                            />

                            {/*<FormControl className={classNames(classes.margin, classes.textField, classes.w100)} error={this.state.errPattern}>
                              <InputLabel shrink htmlFor="password">{lang.common.password}</InputLabel>
                              <Input
                                id="password"
                                name="password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                required
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Tooltip title="Ver/Ocultar Contraseña">
                                      <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={() => this.handleClickShowPassword('current')}
                                      >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                }
                              />
                              {this.state.errPattern ? <FormHelperText id="weight-helper-text" error>{lang.login.pwdPolicy}</FormHelperText> : null}
                              </FormControl>*/}
                          </Grid>
                          <Grid item xs={12}>

                            <TextValidator
                              label={lang.login.confirmPassword}
                              id="confirmPassword"
                              name="confirmPassword"
                              type={this.state.showConfirmPassword ? 'text' : 'password'}
                              value={this.state.confirmPassword}
                              className={classNames(classes.margin, classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              validators={['required', 'isSpaceEmpty', 'isSamePassword']}
                              errorMessages={['*Campo obligatorio', '*Campo obligatorio', lang.login.passNoMatch]}
                              InputLabelProps={{ shrink: true }}

                              InputProps={
                                {
                                  endAdornment: (<InputAdornment position="end">
                                  <Tooltip title="Ver/Ocultar Contraseña">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handleClickShowPassword('confirm')}
                                    >
                                      {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>)
                                }
                              }
                              
                              onBlur={this.handleBlur}
                            />

                            {/*<FormControl className={classNames(classes.margin, classes.textField, classes.w100)} error={this.state.errMatch}>
                              <InputLabel shrink htmlFor="confirmPassword">{lang.login.confirmPassword}</InputLabel>
                              <Input
                                id="confirmPassword"
                                name="confirmPassword"
                                required
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Tooltip title="Ver/Ocultar Contraseña">
                                      <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={() => this.handleClickShowPassword('confirm')}
                                      >
                                        {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                }
                              />
                              {this.state.errMatch ? <FormHelperText id="weight-helper-text">{lang.login.passNoMatch}</FormHelperText> : null}
                              </FormControl>*/}
                          </Grid>
                        </Grid>
                      </div>

                      {/* CH 08/05/2019
                      Se agregan Modal con términos y condiciones */}

                      
                      <div className={classes.contentLegal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="policies"
                              checked={this.state.checkPrivacy}
                              onChange={this.handleChangeChecked('checkPrivacy')}
                              color="primary"
                            />
                          }
                          label="Acepto todos los:"
                        />
                        {/*AHDA 13/05/2019: Changed handleOpen, handleClose*/}
                        <Button onClick={() => this.handleOpen("openModal")} size="small" color="secondary" className={classes.button}>Términos y condiciones</Button>
                        <Modal
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                          open={this.state.openModal}
                          onClose={() => this.handleClose("openModal")}
                        >
                          <div style={getModalStyle()} className={classes.paper}>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <div className={classNames(classes.bgTitle, classes.flexHead)}>
                                  <Typography variant="h6" className={classes.colorTitle} id="modal-title">
                                    Aviso de privacidad
                                  </Typography>
                                  <IconButton color="inherit" onClick={() => this.handleClose("openModal")} aria-label="Close">
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                                  <div className={classes.bgContenido} dangerouslySetInnerHTML={{__html: this.state.privacyAnnouncement}}>
                                    {/*AHDA 13/05/2019: Deleted privacy announcement and added markdown*/}
                                  </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Modal>
                      </div>

                      <div className={classNames(classes.btnArea, classes.noMargin, classes.marginB)}>
                        <div>
                          <Button variant="contained" color="primary" type="button" size="small" onClick={this.handleClick}>
                            {lang.common.cancel}
                          </Button>
                          <Button disabled={!this.state.checkPrivacy} variant="contained" color="primary" type="submit" size="small">
                            {lang.common.continue}
                            <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                          </Button>
                        </div>
                      </div>  
                    </ValidatorForm>,
                  'loading':
                    <Loader text="" />,
                  'message':
                    <React.Fragment>
                      {/* CH 05/04/2019
                      Se corrige alineación de mensaje y botón */}
                      <div className={classes.center}>
                        <IconButton size="large" className={classes.button} color="primary" >
                          <Check />
                        </IconButton>
                        <Typography variant="subheading" gutterBottom >
                          {lang.login.confirmedRegister}
                        </Typography>
                        <Button
                          size="small"
                          color="secondary"
                          className={classNames(classes.rightIcon, classes.button)} onClick={this.handleClick}>
                          {lang.login.login}
                        </Button>
                      </div>
                    </React.Fragment>
                }[this.state.step]}
                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={() => this.handleClose("open")}>
                  <CustomNotification
                    onClose={() => this.handleClose("open")}
                    variant="error"
                    message={lang.common[this.state.errMessage]} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

RegisterForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RegisterForm);
