import withTracker from './withTracker';
import withSession from './withSession'; //AHDA 17/05/2019

import App from '../components/App/App';
import Dashboard from '../components/App/Dashboard';
import Outer from '../components/App/Outer';
//AHDA 05/05/2019
import OuterEmpty from '../components/App/OuterEmpty';

// Pages
import Login from './Pages/Users/Login';
import Register from './Pages/Users/Register';
import BlankPage from './Pages/BlankPage';
import ResetPassword from './Pages/Users/ResetPassword';
import LoginDedicated from './Pages/Standalone/LoginDedicated';
import ConfirmUser from './Pages/Users/ConfirmUser';

// Other
import NotFound from './NotFound/NotFound';
import NotFoundDedicated from './Pages/Standalone/NotFoundDedicated';
import Error from './Pages/Error';
import Maintenance from './Pages/Maintenance';
import Construction from './Pages/Construction';
//import Builtin from './Pages/Builtin';


// Reportes
import ReporteObras from './Pages/Reportes/ReporteObras';
import AreaEvaluadora from './Pages/Reportes/AreaEvaluadora';
import EstatusDocumentacion from './Pages/Reportes/EstatusDocumentacion';
import ReportePais from './Pages/Reportes/ReportePais';
import CurriculumAutor from './Pages/Reportes/CurriculumAutor';
import EstatusContractual from './Pages/Reportes/EstatusContractual';
import CompraObras from './Pages/Reportes/CompraObras';
import BitacoraMovimientos from './Pages/Reportes/BitacoraMovimientos';
import BitacoraObras from './Pages/Reportes/BitacoraObras';
import FechasFlujosPago from './Pages/Reportes/FechasFlujosPago';
//import FechasVencimiento from './Pages/Reportes/FechasVencimiento'; 
//import DetailCountry from './Pages/Reportes/ReportePais/DetailCountry'

// Configuracion
import Usuarios from './Pages/Administration/User'
import Privilegios from './Pages/Administration/Privilegios'
import ConfigureNotifications from './Pages/Administration/ConfigureNotifications'
import Parametros from './Pages/Administration/Parameters';

// Notificaciones
import Notifications from './Pages/Notifications';

//Catálogos
import PaisOrigen from './Pages/Catalogos/PaisOrigen';
import fuente from './Pages/Catalogos/Fuente';
import tematica from './Pages/Catalogos/Tematica';
import audiencia from './Pages/Catalogos/Audiencia';
import ambiente from './Pages/Catalogos/Ambiente';
//import productores from './Pages/Catalogos/Productores';
import idioma from './Pages/Catalogos/Idioma';
import generos from './Pages/Catalogos/Generos';
import formato from './Pages/Catalogos/Formato';
import motivacion from './Pages/Catalogos/Motivacion';
import vigencia from './Pages/Catalogos/Vigencia';
import autores_adaptadores from './Pages/Catalogos/AutoresAdaptadores';
import duracion_libretos from './Pages/Catalogos/DuracionLibretos';
import subgenero from './Pages/Catalogos/Subgenero';
import ventana from './Pages/Catalogos/Ventana';
import temporalidad from './Pages/Catalogos/Temporalidad';
import tipo_contrato from './Pages/Catalogos/TipoContrato';
import empresa_proveedores from './Pages/Catalogos/EmpresasProveedores';
import nivel_riesgo from './Pages/Catalogos/NivelRiesgo';
import causas_rechazo from './Pages/Catalogos/CausasRechazo';
import area_evaluadora from './Pages/Catalogos/AreaEvaluadora'

import UserProfile from './Pages/Perfil'
import ObrasRegistradas from './Pages/ObrasRegistradas'
import MisAsignaciones from './Pages/ObrasRegistradas/MisAsignaciones'
//AHDA 05/05/2019
import FileViewer from './Pages/Files/fileIndex'

const routes = [{
  component: App,
  routes: [
    {
      component: withTracker(App),
      routes: [
        {
          component: withTracker(LoginDedicated),
          path: '/',
          exact: true,
        },
        //Login
        {
          component: withTracker(Outer),
          path: '/login',
          routes: [
            {
              path: '/login',
              component: withTracker(Login),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Register Form Page
        {
          component: withTracker(Outer),
          path: '/register',
          routes: [
            {
              path: '/register',
              component: withTracker(Register),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Register Form Page
        {
          component: withTracker(Outer),
          path: '/confirm-user',
          routes: [
            {
              path: '/confirm-user',
              component: withTracker(ConfirmUser),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Forgot Password 
        {
          component: withTracker(Outer),
          path: '/reset-password',
          routes: [
            {
              path: '/reset-password',
              component: withTracker(ResetPassword),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //In PERFIL
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/profile',
          routes: [
            {
              path: '/profile',
              component: withTracker(UserProfile),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //In OBRAS REGISTRADAS
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/obras-registradas',
          routes: [
            {
              path: '/obras-registradas',
              component: withTracker(ObrasRegistradas),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //In MIS ASIGNACIONES
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/mis-asignaciones',
          routes: [
            {
              path: '/mis-asignaciones',
              component: withTracker(MisAsignaciones),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //In Manintenance
        {
          component: withTracker(Outer),
          path: '/maintenance',
          routes: [
            {
              path: '/maintenance',
              component: withTracker(Maintenance),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Notifications Page
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/notifications',
          routes: [
            {
              path: '/notifications',
              component: withTracker(Notifications),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Settings Page
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/settings',
          routes: [
            {
              path: '/settings',
              component: withTracker(Construction),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Users Page
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/users',
          routes: [
            {
              path: '/users',
              component: withTracker(Usuarios),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Privilegios Page
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/perfiles-y-privilegios',
          routes: [
            {
              path: '/perfiles-y-privilegios',
              component: withTracker(Privilegios),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //ConfigureNotifications Page
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/configuracion-de-notificaciones',
          routes: [
            {
              path: '/configuracion-de-notificaciones',
              component: withTracker(ConfigureNotifications),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Parametros
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/parametros',
          routes: [
            {
              path: '/parametros',
              component: withTracker(Parametros),
              exact: true,
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Reports Pages
        {
          component: withSession(Dashboard), //AHDA 17/05/2019
          path: '/reporte',
          routes: [
            {
              path: '/reporte',
              component: withTracker(BlankPage),
              exact: true,
            },
            {
              path: '/reporte/obras',
              component: withTracker(ReporteObras),
              exact: true,
            },
            {
              path: '/reporte/area-evaluadora',
              component: withTracker(AreaEvaluadora),
              exact: true,
            },
            {
              path: '/reporte/estatus-documentacion',
              component: withTracker(EstatusDocumentacion),
              exact: true,
            },
            {
              path: '/reporte/pais',
              exact: true,
              component: withTracker(ReportePais),
            },
            /*{
              path: '/reporte/pais/:country',
              exact: true,
              component: withTracker(DetailCountry),
            },*/
            {
              path: '/reporte/curriculum-autor',
              exact: true,
              component: withTracker(CurriculumAutor),
            },
            {
              path: '/reporte/estatus-contractual',
              exact: true,
              component: withTracker(EstatusContractual),
            },
            {
              path: '/reporte/compra-obras',
              component: withTracker(CompraObras),
              exact: true,
            },
            {
              path: '/reporte/bitacora-movimientos',
              component: withTracker(BitacoraMovimientos),
              exact: true,
            },
            {
              path: '/reporte/bitacora-de-obras',
              component: withTracker(BitacoraObras),
              exact: true,
            },
            {
              path: '/reporte/fechas-flujos',
              component: withTracker(FechasFlujosPago),
              exact: true,
            },
            /*{
              path:'/reporte/fechas-vencimiento',
              component: withTracker(FechasVencimiento),
              exact: true,
            },*/
            {
              path: '/reporte/',
              component: withTracker(Error),
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //Catálogos Pages
        {
          component: withSession(Dashboard), //AHDA 20/05/2019
          path: '/catalogos',
          routes: [
            {
              path: '/catalogos/pais-de-origen',
              component: withTracker(PaisOrigen),
              exact: true,
            },
            {
              path: '/catalogos/fuente',
              component: withTracker(fuente),
              exact: true,
            },
            {
              path: '/catalogos/tematica',
              component: withTracker(tematica),
              exact: true,
            },
            {
              path: '/catalogos/audiencia',
              component: withTracker(audiencia),
              exact: true,
            },
            {
              path: '/catalogos/ambiente',
              component: withTracker(ambiente),
              exact: true,
            },
            /*{
              path: '/catalogos/productores',
              component: withTracker(productores),
              exact: true,
            },*/
            {
              path: '/catalogos/idioma',
              component: withTracker(idioma),
              exact: true,
            },
            {
              path: '/catalogos/generos',
              component: withTracker(generos),
              exact: true,
            },
            {
              path: '/catalogos/formato',
              component: withTracker(formato),
              exact: true,
            },
            {
              path: '/catalogos/motivacion',
              component: withTracker(motivacion),
              exact: true,
            },
            {
              path: '/catalogos/vigencia',
              component: withTracker(vigencia),
              exact: true,
            },
            {
              path: '/catalogos/autores-adaptadores',
              component: withTracker(autores_adaptadores),
              exact: true,
            },
            {
              path: '/catalogos/duracion-de-libretos',
              component: withTracker(duracion_libretos),
              exact: true,
            },
            {
              path: '/catalogos/subgenero',
              component: withTracker(subgenero),
              exact: true,
            },
            {
              path: '/catalogos/ventana',
              component: withTracker(ventana),
              exact: true,
            },
            {
              path: '/catalogos/temporalidad',
              component: withTracker(temporalidad),
              exact: true,
            },
            {
              path: '/catalogos/tipo-de-contrato',
              component: withTracker(tipo_contrato),
              exact: true,
            },
            {
              path: '/catalogos/empresa-proveedores',
              component: withTracker(empresa_proveedores),
              exact: true,
            },
            {
              path: '/catalogos/causas-de-rechazo',
              component: withTracker(causas_rechazo),
              exact: true,
            },
            {
              path: '/catalogos/nivel-de-riesgo',
              component: withTracker(nivel_riesgo),
              exact: true,
            },
            {
              path: '/catalogos/area-evaluadora',
              component: withTracker(area_evaluadora),
              exact: true,
            },
            {
              path: '/catalogos/',
              component: withTracker(Error),
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        //AHDA 05/05/2019
        {
          component: OuterEmpty,
          path: '/file',
          routes: [
            {
              path: '/file',
              component: withTracker(FileViewer),
              exact: true
            },
            {
              path: '*',
              component: withTracker(NotFound)
            }
          ]
        },
        {
          path: '*',
          component: withTracker(NotFoundDedicated)
        }
      ]
    }
  ]
}];

export default routes;
