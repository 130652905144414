import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

//Estilos
import '../../styles/components/Form.scss';
import styles from './contact-jss';

//Form
//import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { S3Image } from 'aws-amplify-react';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';

//Iconos
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';

class AddContactForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  constructor(props){
    super(props); 

    this.state = {
      new_name: this.props.dataInit.nombre, 
      new_lastName: this.props.dataInit.apellidos, 
      new_rol: this.props.dataInit.id_rol, 
      item: this.props.dataInit, 
      imgAvatar: this.props.dataInit.avatar ? this.props.dataInit.avatar : undefined, 
      file: [], 
      preview: "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"
    }
  }

  //Inputs 
  handleChange = (event) => {
    this.setState({ [event.target.name] : event.target.value }); 
  }

  handleClear = () => {
    this.setState({
      new_name: '', 
      new_lastName: '', 
      new_rol: undefined, 
      file: [], 
      preview: "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"
    })
  }

  //Submit 
  handleSubmit = (event) => {
    event.preventDefault(); 
    
    //Set new data. 
    let auxRol = this.props.rolesCatalogue.find(r => r.value === this.state.new_rol); 
    
    let auxItem = Object.assign({}, this.state.item); 
    auxItem.nombre = this.state.new_name; 
    auxItem.apellidos = this.state.new_lastName; 
    auxItem.id_rol = this.state.new_rol;
    auxItem.asigned_profile = auxRol ? auxRol.data : ""; 
    
    //Send to submit 
    this.props.handleSubmit(auxItem, this.state.file); 
  }

  //Drop
  onDrop = (files) => {
    
    if (files.length <= 0 ){
      //TODO: show snackbar 
      this.props.dropValidations("No se pudo cargar el archivo"); 
      return false; 
    }

    if (files.length > 1) {
      //TODO: add snackbar
      this.props.dropValidations("Solo un archivo"); 
      return false; 
    } 
    
    this.setState({ file: files, imgAvatar: undefined, preview: files[0].preview}); 
    console.log(this.state.preview); 
  }

  //AHDA 31/05/2019: Added componentDidMount
  //Lifecycle
  componentDidMount(){
    //Empty strings validation
    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
      if (/^\s+$/i.test(value)) {
          return false;
      }
      return true;
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.dataInit !== this.props.dataInit){
      this.setState({
        new_name: nextProps.dataInit.nombre, 
        new_lastName: nextProps.dataInit.apellidos, 
        new_rol: nextProps.dataInit.id_rol, 
        item: nextProps.dataInit,
        imgAvatar: nextProps.dataInit.avatar ? nextProps.dataInit.avatar : undefined, 
        file: [], 
        preview: "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"
      })
    }
  }

  render() {
    const {
      classes,
      dataInit,
      rolesCatalogue
    } = this.props;

    let dropzoneRef;
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp', 'image/jpg'];
    const fileSizeLimit = 93000000;

    if (dataInit === null) {
      return false;
    }

    return (
      <div>
        <ValidatorForm onSubmit={this.handleSubmit}>
          <section className={classes.bodyForm}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
            
              {/* Usuario interno / Usuario externo */}
              <Grid item xs={12}>
                <div>
                  <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN</Typography>
                  <Dropzone
                    className={classes.hiddenDropzone}
                    accept={acceptedFiles.join(',')}
                    acceptClassName="stripes"
                    onDrop={this.onDrop}
                    maxSize={fileSizeLimit}
                    ref={(node) => { dropzoneRef = node; }}
                  />
                  {/* GEO 01/04/2019 Ajustes en imagen */}
                  <div className={classes.avatarWrap}>
                    <Avatar
                      alt={dataInit.name}
                      className={classNames(classes.avatar, classes.unsetFlex)}
                      src=""> 
                      {
                        this.state.imgAvatar !== undefined ? 
                        <S3Image theme={{ photoImg: { width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={this.state.imgAvatar} /> : 
                        <img className={classes.imgDefault} src={this.state.preview} alt=""/>
                      }
                    </Avatar>
                    
                    <Tooltip id="tooltip-upload" title="Subir foto">
                      <IconButton
                        className={classes.buttonUpload}
                        component="button"
                        onClick={() => {
                          dropzoneRef.open();
                        }}
                      >
                        <PhotoCamera />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={24}
            >
              {/* Usuario interno / Usuario externo */}
              <Grid item xs={12}>
                <div>
                  {/*AHDA 31/05/2019: Added isSpaceEmpty*/}
                  <TextValidator
                    label="Nombre"
                    placeholder="Ej. Francisco"
                    id="new_name"
                    name="new_name"
                    value={this.state.new_name}
                    key={1}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    validators={['required', 'isSpaceEmpty']}
                    errorMessages={['*Campo requerido', '*Campo requerido']}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {/*AHDA 31/05/2019: Added isSpaceEmpty*/}
                  <TextValidator
                    label="Apellido"
                    placeholder="Ej. Mora"
                    id="new_lastName"
                    name="new_lastName"
                    value={this.state.new_lastName}
                    key={2}
                    className={classNames(classes.field, classes.w100)}
                    onChange={this.handleChange}
                    validators={['required', 'isSpaceEmpty']}
                    errorMessages={['*Campo requerido', '*Campo requerido']}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classNames(classes.field, classes.w100)}>
                  <SelectValidator
                    label="Perfil en el portal*"
                    value={this.state.new_rol}
                    onChange={this.handleChange}
                    name="new_rol"
                    id="new_rol"
                    key={3}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                    className={classes.selectEmpty}
                    InputLabelProps={{ shrink: true }}
                    displayEmpty
                  >
                    {/*Temporal solution*/}
                    {
                      rolesCatalogue.map((rol, idx) => (
                        <MenuItem value={rol.value} key={`rol_${idx}`} disabled={rol.value !== 12 ? false : this.state.item.id_rol !== 12 ? true : false}>{rol.data}</MenuItem>
                      ))
                    }
                  </SelectValidator>
                </FormControl>
              </Grid>
            </Grid>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="contained" color="primary" type="submit">
              Guardar
            </Button>
            <Button type="button" onClick={this.handleClear}>
              Limpiar
            </Button>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}

AddContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit: PropTypes.object
};


export default withStyles(styles)(AddContactForm);
