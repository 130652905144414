const styles = theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  rootTable: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  contentStep: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  contentLegal: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  stepItem: {
    whiteSpace: 'inherit'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  detailContainer: {
    margin: '-16px auto 0',
    width: '100%',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 960,
      minWidth: 960,
      paddingTop: 40,
      marginTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      //overflowX: 'hidden',
    }
  },
  bodyForm: {
    position: 'relative',
    background: '#FFFFFF',
    padding: 10,
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 10px'
    },
  },
  field: {
    width: '100%',
    marginBottom: 0,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  fieldChip: {
    width: '100%',
    marginBottom: 0,
    '& > div': {
      marginTop: '16px !important',
      // maxHeight: '34px !important',
      minHeight: '34px !important'
    }
  },
  fieldFlot: {
    width: '100%',
    marginBottom: 20,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  headingExpand: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.primary.main
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  finishText: {
    textAlign: 'center',
    margin: theme.spacing.unit * 3,
    '& p': {
      marginBottom: 5,
      color: theme.palette.primary.main,
    }
  },
  rightAction: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  w100: {
    width: '100%',
  },
  paper: {
    position: 'absolute',
    width: "50%",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    }
  },
  bgTitle: {
    backgroundColor: theme.palette.primary.dark,
    height: 50,
    padding: '2px 15px',
    color: '#fff',
    '& h6':{
      display: 'inline-block',
      paddingTop: 10
    }
  },
  flexHead: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  colorTitle: {
    color: "white",
    lineHeight: 'normal'
  },
  bgContenido: {
    padding: 15,
    maxHeight: 500,
    overflow: 'auto',
  },
  textError: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: '8px',
  },
  titleContenido: {
    color: '#4a4949',
    fontSize: 12,
    fontWeight: 'bold',
  },
  textContenido: {
    color: '#7d7d7d',
    fontSize: 12,
    textAlign: 'justify',
  },
  closeModal: {
    position: 'absolute',
    right: 60,
    top: 15
  },
  formControl: { 
    width: '100%', 
    minWidth: 120, 
    '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */ 
        color: 'rgba(0,0,0,0.5) !important' 
    }, 
    '& ::-moz-placeholder': { /* Firefox 19+ */ 
        color: 'rgba(0,0,0,0.5) !important' 
    }, 
    '& :-ms-input-placeholder': { /* IE 10+ */ 
        color: 'rgba(0,0,0,0.5) !important' 
    }, 
    '& :-moz-placeholder': { /* Firefox 18- */ 
        color: 'rgba(0,0,0,0.5) !important' 
    }, 
    [theme.breakpoints.down('sm')]: { 
        minWidth: 90, 
        margin: 0, 
        '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */ 
            fontSize: 14 
        }, 
        '& ::-moz-placeholder': { /* Firefox 19+ */ 
            fontSize: 14 
        }, 
        '& :-ms-input-placeholder': { /* IE 10+ */ 
            fontSize: 14 
        }, 
        '& :-moz-placeholder': { /* Firefox 18- */ 
            fontSize: 14 
        } 
    }, 
}, 
});

export default styles;