import headerBackground from '../../../static/images/bg-login.jpg';
import bg from '../../../static/images/bg-login.jpg';
import { fade } from '@material-ui/core/styles/colorManipulator';

const appFrame = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  zIndex: 1,
};

const styles = theme => ({
  root: {
    width: '100%',
    //height: '100%',
    minHeight: '100%',
    marginTop: 0,
    zIndex: 1,
    overflow: 'auto',
  },
  appFrameInner: {
    ...appFrame,
    height: '100%',
  },
  appFrameOuter: {
    ...appFrame,
    //height: '100%',
    minHeight: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    // height: '100%',
    // overflow: 'auto',
    minHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    },
  },
  outerContent: {
    background: `url(${bg}) no-repeat ${theme.palette.primary.main} left bottom`,
    width: '100%',
    //
    height: '100vh !important',
    overflow: 'hidden',
    //
    backgroundSize: 'cover',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      overflow: 'hidden',
    },
  },
  bgbar: {
    //backgroundColor: theme.palette.primary.main,
    background: `url(${headerBackground}) no-repeat bottom`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    position: 'absolute',
    height: 184,
    top: 0,
    left: 0,
    // nuevos
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    '& a': {
      display: 'block',
      zIndex: 1,
    },
    '& img': {
      paddingTop: theme.spacing.unit * 2,
      width: 140,
      zIndex: 1,
    },
    '&:after': {
      background: 'linear-gradient(93.01deg, rgba(203, 34, 116, 0.4) 0.18%, rgba(255, 87, 2, 0.4) 21.83%, rgba(250, 63, 19, 0.4) 81.13%, rgba(246, 46, 31, 0.4) 98.85%)',
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    }
  },
  mainWrap: {
    position: 'relative',
    marginTop: theme.spacing.unit * 6,
    height: '100%',
    zIndex: 1,
    minHeight: '80vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 12,
    },
    '& > div': {
      paddingBottom: theme.spacing.unit * 4,
      willChange: 'inherit !important' // hack for floating form issue whne expaded
    }
  },
  preloader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    background: 'transparent',
    height: 3,
  },
  materialBg: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    opacity: 0.5
  },
  contentPadding: {
    paddingLeft: 15
  },
  hideApp: {
    display: 'none'
  },
  circularProgress: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 100px)',
  },
  brand: {
    height: 54,
    display: 'flex',
    padding: '10px 10px 5px',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 20
    },
    '& h3': {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 10,
      color: theme.palette.common.white,
    }
  },
  brandMovil: {
    height: 55,
    display: 'flex',
    // padding: '10px 10px 15px',
    position: 'relative',
    // alignItems: 'center',
    // justifyContent: 'center',
    '& img': {
      height: 80,
    },
    '& h3': {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 10,
      color: theme.palette.common.white,
    }
  },
  brandMovilRegistrar: {
    height: 75,
    display: 'flex',
    position: 'relative',
    '& img': {
      height: 75,
    }
  },
  btn: {},
  icon: {},
  btnPicker: {
    position: 'fixed',
    zIndex: 2,
    right: 0,
    top: 200,
    background: fade(theme.palette.background.paper, 0.8),
    borderRadius: '30px 0 0 30px',
    padding: '4px 8px 4px 4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[300]}`,
    '& $btn': {
      background: theme.palette.secondary.main,
      borderRadius: 30,
      padding: 8,
      boxShadow: theme.shadows[4],
      display: 'flex',
      alignItems: 'center',
      width: 40,
      height: 40,
      textCenter: 'cener',
      overflow: 'hidden',
      color: 'transparent',
      transition: 'all 0.3s ease',
      '& $icon': {
        color: theme.palette.background.paper,
      },
      '&:hover': {
        color: theme.palette.background.paper,
        width: 90
      }
    }
  },
  outerEmptyContent:{
    background: "#dcdcdc",
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    }, 
    background: 'none', 
    '& div':{
      width: '100%',
      height: '100%'
    }
  }
});

export default styles;
