import { Auth } from 'aws-amplify';
import { apiPOST,apiPOSTNotAuth } from './service_base';
import { getFile } from './storage';
import { OAuth } from './../../config/cognito';
//Error handling 
let error = {
    status: 500, 
    message: '', 
    userAttributes: null
}

/**
 * Login to portal 
 * @param {*} user : must contain username and password
 */
export const userLogin = (user) => {
    return new Promise(
        function(resolve, reject){
            Auth.signIn(user.username, user.password).then(
                data => {
                    //User is reuqerid to do something
                    if(data.challengeName){
                        switch(data.challengeName){
                            //New password reuired: The user was created is AWS console. 
                            case "NEW_PASSWORD_REQUIRED":
                            //const { family_name, name } = user.challengeParam.userAttributes;
                            error.status = 403; 
                            error.message = 'New Password Required';
                            error.userAttributes = data; 
                            reject(error);
                            break; 
                            default:
                            break; 
                        }
                    }
                    else{
                        //AHDA 23/05/2019: Deleted logic for success
                        resolve(data);  
                    }
                }
            ).catch(
                err => {
                    //console.log(err);
                    error.message = err.message; 
                    //Catch incorrect username error 
                    if(err.message.indexOf('Incorrect username or password') > -1 || err.message.indexOf('User does not exist') > -1)
                        error.status = 401; 
                    else if(err.message.indexOf('Password attempts exceeded') > -1)
                        error.status = 502; 
                    else if(err.message.indexOf('User is not confirmed') > -1){
                        error.status = 505;
                    }
                    else
                        error.status = 500; 
                    reject(error); 
                }
            )
        }
    ); 
}

//AHDA 23/05/2019: Method for logic after login, for external users
export const externalPostLogin = (data, user) => {
    return new Promise(
        function(resolve, reject){
            //Get token session 
            let decoded = data.signInUserSession.idToken.payload; 
            //If user doesn't belong to any group, reject 
            if(decoded["cognito:groups"] !== undefined)
            {    
                //Check for last password change
                //Only applies for external users to Televisa 


                let cognitoUser = user.username;
                if (user.username.includes(OAuth.identityProvider+'_')) {
                    cognitoUser = user.username.split(OAuth.identityProvider+"_")[1];

                }
                apiPOST('/users/get', {
                    cognitoUser, 
                    portal: 2
                }).then(
                    response => {
                        //Save session
                        if(response.status === 200){
                            //Assign rds user
                            decoded.rdsUser = response.data; 
                            decoded.rdsUser.key = response.data.portrait; 

                            if(response.data.estatus === 0 || response.data.estatus === 2){
                                signOutPromise().then().catch().finally(() => {
                                    error.message = "User blocked access";
                                    error.status = 404;
                                    reject(error); 
                                });
                            }else{
                                // JCMY 05/03/18
                                getFile(response.data.portrait, 'public').then(
                                    response => decoded.rdsUser.portrait = response
                                )
                                .catch(
                                    err => decoded.rdsUser.portrait = ""
                                )
                                .finally(
                                    () => {
                                        //AHDA 23/05/2019: Changed localstorage only for success
                                        if(decoded["cognito:groups"][0] === "External_Users"){
                                            const oneday = (24*60*60*1000);
                                            const difDays = Math.round(Math.abs((Number.parseInt(decoded["custom:pwdLastUpdateTime"]) - new Date().getTime())/(oneday)));   
                                        
                                            //AHDA 22/05/2019: Changed password expiration time
                                            if(difDays >= 30){
                                                error.message = 'Password expired after 30 days';
                                                error.status = 405;
                                                reject(error); 
                                            }
                                            else {
                                                apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then(
                                                    response => { 
                                                    localStorage.setItem("user_permissions", JSON.stringify(response.data)); 
                                                    localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                                    localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019 
                                                    resolve(decoded);                                           
                                                  }
                                                ).catch(
                                                    err => {
                                                        error.message = err.message;
                                                        error.status = 500;
                                                        reject(error); 
                                                    }
                                                ); 
                                            }
                                        }
                                        else {
                                            /*localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                            localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019 
                                            resolve(decoded);*/
                                            apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then(
                                                response => { 
                                                localStorage.setItem("user_permissions", JSON.stringify(response.data)); 
                                                localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                                localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019 
                                                resolve(decoded);                                           
                                              }
                                            ).catch(
                                                err => {
                                                    error.message = err.message;
                                                    error.status = 500;
                                                    reject(error); 
                                                }
                                            ); 
                                        }
                                }); 
                            }
                            
                        }
                        else {
                            signOutPromise().then().catch().finally(() => {
                                error.message = response.message;
                                if(response.message.indexOf("User not allowed in portal") > -1){
                                    error.status = 404;
                                }else
                                    error.status = 500; 
                                reject(error);
                            });
                        }
                    }
                ).catch(
                    err => {
                        console.log(err)
                        error.message = err.message;
                        error.status = 500;
                        reject(error); 
                    }
                )
            }
            else{
                error.message = 'User not authorized or not in a group';
                error.status = 500;
                reject(error); 
            }
        }
    )
}

//CHANGE PASSWORD
/**
 * Change password when user signs in the first time after register from AWS console 
 * @param {*} userAttributes : the user attributes of the user 
 * @param {*} user : must contain username and password 
 */
export const changePasswordRequired = (user, newPassword) => {
    return new Promise(
        function(resolve, reject){
            //Sigin is required to obtain the current user 

            
            Auth.signIn({username: user.username, password: user.password}).then(
                response => {
                    if(response.challengeName && response.challengeName === "NEW_PASSWORD_REQUIRED"){
                        //Ask for this atrributes 
                        let attributesData = {
                            name: 'Usuario', family_name : 'Televisa'
                        }
                        
                        let cognitoUser = user.username;
                        if (user.username.includes(OAuth.identityProvider+'_')) {
                            cognitoUser = user.username.split(OAuth.identityProvider+"_")[1];
                
                        }

                        //Complete new password required 
                        Auth.completeNewPassword(response, newPassword, attributesData).then(
                            res => {
                                //Get session token 
                                let decoded = res.signInUserSession.idToken.payload; 
        
                                //Update password timestamp
                                UpdatePasswordLastUpdate(user).then(
                                    data => {
                                        //Save session
                                        apiPOST('/users/get', {
                                            cognitoUser, 
                                            portal: 2
                                        }).then(
                                            response => {
                                                if(response.status === 200){
                                                    //Assign rds user
                                                    decoded.rdsUser = response.data; 
                                                    decoded.rdsUser.key = response.data.portrait; 
            
                                                    if(response.data.estatus === 0 || response.data.estatus === 2){
                                                        signOutPromise().then().catch().finally(() => {
                                                            error.message = "User blocked access";
                                                            error.status = 404;
                                                            reject(error); 
                                                        });
                                                    }else{
                                                        // JCMY 05/03/18
                                                        getFile(response.data.portrait, 'public').then(
                                                            response => decoded.rdsUser.portrait = response
                                                        )
                                                        .catch(
                                                            err => decoded.rdsUser.portrait = ""
                                                        )
                                                        .finally(
                                                            () => {
                                                                /*localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                                                localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019
                                                                resolve(decoded);*/
                                                                apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then(
                                                                    response => { 
                                                                    localStorage.setItem("user_permissions", JSON.stringify(response.data)); 
                                                                    localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                                                    localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019 
                                                                    resolve(decoded);                                           
                                                                  }
                                                                ).catch(
                                                                    err => {
                                                                        error.message = err.message;
                                                                        error.status = 500;
                                                                        reject(error); 
                                                                    }
                                                                ); 
                                                        }); 
                                                    }
                                                    
                                                }
                                                else {
                                                    signOutPromise().then().catch().finally(() => {
                                                        error.message = response.message;
                                                        if(response.message.indexOf("User not allowed in portal") > -1){
                                                            error.status = 404;
                                                        }else
                                                            error.status = 500; 
                                                        reject(error);
                                                    });
                                                }
                                        
                                            }
                                        )
                                    }
                                ).catch(
                                    err => {
                                        apiPOST('/users/get', {
                                            cognitoUser, 
                                            portal: 2
                                        }).then(
                                            response => {
                                                if(response.status === 200){
                                                    /*decoded.rdsUser = response.data; 
                                                    localStorage.setItem('current_user', JSON.stringify(decoded));
                                                    localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019
                                                    resolve(decoded);*/
                                                    apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then(
                                                        response => { 
                                                        localStorage.setItem("user_permissions", JSON.stringify(response.data)); 
                                                        localStorage.setItem('current_user', JSON.stringify(decoded)); 
                                                        localStorage.setItem('last_activity', new Date().getTime()); //AHDA 17/05/2019 
                                                        resolve(decoded);                                           
                                                      }
                                                    ).catch(
                                                        err => {
                                                            error.message = err.message;
                                                            error.status = 500;
                                                            reject(error); 
                                                        }
                                                    ); 
                                                }
                                            }
                                        )
                                    }
                                )
                            }
                        ).catch(
                            err => {
                                console.log(err); 
                                error.message = err.message; 
                                
                                //Catch password policies errors 
                                if(error.message.indexOf('Password does not conform to policy') > -1 
                                || error.message.indexOf('Password not long enough') > -1)
                                    error.status = 403;
                                else
                                    error.status = 500;
                                
                                reject(error); 
                            }
                        )
                    }
                }
            ).catch(
                err => {
                    console.log(err);
                    error.message = err.message 
                    error.status = 500; 
                    reject(error); 
                }
            )
        }
    ); 
}

/**
 * Change Password 
 * @param {*} user : must contain username, old password and new password  
 */
export const changePassword = (user) => {
    let error = {}; 

    //AHDA 23/05/2019: Changed method for checking password history
    return new Promise(
        function(resolve, reject){
            //Get current user authenticated 
            Auth.currentAuthenticatedUser()
            .then(
                data => {
                    //Change password 
                    return apiPOSTNotAuth("/users/checkHistory", {cognito_user: user.username, new_pwd: user.newPassword}).then(
                        response => {
                            if(response.status === 200){
                                Auth.changePassword(data, user.oldPassword, user.newPassword)
                                .then(
                                    data => {
                                        //Update password timestamp 
                                        UpdatePasswordLastUpdate().then(
                                            data => {
                                                resolve(data); 
                                            }
                                        ).catch(
                                            err => {
                                                resolve(data); 
                                            }
                                        )
                                    }
                                )
                                .catch(
                                    err => {
                                        console.log(err);
                                        //Catch incorrect password errors 
                                        if(err.message.indexOf("Value at 'previousPassword' failed to satisfy constraint") > -1 
                                        || err.message.indexOf("Incorrect username or password") > -1){
                                            error.status = 401; 
                                        }
                                        else{
                                            error.status = 500; 
                                        }
                    
                                        error.message = err.message; 
                                        error.userAttributes = null; 
                    
                                        reject(error); 
                                    }
                                );
                            }
                            else if(response.status === 202){
                                error.status = 400; 
                                error.message = response.message; 
                                reject(error); 
                            }else{
                                error.status = 500; 
                                error.message = response.message; 
                                reject(error); 

                            }
                        }
                    ).catch(
                        err => {
                            error.status = 500; 
                            error.message = err.message; 
                            reject(error); 
                        }
                    )
                }
            ).catch(err => {
                error.status = 500; 
                error.message = err.message; 
                reject(error); 
            })
        }
    )
}

//FORGOT PASSWORD
/**
 * ForgotPassword: send verification code to user
 * @param {*} user : must contain username
 */
export const forgotPassword = (user) => {
    return new Promise(
        function(resolve, reject){
            //Send verification code 
            Auth.forgotPassword(user.username).then(
                data => {
                    let response = {
                        code: 200, 
                        data: data
                    } 
                    resolve(response); 
                }
            ).catch(
                err => {
                    console.log(err); 
                    
                    //Cach if user exists 
                    if(err.message.indexOf('Username/client id combination not found') > -1)
                        error.status = 405; 
                    else
                        error.status = 500; 
                    
                        error.message = err.message; 
                    reject(error); 
                }
            ); 
            
        }
    )
}

/**
 * Complete forgot password: complete process for forgotten password 
 * @param {*} user : must contain username, verification code and new password 
 */
export const completeForgotPassword = (user) => {
    let error = {}; 

    return new Promise(
        function(resolve, reject){
            //AHDA 23/05/2019: Added logic for password history
            apiPOSTNotAuth("/users/checkHistory", {cognito_user: user.username, new_pwd: user.newPassword}).then(
                response => {
                    if(response.status === 200){
                        //Complete password 
                        Auth.forgotPasswordSubmit(user.username, user.verficationCode, user.newPassword).then(
                            data => {
                                console.log(data); 
                                
                                let response = {
                                    code: 200
                                } 
                                
                                //Signin temporally to update user password timestamp
                                //Not the best solution, search for other. 
                                //WORST CASE: an outdated password last updated timestamp 
                                Auth.signIn({username: user.username, password: user.newPassword}).then(
                                    data => {
                                        //Update password timestamp 
                                        UpdatePasswordLastUpdate(user).then(
                                            data => {}
                                        ).catch(
                                            err => {}
                                        ) 

                                        //Sign out temporary session
                                        //Success or error, resolve 
                                        Auth.signOut().then(
                                            data => resolve(response)
                                        ).catch(
                                            err => resolve(response)
                                        )
                                    }
                                ).catch(
                                    err => {
                                        //If couldnt sign in, resolve anyway 
                                        resolve(response);
                                    }
                                )
                            
                            }
                        ).catch(
                            err => {
                                console.log(err); 
                                if(err.message.indexOf('Invalid verification code provided') > -1)
                                    error.status = 505; 
                                else
                                    error.status = 500;
                                error.message = err.message; 
                                reject(error); 
                            }
                        )
                    }else if(response.status === 202){
                        error.status = 400;
                        error.message = response.message; 
                        reject(error); 

                    }else{
                        error.status = 500;
                        error.message = response.message; 
                        reject(error); 
                    }
                }
            ).catch(err => {
                error.status = 500;
                error.message = err.message; 
                reject(error); 
            }); 
        }
    )
}

//SESSION MANAGEMENT
/**
 * Logout 
 */
export function logOut(){
    Auth.signOut().then(
        data => {
            //Clear local storage and send to login 
            localStorage.clear(); 
            window.location.href = '/'
        }
    ).catch(
        err => {
            localStorage.clear(); 
            window.location.href = '/'
        }
    );
}

//AHDA 16/04/2019
//Function for login out with promise
export function logOutPromise(){
    return new Promise(
        function(resolve, reject){
            Auth.signOut().then(
                data => {
                    //Clear local storage and send to login 
                    localStorage.clear(); 
                    resolve(data); 
                }
            ).catch(
                err => {
                    localStorage.clear(); 
                    reject(err); 
                }
            );
        }
    )
}

//DONT MIX METHODS: this is used in dashboard, wich will cause a loop if mixed 
//Check if there is a user logged 
export function checkSession(){
    //TODO: check token 
    /*const user = localStorage.getItem('current_user');
    if(user === null){
        window.location.href= '/'; 
    }*/
    Auth.currentSession().then(
        current => {
            console.log(current); 
            
            const user = localStorage.getItem("current_user"); 
            if(user === null){
                //NOT SURE YOU HAVE TO SIGN OUT EVERY TIME
                signOut(true)
            }
        }
    ).catch(
        err => {
            signOut(true)
        }
    )
}

//Check if there is a user logged 
export function checkHasSession(){
    //TODO: refresh token 
    /*const user = localStorage.getItem('current_user');
    if(user !== null){
        window.location.href= '/reporte'; 
    }*/

    //Check for valid token 
    Auth.currentSession().then(
        response => {
            const user = localStorage.getItem("current_user"); 
            if(user !== null){
                window.location.href= '/reporte'; 
            }
        }
    ).catch(
        err => {
            signOut(false); 
        }
    ); 
}

//Helpers
function signOut(sendToLogin){
    Auth.signOut().then(
        data => {
            localStorage.clear(); 
            if(sendToLogin)
                window.location.href = '/'
        }
    ).catch(
        err => { 
            localStorage.clear(); 
            if(sendToLogin)
                window.location.href = '/'
        }
    );
}

//REGISTER
/**
 * Register new user 
 * @param {*} user : must contain username, password, email, name, lastname 
 */
export const SignUp = (user, userDB) =>{
    //ERRORS:
    /**
     * 405: couldnt insert to RDS
     * 400: User already exists 
     * 500: general error 
     */
    return new Promise(
        function(resolve, reject){
            let error = {}
            
            Auth.signUp(user).then(
                data => {
                    console.log(data); 
                    //Send to RDS with status 0: not verified
                    apiPOSTNotAuth('/users/add', userDB).then(
                        response => {
                            apiPOSTNotAuth("/users/checkHistory", {cognito_user: user.username, new_pwd: user.password}).then(
                                r => {
                                    resolve({cognito: data, rds: response}); 
                            });
                        }
                    ).catch(
                        err => {
                            error.status = 405; 
                            error.message = err.message; 
                            resolve(error); 
                        }
                    )
                }
            ).catch(
                err => { 
                    console.log(err);
                    error.status = 500;
                    if(err.code === "UsernameExistsException"){
                        error.status = 400; 
                    }
                    error.message = err.message;
                    reject(error);  
                }
            )
        }
    )
}

/**
 * Complete register: complet register process verificating user with code
 * @param {*} user : must contain username, verification code  
 */
export const CompleteSignUp = (user) =>{
    return new Promise(
        function(resolve, reject){
            Auth.confirmSignUp(user.username, user.code).then(
                data => {
                    apiPOSTNotAuth('/users/add', user).then(
                        response => {
                            resolve({cognito: data, rds: response}); 
                        }
                    ).catch(
                        err => {
                            error.status = 405; 
                            error.message = err.message; 
                            resolve(error); 
                        }
                    )
                }
            ).catch(
                err => {
                    console.log(err); 
                    
                    if(err.code === "UsernameExistsException" || err.code === "NotAuthorizedException" ||
                    err.code === "UserNotFoundException" || err.code === "InvalidParameterException"){
                        error.status = 400; 
                    }
                    else if(err.code === "CodeMismatchException" || err.code === "ExpiredCodeException"){
                        error.status = 404;
                    }
                    else{
                        error.status = 500; 
                    }

                    error.message = err.message;
                    reject(error); 
                }
            )
        }
    )
}

/**
 * Resend verification code for register 
 * @param {*} user : must contain username 
 */
export const ResendCode = (user) => {
    return new Promise(
        function(resolve, reject){
            Auth.resendSignUp(user.username).then(
                () => {resolve('Success')}
            ).catch(
                err => {
                    //TODO: handle error 
                    //non existing in cognito
                    console.log(err); 
                    
                    if(err.code === "UsernameExistsException" || err.code === "NotAuthorizedException" ||
                    err.code === "UserNotFoundException" || err.code === "InvalidParameterException"){
                        error.status = 400; 
                    }
                    else{
                        error.status = 500; 
                    }

                    error.message = err.message;
                    reject(error); 
                }
            )
        }
    )
}

/**
 * Update password last update: update timestamp for last updated password 
 */
const UpdatePasswordLastUpdate = () =>{
    //console.log( new Date().getTime()); 
    return new Promise(
        function(resolve, reject){
            //Get current session 
            Auth.currentAuthenticatedUser().then(
                user => {
                    //Update timestamp 
                    Auth.updateUserAttributes(user, {'custom:pwdLastUpdateTime': new Date().getTime().toString()}).then(
                        data => {
                            resolve(data); 
                        } 
                    ).catch(
                        err => {
                            console.log(err); 
                            reject(err); 
                        }
                    )
                }
            ).catch(
                err => {
                    console.log(err); 
                    reject(err); 
                }
            )
        }
    )
    
}


function signOutPromise(){
    return new Promise(
        function(resolve, reject){
            Auth.signOut().then(
                data => {
                    resolve(data); 
                }
            ).catch(
                err => { 
                    reject(err); 
                }
            );
        }
    ); 
}

//AHDA 16/04/2019
//Login for ADFS Auth 
export const getCurrentAuthenticated = () => {
    return new Promise(function(resolve, reject){
        //AHDA 14/05/2019
        Auth.currentAuthenticatedUser().then(currentAuth => {
            resolve(currentAuth)
        }).catch(err => {
            reject(err); 
        })
    })
}

export const getCurrentUserInfo = () => {
    return new Promise(function(resolve, reject){
        //AHDA 14/05/2019
        Auth.currentUserInfo().then(cuurentUser =>{
            console.log("Current User: ", cuurentUser); 
            resolve(cuurentUser); 
        }).catch(err => {
            console.log("Current User: ", err); 
            reject(err); 
        })
    })
}