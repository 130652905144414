import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './user-jss';
import PapperBlock from './../PapperBlock/PapperBlock';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';
import Loader from '../Loader/Loader';
import Typography from '@material-ui/core/Typography';
//AHDA 16/04/2019
//Added dependencies for Hub login 
import OAuthButton from './OAuthButton';
import { Hub } from 'aws-amplify';
import { ContentDivider } from '../Divider';
import ChangePasswordForm from './ChangePassword';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import logo_televisa from '../../../static/images/logo-televisa.svg';
import logoObras from "../../../static/images/logo-obras.png";

//Language
//import langstr from '../../language/lang'; 
import { LanguageConsumer } from './../../language/LanguageContext';

//Services 
//AHDA 23/05/2019
import {
  userLogin,
  changePasswordRequired,
  ResendCode,
  logOutPromise,
  getCurrentAuthenticated,
  getCurrentUserInfo,
  externalPostLogin
} from '../../services/cognito_service';
import { OAuth } from './../../../config/cognito';
import { apiPOST } from '../../services/service_base';
import { Hidden } from '@material-ui/core';

class LoginForm extends React.Component {
  state = {
    showPassword: false,
    showConfirm: false,
    showNew: false,
    errPwd: false,
    email: '',
    password: '',
    newPwd: '',
    confirmPwd: '',
    open: false,
    isLoading: false,
    step: 'login',
    errMessage: 'errMsg'
  }

  //AHDA 16/04/2019
  //Added constructor
  _isMounted = false;

  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    Hub.listen('auth', this);
  }

  //AHDA 16/04/2019
  //Added component did mount
  //Lifecycle
  componentDidMount() {
    //TODO: Add error messages 
    getCurrentAuthenticated().then(user => {

      if (!this._isMounted) {
        this._syncUserWithDB(user, user.attributes);
        this._isMounted = true;
      }

      /*getCurrentUserInfo().then(response =>{
        console.log("Current attributes: ", response);
        
      }).catch(err => 
        console.log("current info", err));*/
    }).catch(e => {
      console.log(e);
    });
  }

  //AHDA 16/04/2019
  //Method for hub, auth with ADFS 
  onHubCapsule(capsule) {

    const { channel, payload, source } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          getCurrentAuthenticated().then(user => {
            console.log("Current user: ", user);

            if (!this._isMounted) {
              this._syncUserWithDB(user, user.attributes);
              this._isMounted = true;
            }

            /*getCurrentUserInfo().then(response =>{
              console.log("Current attributes: ", response);
              
            }).catch(err => 
              console.log("current info", err));*/
          }).catch(e => {
            console.log(e);
          });
          break;

        case 'signIn_failure':
          console.log('not signed in');
          break;
        default:
          break;
      }
    }
  }

  //AHDA 16/04/2019
  //Method for synching an ADFS user into de DB 
  _syncUserWithDB = (user, attributtes) => {

    let decode = user.signInUserSession.idToken.payload;
    //AHDA 23/05/2019: Added validation and logic for external users
    if (decode["cognito:groups"]) {

      //cognito users only have one rol, ADFS users have two
      if (decode["cognito:groups"].length <= 1) {

        const user_auth = {
          username: user.username
        }
        externalPostLogin(user, user_auth).then(
          response => {
            window.location.href = "/reporte"
          }
        ).catch(err => {
          console.log(err)
          let { status } = err;
          switch (status) {
            //Blocked or deleted user 
            case 404:
              this.setState({ isLoading: false, open: true, errMessage: 'deletedUser' });
              break;
            //Expired password
            case 405:
              this.setState({ isLoading: false, step: 'expired' })
              break;
            default:
              this.setState({ isLoading: false, open: true, errMessage: 'errMsg' })
              break;
          }
        });
      } else {
        //AHDA 14/05/2019
        //Method for active directory
        //Getting user from database

        let cognitoUser = user.username;
        if (user.username.includes(OAuth.identityProvider + '_')) {
          cognitoUser = user.username.split(OAuth.identityProvider + "_")[1];
        }
        apiPOST("/users/get", {
          cognitoUser,
          portal: 2,
          name: attributtes.name ? attributtes.name : null,
          lastName: attributtes.family_name ? attributtes.family_name : null,
          numEmpl: attributtes["custom:employeeID"] ? attributtes["custom:employeeID"] : null,
          profile: attributtes.profile ? attributtes.profile : null,
          businessUnit: attributtes["custom:profileAD"] ? attributtes["custom:profileAD"] : null
        }).then(
          response => {
            //Decoded variable for saving user in local storage 
            let decoded = {};

            //If success 
            if (response.status === 200) {

              //Assign rds user
              decoded.rdsUser = response.data;
              decoded.rdsUser.key = response.data.portrait;

              //If user deleted or blocked 
              if (response.data.estatus === 0 || response.data.estatus === 2) {
                logOutPromise().then().catch().finally(() => {
                  this.setState({ isLoading: false, open: true, errMessage: 'deletedUser' });
                });
              }
              else {
                apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then(
                  response => { 
                  localStorage.setItem("user_permissions", JSON.stringify(response.data)); 
                  localStorage.setItem('current_user', JSON.stringify(decoded)); 
                  localStorage.setItem('last_activity', new Date().getTime()); 
                  window.location.href = "/reporte";                                          
                }
              ).catch(
                  err => {
                    console.error(err) 
                  }
              );
                localStorage.setItem('current_user', JSON.stringify(decoded));
                
              }
            }
            else {
              //Logout user in error
              logOutPromise().then().catch().finally(() => {

                //If user does not have rol for Obras portal send message
                if (response.message.indexOf("User not allowed in portal") > -1) {
                  this.setState({ isLoading: false, open: true, errMessage: 'deletedUser' });
                } else
                  this.setState({ isLoading: false, open: true, errMessage: 'errMsg' })
              });
            }
          }
        ).catch(err => {
          //Send message in error
          console.log("Error", err);
          this.setState({ isLoading: false, open: true, errMessage: 'errMsg' })
        });
      }
    }
  }

  //Controls
  handleClickShowPassword = (pwd) => {
    switch (pwd) {
      case 'current':
        this.setState({ showPassword: !this.state.showPassword });
        break;
      case 'new':
        this.setState({ showNew: !this.state.showNew });
        break;
      case 'confirm':
        this.setState({ showConfirm: !this.state.showConfirm });
        break;
      default:
        break;
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClick = () => {
    window.location.href = '/reset-password';
  }

  handleRegister = () => {
    window.location.href = '/register';
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  //Submit
  handleSubmit = (event) => {
    event.preventDefault();

    const user = {
      // JCMY 09/07/0219 incidencia 1032
      username: (this.state.email).toLowerCase(),
      password: this.state.password
    }

    switch (this.state.step) {
      case 'login':
        this.setState({ isLoading: true });
        //AHDA 23/05/2019: Deleted logic for success
        userLogin(user).then().catch(
          err => {

            console.log(err);
            let { status } = err;

            switch (status) {
              //New password required 
              case 403:
                this.setState({ isLoading: false, step: 'changeRequired', errPwd: false });
                break;
              //Blocked or deleted user 
              case 404:
                this.setState({ isLoading: false, open: true, errMessage: 'deletedUser' });
                break;
              //Wrong username or password
              case 401:
                this.setState({ isLoading: false, errPwd: true, password: '' });
                break;
              //Expired password
              case 405:
                this.setState({ isLoading: false, step: 'expired' })
                break;
              //Too many tries
              case 502:
                this.setState({ isLoading: false, open: true, errMessage: 'blockedUser' })
                break;
              //User not verified
              case 505:
                ResendCode({ username: this.state.email }).then(
                  response => {
                    this.setState({ isLoading: false, open: true, errMessage: 'accountNotVerified' })
                  }
                ).catch(
                  err => {
                    this.setState({ isLoading: false, open: true, errMessage: 'accountNotVerified' })
                  }
                )
                break;
              default:
                this.setState({ isLoading: false, open: true, errMessage: 'errMsg' })
                break;
            }
          }
        )
        break;
      case 'changeRequired':
        if (this.state.newPwd === this.state.confirmPwd) {
          this.setState({ isLoading: true, errPwd: false })
          //Set new password 
          changePasswordRequired(user, this.state.newPwd).then(
            response => {
              window.location.href = "/reporte";
            }
          ).catch(
            err => {
              console.log(err);
              //Password does not match policy 
              if (err.status === 403) {
                this.setState({ isLoading: false, errPwd: false, errPolicy: true })
              }
              else {
                this.setState({ isLoading: false, open: true, errPwd: false, errPolicy: false })
              }
            }
          )
        } else {
          this.setState({ errPwd: true })
        }
        break;
      default:
        break;
    }
  }

  //Notifications
  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.formWrap}>
              <PapperBlock whiteBg>
                <Hidden mdUp>
                  <div className={classes.brandMovil}>
                    <img src={logo_televisa} />
                  </div>
                </Hidden>
                <div className={classes.logoObrasContainer}>
                  <img src={logoObras} />
                </div>

                {
                  this.state.isLoading ? <Loader text={lang.login.logginIn} /> :
                    <React.Fragment>
                      {
                        {
                          'login':
                            <div>
                              <form onSubmit={this.handleSubmit}>
                                {/* GEO 01/04/2019
                                  Se ajustan inputs al mismo estilo que todos los que tiene el sitio y se agrega tooltip a botón de mostrar/ocultar contraseña. */}
                                <div>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={16}
                                  >
                                    <Grid item xs={12}>
                                      <FormControl className={classes.w100} error={this.state.errPwd}>
                                        <TextField
                                          id="email"
                                          name="email"
                                          placeholder="Ej. correo@ejemplo.com"
                                          required
                                          label={lang.common.email}
                                          className={classNames(classes.textField, classes.w100)}
                                          value={this.state.email}
                                          onChange={this.handleChange}
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormControl className={classNames(classes.margin, classes.textField, classes.w100)} error={this.state.errPwd}>
                                        <InputLabel shrink htmlFor="password">{lang.common.password}</InputLabel>
                                        <Input
                                          id="password"
                                          name="password"
                                          type={this.state.showPassword ? 'text' : 'password'}
                                          value={this.state.password}
                                          required
                                          onChange={this.handleChange}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <Tooltip title="Ver/Ocultar Contraseña">
                                                <IconButton
                                                  aria-label="Toggle password visibility"
                                                  onClick={() => this.handleClickShowPassword('current')}
                                                >
                                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </Tooltip>
                                            </InputAdornment>
                                          }
                                        />
                                        {this.state.errPwd ? <FormHelperText id="weight-helper-text" error>{lang.login.incorrectUser}</FormHelperText> : null}
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.btnArea}>
                                  {/*<FormControlLabel control={<Field name="remember" component={Checkbox} />} label={lang.login.remember} />*/}
                                  <Button variant="contained" size="small" color="primary" type="submit">
                                    {lang.common.continue}
                                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                                  </Button>
                                </div>
                              </form>
                              <ContentDivider content="O" />
                              <OAuthButton />

                              <div className={classes.help}>
                                <Typography variant="p" >
                                  {lang.login.cantLogin} <a href="mailto:obras@televisaunivision.com">obras@televisaunivision.com</a>
                                </Typography>
                              </div>
                              <div className={classes.footer}>

                                <Button size="small" color="secondary" name="reset" className={classes.button} onClick={this.handleClick}>{lang.login.forgotPassword}</Button>
                                | <Button size="small" color="secondary" name="register" className={classes.button} onClick={this.handleRegister}>Registrar</Button>
                              </div>
                            </div>
                          ,
                          'changeRequired':
                            <form onSubmit={this.handleSubmit}>
                              <Typography variant="subtitle2" gutterBottom >
                                {lang.login.changePswdRequiredMsg}
                              </Typography>
                              <div>
                                <FormControl className={classes.formControl} required={true} error={this.state.errPwd || this.state.errPolicy}>
                                  <InputLabel htmlFor="new-pwd">{lang.login.newPwd}</InputLabel>
                                  <Input
                                    id="new-pwd"
                                    value={this.state.newPwd}
                                    type={this.state.showNew ? 'text' : 'password'}
                                    name="newPwd"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="Toggle password visibility"
                                          onClick={() => this.handleClickShowPassword('new')}
                                        >
                                          {this.state.showNew ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    inputProps={
                                      { maxLength: 60 }
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div>

                                <FormControl className={classes.formControl} required={true} error={this.state.errPwd || this.state.errPolicy}>
                                  <InputLabel htmlFor="confirm-pwd">{lang.login.confirmPassword}</InputLabel>
                                  <Input
                                    id="confirm-pwd"
                                    name="confirmPwd"
                                    value={this.state.confirmPwd}
                                    type={this.state.showConfirm ? 'text' : 'password'}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="Toggle password visibility"
                                          onClick={() => this.handleClickShowPassword('confirm')}
                                        >
                                          {this.state.showConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    inputProps={
                                      { maxLength: 60 }
                                    }
                                  />
                                  {this.state.errPwd ? <FormHelperText id="weight-helper-text">{lang.login.passNoMatch}</FormHelperText> : null}
                                  {this.state.errPolicy ? <FormHelperText>{lang.login.pwdPolicy}</FormHelperText> : null}
                                </FormControl>
                              </div>
                              <div className={classes.btnArea}>
                                {/*<FormControlLabel control={<Field name="remember" component={Checkbox} />} label={lang.login.remember} />*/}
                                <Button variant="contained" color="primary" type="submit" size="small">
                                  {lang.common.continue}
                                  <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                                </Button>
                              </div>
                            </form>,
                          'expired':
                            <ChangePasswordForm />
                        }[this.state.step]
                      }
                    </React.Fragment>
                }
              </PapperBlock>

              {/*for error notifications*/}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                <CustomNotification
                  onClose={this.handleClose}
                  variant="error"
                  message={lang.common[`${this.state.errMessage}`]} />
              </Snackbar>
            </div>
        }
      </LanguageConsumer>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginForm);
