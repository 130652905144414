import { apiPOST, apiPOSTNotAuth } from "./service_base";

//AHDA 15/04/2019: commented unused dependencies
import { Storage } from "aws-amplify";

export const promiseProgress = (promises, cbProgress) => {
  let d = 0;
  cbProgress(0);

  const response = (res) => {
    d++;
    // cbProgress( (d * 100) / promises.length);
    return res;
  };

  for (const promise of promises) {
    promise.then(response);
  }

  return Promise.all(promises);
};

//Save one file
export const saveFile = (path, file, level, fileName) => {
  return new Promise(function(resolve, reject) {
    let name = `${file.name}`.replace(/[^0-9A-Z.]+/gi, "-");

    if (fileName != "") {
      name = fileName.replace(/[^0-9A-Z.]+/gi, "-");
    }

    Storage.put(`${path}/${name}`, file, {
      level: level,
      contentType: file.type,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveUserPhoto = (file, user) => {
  return new Promise(function(resolve, reject) {
    Storage.put(
      `profilePicture/${new Date().getTime()}_user${user.id_usuario}_` +
        `${file.name}`.replace(/[^0-9A-Z.]+/gi, "-"),
      file,
      {
        level: "public",
        contentType: file.type,
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Save multiple files user
export const saveMultipleFiles = (path, files, level) => {
  let keys = [];
  let errors = [];

  let response = {
    message: "",
    keys: [],
    errors: [],
  };

  return new Promise(function(resolve, reject) {
    if (files.length <= 0) {
      response.message = "No files";
      resolve(response);
    }

    for (let x in files) {
      Storage.put(
        `${path}/${files[x].name.replace(/[^0-9A-Z.]+/gi, "-")}`,
        files[x],
        {
          level: level,
          contentType: files[x].type,
        }
      )
        .then((result) => {
          keys.push(result);
          if (x == files.length - 1) {
            response.keys = keys;
            response.errors = errors;
            resolve(response);
          }
        })
        .catch((err) => {
          errors.push(files[x]);

          if (x === files.length - 1) {
            response.keys = keys;
            response.errors = errors;
            resolve(response);
          }
        });
    }
  });
};

export const saveMultipleFilesPromise = (data, cbProgress) => {
  const { path, files, level } = data;

  let keys = [];
  let errors = [];

  let response = {
    message: "",
    keys: [],
    errors: [],
  };

  //Recibir metodo de guardar en rds
  let promises = [];
  files.map((file, fileIdx) => {
    promises.push(
      Storage.put(
        `${path}/` + `${file.name}`.replace(/[^0-9A-Z.]+/gi, "-"),
        file,
        {
          level: level,
          contentType: file.type,
          progressCallback(uploadProgress) {
            if (cbProgress) {
              cbProgress(
                calculateProgress(fileIdx, files.length, uploadProgress)
              );
            }
          },
        }
      )
        .then((result) => {
          file.ruta = result.key;
          file.file = undefined;
          keys.push(file);
          return "Success";
        })
        .catch((err) => {
          file.file = undefined;
          errors.push(file);
          return "Error";
        })
    );
  });

  return Promise.all(promises).then((result) => {
    console.log(result);

    response.keys = keys;
    response.errors = errors;
    if (result) {
      return response;
    }
  });
};

export const getFileDownload = (key, level) => {
  return new Promise(function(resolve, reject) {
    Storage.get(key, {
      level: level,
      download: true,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFile = (key, level) => {
  return new Promise(function(resolve, reject) {
    Storage.get(key, {
      level: level
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOtherUserFile = (key, user) => {
  return new Promise(function(resolve, reject) {
    Storage.get(key, { level: "protected", identityId: user })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//AHDA 05/05/2019
//Added complete file saving (for only consuming one promise)
export const saveCompleteFile = (path, file, level, url, body) => {
  return new Promise(function(resolve, reject) {
    Storage.put(
      `${path}/${new Date().getTime()}_` +
        `${file.name}`.replace(/[^0-9A-Z.]+/gi, "-"),
      file,
      {
        level: level,
        contentType: file.type,
      }
    )
      .then((result) => {
        body.ruta = result.key;
        apiPOST(url, { material: [body] })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              resolve({ key: result.key, data: response.data });
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const calculateProgress = (fileIdx, filesCount, progress) => {
  const { loaded, total } = progress;
  let totalPercentage = 0;
  if (loaded > 0 && loaded !== null && total !== null) {
    const percentagePerFile = 100 / filesCount;
    const filePercentage = 100 * (loaded / total);
    const currentPercentage = filePercentage * (percentagePerFile / 100);
    totalPercentage = percentagePerFile * fileIdx + currentPercentage;
  }

  return totalPercentage;
};
//AHDA 05/05/2019
//Added method saveMultipleFilesAll
export const saveMultipleFilesAll = (files, level, cbProgress) => {
  let keys = [];
  let errors = [];

  let response = {
    message: "",
    keys: [],
    errors: [],
  };

  //Recibir metodo de guardar en rds
  let promises = [];
  files.map((file, fileIdx) => {
    promises.push(
      Storage.put(
        `${file.path}/${new Date().getTime()}_` +
          `${file.file.name}`.replace(/[^0-9A-Z.]+/gi, "-"),
        file.file,
        {
          level: level,
          contentType: file.file.type,
          progressCallback(uploadProgress) {
            cbProgress(
              calculateProgress(fileIdx, files.length, uploadProgress)
            );
          },
        }
      )
        .then((result) => {
          file.ruta = result.key;
          file.file = undefined;
          keys.push(file);
          return "Success";
        })
        .catch((err) => {
          file.file = undefined;
          errors.push(file);
          return "Error";
        })
    );
  });

  return Promise.all(promises).then((result) => {
    response.keys = keys;
    response.errors = errors;
    if (result) {
      return response;
    }
  });
};

//AHDA 07/05/2019
//Added method for deleting files
export const deleteFileComplete = (url, body, key) => {
  console.log(body);
  return new Promise(function(resolve, reject) {
    apiPOSTNotAuth(url, body)
      .then((response) => {
        if (response.status === 200) {
          Storage.remove(key)
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              resolve(err);
            });
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
