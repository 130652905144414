import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Notification from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { LanguageConsumer } from './../../language/LanguageContext';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

//Notifi
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Alarm from '@material-ui/icons/Alarm';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Check from '@material-ui/icons/Check';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SimCardAlert from '@material-ui/icons/SimCardAlert';
import FastForward from '@material-ui/icons/FastForward'; //aHDA 08/05/2019

//Styles 
import '../../styles/components/Messages.scss';
import styles from './header-jss';

import { S3Image } from 'aws-amplify-react';

//Services
import { logOut } from '../../services/cognito_service';

import { apiPOST, returnCurrentUserId } from '../../services/service_base';

const notifTypes = [
  "newWorkRegister",
  "workEvaluationAssign",
  "additionalMaterialRequest",
  "additionalMaterialReception",
  "dueTime",
  "evaluationAssessment",
  "purchaseAuthorization", 
  "legalDocumentsExpiration", 
  "registerWorkTime"
]

class UserMenu extends React.Component {
  //AHDA 15/04/2019
  //Added permissionInterval, chkViewProfile
  state = {
    openMenu: null,
    notifications: [], 
    permissionInterval: null, 
    chkViewProfile: false
  };

  // ------------------------------ Menu
  handleMenu = menu => {
    this.setState({
      openMenu: this.state.openMenu === menu ? null : menu
    });
  };

  handleCloseToggle = () => {
    this.setState({ openMenu: null });
  };

  handleLogOut = () => {
    logOut();
  }

  goToProfile = () => {
    this.props.history.push('/profile');
  }
  // ------------------------------ End Menu
  
  //Notifications
  handleUpdateItem = (item) => {

    const { type } = item;
    let typeId = notifTypes.findIndex(t => t === type);

    apiPOST('/notifications/update', { idNotificacion: item.id_notificaciones }).then(
      response => {
        if (response.status === 200) {
          const notifications = this.state.notifications
          const index = notifications.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
          notifications.splice(index, 1);
          this.setState({ notifications: notifications }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        } else {
          this.setState({ openError: true }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        });
  }


  handleUpdatenotifications = () => {

    const request = {
      id_usuario: returnCurrentUserId(),
      portal: "obras",
      stat: 0
    }
         
    apiPOST('/notifications/get', request, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ notifications: response.data })
        } else {
          this.setState({ openError: true });
        }
    }).catch(
      error => {
        console.log(error)
    });

  }

  //AHDA 15/04/2019
  //Added method for getting permissions, temporal solution 
  //AHDA 23/04/2019
  //Commented method 
  /*handleGetPermissions = () => {
    let config = localStorage.getItem("user_permissions"); 
    if(config){
      console.log("Configuration: ", config); 
      config = JSON.parse(config).filter(x => x.menu_id === 12);
      if(config.length > 0){
        config = config[0]; 
        let configPermission = JSON.parse(config.grant_configuration);
        
        this.setState({
          chkViewProfile: configPermission.chkViewProfile
        });
      }else{
        this.setState({
          chkViewProfile: false
        });
      }
    }
  }*/

  //Lifecycle 
  componentDidMount() {

    //AHDA 15/04/2019
    //Interval for getting profile permissions, temporal solution 
    let permissionInterval = setInterval(() => {
      let config = localStorage.getItem("user_permissions"); 
      if(config){
        config = JSON.parse(config).filter(x => x.menu_id === 12);
        if(config.length > 0){
          config = config[0]; 
          let configPermission = JSON.parse(config.grant_configuration);
          
          this.setState({
            chkViewProfile: configPermission.chkViewProfile
          });
        }else{
          this.setState({
            chkViewProfile: false
          });
        }

        clearInterval(permissionInterval); 
      }
    }, 5000); 
    

    //Obteniendo notificaciones
    const request = {
      id_usuario: returnCurrentUserId(),
      portal: "obras",
      stat: 0
    }

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/notifications/get', request, true).then(
      response => {
        
        if (response.status === 200) {
          this.setState({ notifications: response.data })
        } else {
          this.setState({ openError: true });
        }
    }).catch(
      error => {
        this.setState({ openError: true, isLoading: false });
    });

    setInterval(this.handleUpdatenotifications, 30000);
  }

  render() {
    const { classes } = this.props;
    const { openMenu } = this.state;
    const user = JSON.parse(localStorage.getItem('current_user'));

    const getNotification = (notification, idx) => {

      switch (notification.type) {
        case "newWorkRegister":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LibraryAdd />
              </Avatar>
              <ListItemText primary={`La empresa/proveedor '${notification.productorObras}' ha registrado una nueva obra '${notification.titulo_original}' en el portal para su evaluación`} secondary="Registro de obra" />
            </div>
          </MenuItem>
        case "workEvaluationAssign":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LocalLibrary />
              </Avatar>
              <ListItemText primary={`Se le ha asignado la obra '${notification.titulo_original}' para su evaluación`} secondary="Asignación de obra" />
            </div>
          </MenuItem>
        case "additionalMaterialRequest":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <NoteAdd />
              </Avatar>
              <ListItemText primary={`Se requiere de material adicional para continuar con la evaluación de la obra '${notification.titulo_original}'`} secondary="Solicitando material adicional" />
            </div>
          </MenuItem>
        case "additionalMaterialReception":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <PlaylistAddCheck />
              </Avatar>
              <ListItemText primary={`Se recibió material complementario de la obra '${notification.titulo_original}'`} secondary="Recibiendo material complementario" />
            </div>
          </MenuItem>
        case "dueTime":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Alarm />
              </Avatar>
              <ListItemText primary={`El tiempo para emitir el dictamen de la obra '${notification.titulo_original}' está a punto de finalizar.`} secondary="Faltan 5 días naturales." />
            </div>
          </MenuItem>
        case "evaluationAssessment":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Check />
              </Avatar>
              <ListItemText primary={`Se ha finalizado la evaluación de la obra '${notification.titulo_original}'`} secondary="Evaluación finalizada" />
            </div>
          </MenuItem>
        case "purchaseAuthorization":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <AttachMoney />
              </Avatar>
              <ListItemText primary={`Se autorizó la compra de la obra '${notification.titulo_original}'`} secondary="Compra autorizada" />
            </div>
          </MenuItem>
        //AHDA 08/05/2019
        //Added legalDocumentsExpiration
        case "legalDocumentsExpiration":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <SimCardAlert />
              </Avatar>
              <ListItemText primary={`La obra '${notification.titulo_original}' tiene documentos que están por vencer`} secondary="Documentos por vencer" />
            </div>
          </MenuItem>
        //AHDA 08/05/2019
        //Added legalDocumentsExpiration
        case "registerWorkTime":
          return <MenuItem key={idx} onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <FastForward />
              </Avatar>
              <ListItemText primary={`Ha expirado el tiempo para avanzar la obra '${notification.titulo_original}'`} secondary="Tiempo excedido" />
            </div>
          </MenuItem>
        case "additionalMaterial_userProvider":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
          <div className="messageInfo">
            <Avatar className="icon">
              <FastForward />
            </Avatar>
            <ListItemText primary={`Solicitud de material adicional de la obra '${notification.titulo_original}'`} secondary="Solicitud de material" />
          </div>
        </MenuItem>
        case "notViable_userProvider":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
          <div className="messageInfo">
            <Avatar className="icon">
              <FastForward />
            </Avatar>
            <ListItemText primary={`Estatus de la obra '${notification.titulo_original}': No viable `} secondary="Propuesta no viable" />
          </div>
        </MenuItem>
        case "preevaluation_userProvider":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
          <div className="messageInfo">
            <Avatar className="icon">
              <FastForward />
            </Avatar>
            <ListItemText primary={`Estatus de la obra '${notification.titulo_original}': Preevaluación `} secondary="Propuesta enviada a la etapa de evaluación" />
          </div>
        </MenuItem>
        case "viable_userProvider":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <FastForward />
              </Avatar>
              <ListItemText primary={`Estatus de la obra '${notification.titulo_original}': Viable `} secondary="Propuesta viable" />
            </div>
          </MenuItem>
        case "evaluatorSpecialistFile":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif}>
            <div className="messageInfo">
              <Avatar className="icon">
                <NoteAdd />
              </Avatar>
              <ListItemText primary={`El especialista evaluador ha cargado su dictamen`} secondary="Especialista evaluador" />
            </div>
          </MenuItem>
        default:
          break;
      }
    }

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.userMenu}>
              {/* ----- Notification Dropdown Menu ----- */}
              <Manager>
                <Target>
                  <div
                    ref={node => {
                      this.target1 = node;
                    }}
                  >
                    {/* GEO 01/04/2019
                  Se agrega tooltip a boton de notificaciones */}
                    <Tooltip title="Notificaciones">
                      <IconButton
                        aria-haspopup="true"
                        onClick={() => this.handleMenu('notification')}
                        color="inherit"
                      >
                        <Badge className={classes.badge} badgeContent={`${this.state.notifications.length}`} color="secondary">
                          <Notification />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Target>
                <Popper
                  placement="bottom-start"
                  eventsEnabled={openMenu === 'notification'}
                  className={classNames({ [classes.popperClose]: openMenu !== 'notification' }, classes.notifZindex)}
                >
                  <ClickAwayListener onClickAway={this.handleCloseToggle}>
                    <Grow in={openMenu === 'notification'} id="menu_notification" style={{ transformOrigin: '0 0 0' }}>
                      <Paper className={classes.paper}>
                        <MenuList role="menu" className={classes.notifMenu}>
                          {/* JCMY 01/04/19 */}
                          {/* AHDA 27/95/2019: Changed component for empty */}
                          { 
                            this.state.notifications.length > 0 ? 
                            (
                              this.state.notifications.map((notification, idx) => 
                                (getNotification(notification, idx)))
                            ) : 
                            <MenuItem>
                              <div className="messageInfo">
                                <ListItemText primary={"No cuentas con notificaciones"} secondary="" />
                              </div>
                            </MenuItem>  
                          }
                          
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                </Popper>
              </Manager>
              {/* ----- End Notification Dropdown Menu ----- */}
              {/* ----- User Setting Dropdown Menu ----- */}
              <Manager>
                <Target>
                  <div
                    ref={node => {
                      this.target1 = node;
                    }}
                  >
                    {/* CH 15/04/2019</Tooltip></Tooltip>
                    Se agrega tooltip a boton de notificaciones */}
                    <Tooltip title="Perfil">
                        {/*AHDA 02/04/2019
                        Imagen por default*/}
                        <Button onClick={() => this.handleMenu('user-setting')}>
                          <Avatar
                            alt=""
                            src={user ? "" : user.rdsUser.key ? "" : "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"}
                            className={classNames(classes.borderUser, classes.unsetFlex)}>
                            {
                              !user ? "" : !user.rdsUser.key ? "" : <S3Image theme={{ photoImg: { width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={user.rdsUser.key}/> 
                            }
                          </Avatar>
                        </Button>
                    </Tooltip>
                  </div>
                </Target>
                <Popper
                  placement="bottom-start"
                  eventsEnabled={openMenu === 'user-setting'}
                  className={classNames({ [classes.popperClose]: openMenu !== 'user-setting' })}
                >
                  <ClickAwayListener onClickAway={this.handleCloseToggle}>
                    <Grow in={openMenu === 'user-setting'} id="user_settint" style={{ transformOrigin: '0 0 0' }}>
                      <Paper className={classes.paper}>
                        <MenuList role="menu">
                          {/*AHDA 15/04/2019: Validation for permissions*/}
                          {
                            this.state.chkViewProfile && 
                              <MenuItem onClick={this.goToProfile} onTouchEnd={this.goToProfile}>
                                <ListItemIcon>
                                  <Icon>account_circle</Icon>
                                </ListItemIcon>
                                {lang.common.profile}
                              </MenuItem>
                          }
                          {
                            this.state.chkViewProfile && 
                            <Divider />
                          }
                          <MenuItem onClick={this.handleLogOut} onTouchEnd={this.handleLogOut}>
                            <ListItemIcon>
                              <Icon>lock</Icon>
                            </ListItemIcon>
                            {lang.common.logOut}
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                </Popper>
              </Manager>
              {/* ----- End User Setting Dropdown Menu ----- */}
            </div>
        }
      </LanguageConsumer>

    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserMenu));
