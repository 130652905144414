const acceptedMaterialTypes = {
  'img': [
    "image/jpeg",
    "image/png",
    "image/jpg",
    'application/x-rar-compressed',
    'application/x-rar',
    'application/zip',
    'application/x-zip-compressed',
    "",
  ],
  'video': [
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'application/x-rar',
    'application/x-rar-compressed',
    'application/zip',
    'application/x-zip-compressed',
    ""
  ],
  'trailer': [
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'application/x-rar',
    'application/x-rar-compressed',
    'application/zip',
    'application/x-zip-compressed',
    ""
  ],
  'libreto': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/x-rar',
    'application/x-rar-compressed',
    'application/zip',
    'text/plain',
    'application/x-zip-compressed',
    ""
  ],
  'escaleta': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    "image/jpeg",
    "image/png",
    "image/jpg",
    'application/x-rar-compressed',
    'application/x-rar',
    'application/x-zip-compressed',
    'application/zip',
    ""
  ],
  'capitulo1': [
    'audio/mpeg',
    'audio/mp4',
    'audio/vnd.wav',
    'audio/mp3',
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'application/x-rar',
    'video/x-ms-wmv',
    'application/x-rar-compressed',
    'application/zip',
    'application/x-zip-compressed',
    ""
  ],
  'biblia': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/x-rar',
    'application/pdf',
    'application/x-rar-compressed',
    'application/zip',
    'application/x-zip-compressed',
    'text/plain',
    ""
  ],
  'brochure': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/x-rar-compressed',
    'application/x-rar',
    'application/zip',
    'text/plain',
    'application/x-zip-compressed',
    ""
  ],
  'sinopsisCorta': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/x-rar-compressed',
    'application/x-rar',
    'application/zip',
    'application/x-zip-compressed',
    'text/plain',
    ""
  ],
  'sinopsisLarga': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/x-rar',
    'application/x-zip-compressed',
    'application/x-rar-compressed',
    'application/zip',
    'text/plain',
    ""
  ],
  'diagrama relaciones': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'image/jpeg',
    'image/png',
    'application/x-rar',
    'image/jpg',
    'application/x-rar-compressed',
    'application/zip',
    'application/x-zip-compressed',
    ""
  ],
  'otro': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/x-rar-compressed',
    'application/zip',
    'audio/mpeg',
    'audio/mp4',
    'audio/vnd.wav',
    'audio/mp3',
    'application/x-rar',
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'application/x-zip-compressed',
    ""
  ],
  'perfiles personajes': [
    "application/pdf", 
    "application/msword", 
    "application/vnd.ms-powerpoint", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ],
  'sinopsis10': [
    "application/pdf", 
    "application/msword", 
    "application/vnd.ms-powerpoint", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ],
  'default': [
    "application/pdf", 
    "application/msword", 
    "application/vnd.ms-powerpoint", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ]
}

export default acceptedMaterialTypes;
