import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import styles from './../reportes-jss'
import { textLabelsEs } from '../../../../utils/tableTexts';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';

//Auxiliars 
import { convertToStringArray } from '../../../../utils/helpers';

//Notifications  
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Iconos 
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

//services 
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base'
import Tooltip from '@material-ui/core/Tooltip';

//Keys to convert object to array 
const keys = ['proveedor', 'tituloObra', 'pais', 'tipoContrato', 'inicioCompra', 'entregaDocs',
  'diasTrancurridos', 'estatusDocumentacion', 'documentosFaltantes'];

class AdvFilter extends React.Component {
  state = {
    //Filters
    pais: 0,
    estatusDoc: 0,
    proveedor: 0,
    fechaInicio: null,
    fechaFin: null,
    //Table
    columns: [],
    data: [],
    //Catalogues
    countries: [],
    empresas: [],
    //Notifications
    open: false,
    errorMessage: "errMsg",
    //Loading
    loading: false,
    dataCsV: []
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      pais: 0,
      estatusDoc: 0,
      proveedor: 0,
      fechaInicio: null,
      fechaFin: null,
      loading: true
    });

    let body = {
      fechaInicio: 0,
      fechaFin: 0,
      proveedor: 0,
      estatusDocumentacion: 0,
      pais: 0
    }
    this.handleFetch(body);
  }

  //Notifications 
  handleClose = () => {
    this.setState({ open: false });
  }

  //Date Pickers
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Download csv
  handleDownload = (data) => {
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteEstatusDocumentacion'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    const dataCsv = [["Proveedor", "Título de la Obra", "País", "Tipo de Contrato", "Inicio de compra", "Entrega de docs", "Días transcurridos", "Documentación", "Documentación Faltante"]]

    for (const x in data) {
      const object = []
      object.push(data[x]);
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Estatusdocumentación .csv";
    download.click();

  }

  //Search
  handleSearch = event => {
    event.preventDefault();

    const fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    const ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin);

    let body = {
      fechaInicio: fi === 0 ? 0 : fi.getFullYear() + "-" + (fi.getMonth() + 1) + "-" + fi.getDate(),
      fechaFin: ff === 0 ? 0 : ff.getFullYear() + "-" + (ff.getMonth() + 1) + "-" + ff.getDate(),
      proveedor: this.state.proveedor,
      estatusDocumentacion: this.state.estatusDoc,
      pais: this.state.pais
    }

    const ds = new Date(fi);
    const df = new Date(ff);

    if (ds.getTime() > df.getTime()) {
      this.setState({ open: true, loading: false, errorMessage: "dateError" });
    } else {
      this.setState({ loading: true });
      this.handleFetch(body);
    }
  }

  componentDidMount() {
    this.setState({ loading: true });

    const { lang } = this.context;
    this.setState(
      {
        columns: [lang.reports.provider, lang.reports.title, lang.reports.country, lang.reports.contractType,
        lang.reports.purchaseStart, lang.reports.docsDelivery, lang.reports.daysPassed, lang.reports.documentation,
        lang.reports.missingDoc]
      }
    )

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ countries: response.data })
        }
      })

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 7 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ empresas: response.data })
        }
      })

    let body = {
      fechaInicio: 0,
      fechaFin: 0,
      proveedor: 0,
      estatusDocumentacion: 0,
      pais: 0
    }
    this.handleFetch(body);
  }

  handleFetch(body) {
    apiPOST('/reportes/estatusDocumentacion', body).then(
      response => {
        if (response.status === 200) {

          //JCMY 05/02/19
          const result = [];
          for (const x in response.data) {
            var obj = [];

            //AHDA 18/06/2019: Validaitons for nulls
            const item = response.data[x]; 
            obj.push(item.proveedor ? item.proveedor : "-");
            obj.push(item.tituloObra ? item.tituloObra : "-");
            obj.push(item.pais ? item.pais : "-");
            obj.push(item.tipoContrato ? item.tipoContrato : "-");
            obj.push(item.inicioCompra ? item.inicioCompra : "-");
            // EGS 24/04/2019 - Evitar nulls evitando tronar el multiselect de los filtroos 
            obj.push(item.entregaDocs ? item.entregaDocs : "-");
            obj.push(item.diasTrancurridos ? item.diasTrancurridos : "-");
            //AHDA 07/05/2019
            //Validation for value
            obj.push(item.estatusDocumentacion == null?  "-" : item.estatusDocumentacion == 1 ? "Incompleta" : "Completa");
            obj.push(item.documentosFaltantes? item.documentosFaltantes :  "-");
            result.push(obj);
          }
          this.setState({ data: result, loading: false, dataCsV: result });
        }
        else {
          this.setState({ open: true, loading: false, errorMessage: "errMsg" });
        }
      }
    ).catch(
      err => {
        console.log(err);
        this.setState({ open: true, loading: false, errorMessage: "errMsg" });
      }
    );
  }

  render() {
    const { classes } = this.props
    const { columns, data, fechaInicio, fechaFin } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.dataCsV)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title={lang.reports.reports} desc="">
                {this.state.loading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                <form className={classes.container} onSubmit={this.handleSearch}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={3} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={'fr'}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={'fr'}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          minDate={fechaInicio}
                          clearable={true}
                          disabled={fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.proveedor} onChange={this.handleChange} name="proveedor" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.empresas.map((empresa, idx) => (
                              <MenuItem value={empresa.value} key={`empresa${idx}`}>
                                {empresa.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.compProv}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select
                          value={this.state.estatusDoc}
                          onChange={this.handleChange}
                          name="estatusDoc"
                          displayEmpty
                          className={classes.selectEmpty}
                          inputProps={{ name: 'estatusDoc', id: 'filter-pais' }}
                        >
                        {/*AHDA 07/05/2019: Changed value*/}
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          <MenuItem value={2}>{lang.reports.complete}</MenuItem>
                          <MenuItem value={1}>{lang.reports.incomplete}</MenuItem>
                        </Select>
                        <FormHelperText>{lang.reports.docStatus}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select
                          value={this.state.pais}
                          onChange={this.handleChange}
                          name="pais"
                          className={classes.selectEmpty}
                          inputProps={{ name: 'pais', id: 'filter-pais' }}
                        >
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.countries.map((country, idx) => (
                              <MenuItem value={country.value} key={`country${idx}`}>
                                {country.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.country}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button variant="contained" color="primary" className={classes.button} type="submit">
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.documentacion}>
                  <MUIDataTable
                    title={`${lang.reports.repDocumentationState} (${this.state.data.length} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common[this.state.errorMessage]} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

export default withStyles(styles)(AdvFilter);
