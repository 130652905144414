import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import styles from './../reportes-jss';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';
import { textLabelsEs } from '../../../../utils/tableTexts';

//Filtros
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';

import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import moment from 'moment';

class BitacoraMovimientos extends React.Component {
  state = {
    //filters
    fechaInicio: null,
    fechaFin: null,
    accion: 0,
    user: '',
    //Table
    columns: [],
    data: [],
    //Autocomplete
    users: [],
    actions: [],
    isLoading: true,
    results: 0,
    openError: false,
    errorMessage: "errMsg",
    actionsTranslated: []
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  //DATES
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Innputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ user: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ user: changes.inputValue })
    }
  }

  //Clear filters 
  //AHDA 19/02/2019
  handleClearFilters = () => {

    this.setState({
      fechaInicio: null,
      fechaFin: null,
      accion: 0,
      user: '',
      isLoading: true
    });

    const request = {
      start_date: "0",
      end_date: "0",
      action: 0,
      user: "0",
      portal: "obras"
    }

    this.handleFetch(request);
  }


  //Notifications
  handleCloseError = () => {
    this.setState({ openError: false });
  }

  // JCMY 22/01/19
  //Llamada de busqueda
  searchs = () => {

    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      action: this.state.accion,
      user: this.state.user === "" ? "0" : this.state.user === "TODOS" ? "0" : this.state.user,
      portal: "obras"
    }

    // JCMY 08/02/19
    const ds = new Date(fi);
    const df = new Date(ff);

    if (ds.getTime() > df.getTime() || ds === "Invalid Date") {
      this.setState({ openError: true, isLoading: false, errorMessage: "dateError" });
    } else {
      this.setState({ isLoading: true })
      this.handleFetch(request);
    }
  }

  //JCMY 24/01/18
  componentDidMount() {
    //Lang 
    const { lang } = this.context;
    this.setState({ columns: [{
      name: "dateFormat",
      label: lang.reports.date,
      options: {
        customBodyRender: value =>
          moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
      }
    }, 
    lang.reports.user, lang.reports.employee, lang.reports.action, lang.reports.description] });

    //Catalogo usuarios
    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 14 }, true).then(response => {
      if (response.status === 200) {
        this.setState({ users: response.data })
      }
    });

    //Catalogo acciones
    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 20 }, true).then(response => {
      if (response.status === 200) {
        this.setState({ actions: response.data })
        let obj = [];
        this.state.actions.map((action, idx) => (
          // <MenuItem value={action.value} key={`action${idx}`}>{lang.actionTypesCatalogue[action.data]}</MenuItem>
          obj.push({
            value: action.value,
            data: lang.actionTypesCatalogue[action.data]
          })
        ));

        obj.sort(this.dynamicSort("data"));

        this.setState({
          actionsTranslated: obj
        })

      }
    })



    //Carga inicial
    const request = {
      start_date: "0",
      end_date: "0",
      action: 0,
      user: "0",
      portal: "obras"
    }
    this.handleFetch(request);
  }

  //AHDA 19/02/2019
  handleFetch = (request) => {
    //GetLanguage context
    const { lang } = this.context;

    apiPOST('/reportes/getLog', request).then(response => {
      if (response.status === 200) {

        const result = [];
        for (const x in response.data) {
          var obj = [];
          //AHDA 10/05/2019
          //Added validations
          const item = response.data[x];
          obj.push(item.date ? item.date : "-");
          obj.push(item.user ? item.user : "-");
          obj.push(item.id_empleado ? item.id_empleado : "-");
          obj.push(lang.actionTypesCatalogue[item.action] ? lang.actionTypesCatalogue[item.action] : "-");
          const description =
            `Pantalla: ${lang.screens[item.screen] ? lang.screens[item.screen] : " - "}; Acción: ${lang.actionTypesCatalogue[item.action]}; ${item.title_work ? `Obra: ${item.title_work};` : ""} Usuario: ${item.user ? item.user : "-"};  Fecha y hora: ${moment(new Date(response.data[x].date)).format("DD/MM/YYYY hh:mm:ss")}`;
          obj.push(description);
          result.push(obj);
        }
        this.setState({ data: result, isLoading: false, results: response.data.length })
      } else {
        this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
      }
    }).catch(error => {
      this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
    });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: true,
      downloadOptions: {
        filename: `${this.context.lang.reports.repMovementLog}.csv`,
        separator: ','
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const body = {
          id_usuario: returnCurrentUserId(),
          user_action: 3,
          work_id: 0,
          portal: 'obras',
          screen: 'reporteBitacoraMovimientos'
        };
        apiPOST('/audit/add', body);
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs
    };
    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {this.state.isLoading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={3} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          // helperText={lang.reports.compDateEnter}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          minDate={this.state.fechaInicio}
                          disabled={this.state.fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <div className={classes.root}>
                        <Downshift selectedItem={this.state.user} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                            <div className={classes.formControl}>
                              {renderInput({
                                fullWidth: true,
                                classes,
                                // helperText: lang.reports.company,
                                helperText: lang.reports.user,
                                InputProps: getInputProps({
                                  // placeholder: lang.reports.company,
                                  placeholder: lang.reports.user,
                                  id: 'user',
                                  name: 'user',
                                }),
                              })}
                              {isOpen ? (
                                <Paper className={classes.paper} square>
                                  {getSuggestions(inputValue, this.state.users.filter(e => e.label !== "")).map((suggestion, index) =>
                                    renderSuggestion({
                                      suggestion,
                                      index,
                                      itemProps: getItemProps({ item: suggestion.label }),
                                      highlightedIndex,
                                      selectedItem,
                                    }),
                                  )}
                                </Paper>
                              ) : null}
                            </div>
                          )}
                        </Downshift>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.accion} onChange={this.handleChange} name="accion" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.actionsTranslated.map((action, idx) => (
                              <MenuItem value={action.value} key={`action${idx}`}>{action.data}</MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.action}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.bitacoraMov}>
                  <MUIDataTable
                    title={`${lang.reports.repMovementLog} (${this.state.results} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/* JCMY 08/02/19 */}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleCloseError}>
                  <CustomNotification
                    onClose={this.handleCloseError}
                    variant="error"
                    message={lang.common[this.state.errorMessage]} />
                </Snackbar>
              </PapperBlock>
          }
        </LanguageConsumer>

      </div>
    );
  }
}

BitacoraMovimientos.contextType = LanguageCxt;

export default withStyles(styles)(BitacoraMovimientos);