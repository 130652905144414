import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import ContactHeader from '../../../../components/Contact/ContactHeader';
import ContactList from '../../../../components/Contact/ContactList';
import ContactDetail from '../../../../components/Contact/ContactDetail';
import AddContact from '../../../../components/Contact/AddContact';
import LoaderFull from '../../../../components/Loader/LoaderFull'; 

import styles from '../../../../components/Contact/contact-jss';
//import {getUser} from '../../../../services/apiObras'; 

//Services 
import { apiPOST, returnCurrentUserId, updateCurrentUser } from '../../../../services/service_base'; 
import { saveUserPhoto } from '../../../../services/storage'; 

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

class Contact extends React.Component {
 
  state = {
    open : false,
    close: false,
    selectedUser: 1,
    selectedData : null,
    searchData:[], 
    roles: [],
    //Errors 
    errType: "error", 
    errMessage: "", 
    openError: false, 
    //Loading 
    isLoading: false, 
    isFullLoading: false,
    chkBlockUser: false,
    chkDeleteUser: false,
    chkEditUser: false,
    chkUnlockUser: false,
    chkViewUser: false
  }

  //Notifications 
  handleCloseError = () => {
    this.setState({openError: false}); 
  }

  //Show detail
  showDetail = (data) => {
    this.setState({ selectedData: data })
  }

  //Search
  search = (event) => {
    let key = event.target.value.toUpperCase(); 
    
    //Get all
    if(key === ""){
      this.setState({searchData: [...this.state.originalData]}); 
      return false; 
    }

    let auxData = [...this.state.originalData]; 
    let resData =[]
    resData= auxData.filter(user => {
        if(user.nombre && user.portal_profile){
            return user.nombre.toUpperCase().indexOf(key) > -1 || user.portal_profile.toUpperCase().indexOf(key) > -1;
        }else{
            return user.portal_profile.toUpperCase().indexOf(key) > -1;
        }
    }); 
    this.setState({searchData: resData}); 
  }

  clearSearch = () => {
    this.setState({searchData: [...this.state.originalData]});
  }

  ////////////////////// Edit
  handleDropErrors = (message) => {
    this.setState({
        isLoading: false, 
        openError: true, 
        errType: "warning", 
        errMessage:message}); 
  }

  submitContact = (item, avatar) => {

    //TODO: validate if new image
    let errFile = false; 
    
    this.setState({ isFullLoading: true });
    if(avatar.length > 0){
        saveUserPhoto(avatar[0], item).then(response => {
            item.avatar = response.key; 
        }).catch(err => {
            errFile = true; 
        }).finally(
            () => {
                this.editUser(item, errFile);
            }
        )
    }else{
        this.editUser(item);
    } 
  }

  _closeAddUser = () => {
    this.setState({open:false});
  }

  edit = (data) => {
    this.setState({ open: true, selectedData: data });
  }

  editUser = (item, errFile) => {
    let body = {
        user_id : item.id_usuario, 
        new_name : item.nombre,
        new_last_name : item.apellidos,
        new_rol : item.id_rol,
        new_status : item.estatus,
        new_position : item.puesto,
        new_bussiness_unit : item.unidad_negocio,
        new_portrait: item.avatar,
        user_who_updates: returnCurrentUserId(),
        portal: 'obras',
        screen: 'configurationUsers'
    }

    apiPOST('/users/update', body).then(
        response => {
            if(response.status === 200){

                //If user is editing its profile, refresh
                if(item.id_usuario === returnCurrentUserId()){
                    //Change refresh to a listener later 
                    if(updateCurrentUser(item)){
                        window.location.reload(); 
                    }
                }

                //TODO: replace new user in arrays 
                let auxSearchData = [...this.state.searchData], auxOriginalData = [...this.state.originalData]; 
                let auxIndex = auxSearchData.findIndex(u => u.id_usuario === item.id_usuario), 
                    auxOriginalIndex = auxOriginalData.findIndex(u => u.id_usuario === item.id_usuario); 
                
                if(auxIndex > -1){
                    auxSearchData[auxIndex] = item; 
                }

                if(auxOriginalIndex > -1){
                    auxOriginalData[auxOriginalIndex] = item; 
                }

                // JCMY 27/03/2019
                // Se actualiza data para mostrar el nombre del rol
                const rolesList = this.state.roles;

                const objRole = rolesList.find(obj => obj.value === body.new_rol)
                item.portal_profile = objRole.data
                


                this.setState({
                    open: false,
                    isFullLoading : false, 
                    searchData: auxSearchData, 
                    originalData: auxOriginalData, 
                    selectedData: item, 
                    openError: true, 
                    errType: errFile ? "error" : "success", 
                    errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
                });
            }else{
                this.setState({
                    open: false,
                    isFullLoading: false,
                    openError: true, 
                    errType: errFile ? "error" : "success", 
                    errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
                }); 
            }
        }
    ).catch(
        err => {
            this.setState({
                open: false, 
                isFullLoading: false,
                openError: true, 
                errType: errFile ? "error" : "success", 
                errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
            }); 
        }
    )
  }

  ///////////////////// End Edit 
  
  //Block User
  handleBlockUser = (data, newEstatus) => {
    
    let body = {
        user_id: data.id_usuario,
        user_status: newEstatus, 
        user_who_updates: returnCurrentUserId(), 
        portal: "obras", //AHDA 10/05/2019
        screen: "configurationUsers"
    }
    
    this.setState({isFullLoading: true}); 

    apiPOST('/users/block', body).then(
        response => {
            if(response.status === 200){
                //TODO: replace user in arrays with new status and selected user 
                const bodyAudit = {
                    id_usuario: returnCurrentUserId(),
                    user_action:newEstatus==1? 21 : 20,//0 block, 1 unlock
                    work_id: 0,
                    portal: 'obras',
                    screen: 'login'
                }
                apiPOST('/audit/add', bodyAudit);
 
                let auxSearchData = [...this.state.searchData], auxOriginalData = [...this.state.originalData]; 
                let auxIndex = auxSearchData.findIndex(u => u.id_usuario === data.id_usuario), 
                    auxOriginalIndex = auxOriginalData.findIndex(u => u.id_usuario === data.id_usuario); 
                
                if(newEstatus === 2){
                  if(auxIndex > -1){
                    auxSearchData.splice(auxIndex, 1); 
                  }

                  if(auxOriginalIndex > -1){
                      auxOriginalData.splice(auxOriginalIndex, 1);
                  }
                }else{
                  if(auxIndex > -1){
                    auxSearchData[auxIndex].estatus = body.user_status;
                  }

                  if(auxOriginalIndex > -1){
                      auxOriginalData[auxOriginalIndex].estatus = body.user_status;
                  }
                }

                this.setState({
                    isFullLoading : false, 
                    searchData: auxSearchData, 
                    originalData: auxOriginalData, 
                    selectedData: newEstatus === 2 ? null : data, 
                    openError: true, 
                    errType: "success", 
                    errMessage: newEstatus === 2 ? "Se elimino el usuario" : "Se actualizaron los datos del usuario"
                });
            }else{
                this.setState({
                    isFullLoading: false, 
                    openError: true, 
                    errType: "error", 
                    errMessage:"Algo salio mal, intenta de nuevo mas tarde"});
            }
        }
    ).catch(
        err => {
            console.log(err); 
            this.setState({
                isFullLoading: false, 
                openError: true, 
                errType: "error", 
                errMessage:"Algo salio mal, intenta de nuevo mas tarde"});           
        }
    )
  }

  //Life cycle 
  componentDidMount() {
    let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 14)[0];
    let configPermission = JSON.parse(config.grant_configuration);
    
    this.setState({ 
      isLoading: true ,
      chkBlockUser: configPermission.chkBlockUser,
      chkDeleteUser: configPermission.chkDeleteUser,
      chkEditUser: configPermission.chkEditUser,
      chkUnlockUser: configPermission.chkUnlockUser,
      chkViewUser: configPermission.chkViewUser
    });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', {idCatalogo: 34}, true).then(response => {
        if(response.status === 200){
            this.setState({roles: response.data}); 
        }
    }); 

    //Get initial data 
    apiPOST('/users/getAll', {"portal" : 2}).then(
        response => {
            console.log("Usuarios",response); 
            if(response.status === 200){
                //Todo: map data
                this.setState({searchData: response.data, originalData: response.data, isLoading: false});
            }else{
                this.setState({
                    isLoading: false, 
                    openError: true, 
                    errType: "error", 
                    errMessage:"Algo salió mal, intenta de nuevo más tarde"});           
            }
        }
    ).catch(
        err => {
            this.setState({
                isLoading: false, 
                openError: true, 
                errType: "error", 
                errMessage:"Algo salió mal, intenta de nuevo más tarde"}); 
        }
    )
  }
  
  render() {
    const title = brand.name + ' - Contact';
    const description = brand.desc;
    const {
      classes,
      hideDetail,
      showMobileDetail
    } = this.props;
    return (
      <div className="marginTopLogo">
        {
            this.state.isFullLoading ? <LoaderFull /> : null
        }

        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        {/* <Notification close={() => closeNotif()} message={messageNotif} /> */}
        <div className={classes.root}>
            {/*AHDA 31/05/2019*/}
            <ContactHeader
                hideDetail={hideDetail}
                total={this.state.searchData.length}
                showMobileDetail={showMobileDetail}
                title={"Usuarios"}
            />
            
            <ContactList
                clippedRight
                itemSelected={this.state.selectedUser}
                dataContact={this.state.searchData}
                showDetail={this.showDetail}
                search={this.search}
                isLoading={this.state.isLoading}
                clearFilter={this.clearSearch}
            />
            
            <ContactDetail
                showMobileDetail={showMobileDetail}
                dataContact={this.state.searchData}
                edit={this.edit}
                item={this.state.selectedData}
                blockUser={this.handleBlockUser}
                canEdit={this.state.chkEditUser}
                canBlock={this.state.chkBlockUser}
                canUnblock={this.state.chkUnlockUser}
                canDelete={this.state.chkDeleteUser}
            />
        </div>

        {
            this.state.selectedData ? 
            <AddContact
                openForm={this.state.open}
                closeForm={this._closeAddUser}
                submit={this.submitContact}
                dataInit={this.state.selectedData}
                rolesCatalogue={this.state.roles}
                dropValidations={this.handleDropErrors}
            /> : null
        }

        {/*For notifications*/}
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={this.state.openError}
            autoHideDuration={9000}
            onClose={this.handleCloseError}>
            <CustomNotification
                onClose={this.handleCloseError}
                variant={this.state.errType}
                message={this.state.errMessage} />
        </Snackbar>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object,
  avatarInit: PropTypes.string,
  fetchData: PropTypes.func,
  showDetail: PropTypes.func,
  hideDetail: PropTypes.func,
  keyword: PropTypes.string,
  open: PropTypes.bool,
  showMobileDetail: PropTypes.bool,
  add: PropTypes.func,
  close: PropTypes.func,
  submit: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  favorite: PropTypes.func,
  search: PropTypes.func,
  dataContact: PropTypes.object,
  itemSelected: PropTypes.number,
  closeNotif: PropTypes.func,
  messageNotif: PropTypes.string,
};
 
export default withStyles(styles)(Contact);
