import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './../reportes-jss'
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import EventCalendar from '../../../../components/Calendar/EventCalendar';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';
import { textLabelsEs } from '../../../../utils/tableTexts';

//Filtros
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';

//Autocomplete
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';

import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';


//Styles
import '../../../../styles/components/vendors/react-big-calendar/react-big-calendar.css';

import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';

class FechasFlujosPago extends React.Component {
  state = {
    //Filters
    fechaInicio: null,
    fechaFin: null,
    work: '',
    //Table
    columns: [],
    data: [],
    dataCsv: [],
    //Autocomplete
    works: [],
    //View
    view: 'table',
    textIcon: 'Vista calendario',
    isLoading: true,
    eventData: [],
    results: 0,
    monthNames: [],
    openError: false,
    errorMessage: "errMsg"
  }

  //Notifications
  handleCloseError = () => {
    this.setState({ openError: false });
  }

  //Innputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ work: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ work: changes.inputValue })
    }
  }

  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      fechaInicio: null,
      fechaFin: null,
      work: '',
      isLoading: true
    });

    const request = {
      start_date: "0",
      end_date: "0",
      title: "0"
    }
    this.handleFetch(request);
  }

  //DATES
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Change view
  handleClick = () => {
    this.setState({
      view: this.state.view === 'table' ? 'calendar' : 'table',
      textIcon: this.state.textIcon === 'Vista calendario' ? 'Vista tabla' : 'Vista calendario'
    })
  }

  //Download csv
  handleDownload = (data) => {
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteFechasFlujosPago'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    const dataCsv = [["Empresa/Proveedor", "Autor / Nickname", "Título de la Obra", "Tipo de Contrato", "Importe del pago", "Total del contrato", "Moneda", "Fecha de pago"]]

    for (const x in data) {
      const object = []
      object.push(data[x]);
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Flujospago.csv";
    download.click();
  }

  // JCMY 25/01/19
  //Llamada de busqueda
  searchs = () => {
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      title: this.state.work === "" ? "0" : this.state.work === "TODOS" ? "0" : this.state.work
    }


    // JCMY 08/02/19
    const ds = new Date(fi);
    const df = new Date(ff);

    if (ds.getTime() > df.getTime()) {
      this.setState({ openError: true, isLoading: false, errorMessage: "dateError" });
    } else {
      this.setState({ isLoading: true });
      this.handleFetch(request);
    }
  }

  componentDidMount() {
    const { lang } = this.context;
    this.setState(
      {
        columns: [lang.reports.compProv, lang.reports.authors, lang.reports.title, lang.reports.contractType,
        lang.reports.paymentAmount, lang.reports.contactTotal, lang.reports.currency, lang.reports.paymentDate],
        monthNames: [lang.calendar.january, lang.calendar.february, lang.calendar.march, lang.calendar.april,
        lang.calendar.may, lang.calendar.june, lang.calendar.july, lang.calendar.august,
        lang.calendar.september, lang.calendar.october, lang.calendar.november, lang.calendar.december]
      }
    )

    //Catalogo obras
      //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 16 }, true).then(response => {
      if (response.status === 200) {
        this.setState({ works: response.data })
      }
    });

    //Carga Inicial
    const request = {
      start_date: "0",
      end_date: "0",
      title: "0"
    }
    this.handleFetch(request);
  };

  //AHDA 19/02/2019
  handleFetch = (request) => {
    apiPOST('/reportes/paymentFlow', request).then(response => {
      if (response.status === 200) {

        const result = [];
        const resultCsv = [];
        const dataCalendar = [];

        for (const x in response.data) {
          var obj = [];

          const item = response.data[x]; 

          //AHDA 13/05/2019
          //Added validations 
          obj.push(item.company ? item.company : '-');
          obj.push(item.autor ? item.autor : '-');
          obj.push(item.title ? item.title : '-');
          obj.push(item.contract_type ? item.contract_type : '-');
          obj.push(item.payment_amount ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.payment_amount) : '-');
          obj.push(item.total_payment ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total_payment) : '-');
          obj.push(item.currency ? item.currency : '-');
          obj.push(item.payment_date);
          result.push(obj);

          // JCMY 14/02/19
          var objCsv = [];
          objCsv.push(response.data[x].company);
          objCsv.push(response.data[x].autor);
          objCsv.push(response.data[x].title);
          objCsv.push(response.data[x].contract_type);
          objCsv.push(response.data[x].payment_amount);
          objCsv.push(response.data[x].total_payment);
          objCsv.push(response.data[x].currency);
          objCsv.push(response.data[x].payment_date);
          resultCsv.push(objCsv);

          const event = {};
          const f = (response.data[x].payment_date).split("/");

          event.id = response.data[x].id_obras;
          event.title = `${response.data[x].company} (${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(response.data[x].payment_amount)})`;
          event.start = new Date(f[2] + "-" + f[1] + "-" + f[0] + " 09:00:00");
          event.end = new Date(f[2] + "-" + f[1] + "-" + f[0] + " 18:00:00");
          event.payment_date = response.data[x].payment_date;
          event.work = response.data[x].title;
          event.authors = response.data[x].autor;
          event.provider = response.data[x].company;
          event.amount = (response.data[x].payment_amount);

          dataCalendar.push(event);
        }
        this.setState({ data: result, isLoading: false, eventData: dataCalendar, results: response.data.length, dataCsv: resultCsv })
      } else {
        this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
      }
    }).catch(error => {
      this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
    });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.dataCsv)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };
    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {this.state.isLoading ? <LoaderFull /> : ''}
                <Button color="secondary" className={classNames(classes.button, classes.botonFlotante)} onClick={this.handleClick}>
                  <Icon className={classNames(classes.leftIcon, classes.iconSmall)}>
                    {
                      this.state.view === 'table' ? 'event' : 'view_list'
                    }
                  </Icon>
                  {this.state.view === 'table' ? lang.reports.calendarView : lang.reports.tableView}
                </Button>
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={4} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          // helperText={lang.reports.evDateEnter}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          minDate={this.state.fechaInicio}
                          disabled={this.state.fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <div className={classes.root}>
                        <Downshift selectedItem={this.state.work} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                              <div className={classes.formControl}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  helperText: lang.reports.title,
                                  InputProps: getInputProps({
                                    id: 'work',
                                    name: 'work',
                                  }),
                                })}
                                {isOpen ? (
                                  <Paper className={classes.paper} square>
                                    {getSuggestions(inputValue, this.state.works).map((suggestion, index) =>
                                      renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion.label }),
                                        highlightedIndex,
                                        selectedItem,
                                      }),
                                    )}
                                  </Paper>
                                ) : null}
                              </div>
                            )}
                        </Downshift>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.bitacoraMov}>
                  {
                    this.state.view === 'table' ?
                      <MUIDataTable title={`${lang.reports.repPaymentFlows} (${this.state.results} ${lang.reports.results})`}
                        data={data} columns={columns}
                        options={options} /> :
                      <EventCalendar events={this.state.eventData} monthNames={this.state.monthNames} />
                  }
                </div>
                {/* JCMY 08/02/19 */}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleCloseError}>
                  <CustomNotification
                    onClose={this.handleCloseError}
                    variant="error"
                    message={lang.common[this.state.errorMessage]} />
                </Snackbar>
              </PapperBlock>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

FechasFlujosPago.contextType = LanguageCxt;

export default withStyles(styles)(FechasFlujosPago);
