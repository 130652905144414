import React, { Component } from 'react';
//import ls from 'local-storage';
import { logOutPromise, checkSession } from '../services/cognito_service'; 
import ReactTimeout from 'react-timeout'; 
import SweetAlert from 'sweetalert-react';
import '../styles/components/vendors/sweetalert-react/sweetalert.css'; 
import { withRouter } from 'react-router-dom'; 
import { withStyles } from '@material-ui/core';
import { styles } from './styles';

export default function withSession(WrappedComponent, options = {}){

    const checkLastActivity = () => {
        let currentTime = new Date().getTime(); 
        const lastTime = localStorage.getItem("last_activity"); 

        if(lastTime){
            let diff = (currentTime - lastTime)/1000; 
            diff = diff/60; 
            /*console.log("Last time: ", lastTime); 
            console.log("Current time: ", currentTime); 
            console.log("Last Activity: ", diff); */

            if(diff >= 30){
                return true; 
            }else{
                return false; 
            }
        }/*else{
            ls.set("last_activity", new Date().getTime());
            return false; 
        }*/
    }

    const timeoutTime = 1000 * 60 * 30; 

    const HOC = class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                show: false
            };
        }

        //Timeout 
        startTimeout = undefined; 

        componentWillMount(){
            checkSession();
        }

        componentDidMount(){
            //TODO: atach click listener  
            this.setTimeOut(); 
        }

        componentWillUnmount(){
            //TODO: detach click listener 
            this.stopTimeOut(); 
        }
        
        /*logOut = () => {
            //Check if there is a session 
            //console.log("--------------- Session finished"); 
            logOutPromise().then().catch(err => console.log(err)).finally(() => {
                this.setState({show: false}); 
                //this.props.history.push("/");
            }); 
        }*/

        setTimeOut = () => {
            //Set interval 
            this.startTimeout = this.props.setInterval(this.checkSession, timeoutTime); 
        }

        stopTimeOut = () => {
            if(this.startTimeout){
                //Clear timeout
                this.props.clearInterval(this.startTimeout); 
            }
        }

        checkSession = () => {
            const isExpired = checkLastActivity(); 
            if(isExpired){
                this.setState({show: true});
            }
        }

        handleConfirmSwal = () => {
            //this.props.history.push("/"); 
            logOutPromise().then().catch(err => console.log(err)).finally(() => {
                //this.setState({show: false}); 
                //this.props.history.push("/");
                window.location.href = "/"; 
            });
        }

        render() {
            const {classes} = this.props;
            return (<React.Fragment>
                <WrappedComponent {...this.props} />
                <SweetAlert
                    show={this.state.show}
                    customClass={classes.alertDialog}
                    title="Tu sesión ha expirado"
                    text="Debes volver a ingresar tus credenciales para seguir en el portal"
                    onConfirm={() => {
                        this.handleConfirmSwal();
                    }}
                    onCancel={() => {
                        this.handleConfirmSwal();
                    }}
                    />
                </React.Fragment>);
        }
    }

    return withRouter(withStyles(styles)(ReactTimeout(HOC)));
}