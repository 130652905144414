const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
    height: 30,
  },
  gridContainer: {
    display: 'flex',
    height: '100%',
    marginTop: '2rem',
    alignItems: 'unset',
  },
  alignFlex: {
    display: 'flex',
  },
  avatarBlue: {
    background: theme.palette.secondary.main
  },
  w100: {
    width: "100%",
  },
  textCenter: {
    textAlign: 'center'
  },
  uploadAvatar: {
    width: '100%',
    height: '100%',
    background: theme.palette.grey[200],
    boxShadow: theme.shadows[4],
  },
  avatarWrap: {
    width: 100,
    height: 100,
    margin: '10px auto 30px',
    position: 'relative'
  },
  hiddenDropzone: {
    display: 'none'
  },
  buttonUpload: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  width100: {
    width: '100%',
  }, 
  appBar: { 
      position: 'relative', 
  }, 
  flex: { 
      flex: 1, 
  }, 
  card: {
    cursor: 'pointer'
  },
  detailContainer: {
    margin: '-16px auto 0',
    width: '100%',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 960,
      minWidth: 960,
      paddingTop: 40,
      marginTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      //overflowX: 'hidden',
    }
  },
});

export default styles;