export const getObjectById = (data, item) => {
    let auxItem = data.find(itm => {
        return itm.id === item.id;
    })
    return auxItem; 
}

//AHDA 03/05/2019
//Get File Title
export const getFileTitle = (url) => {
    let auxUrl = url.split('/'); 
    auxUrl = auxUrl.length > 0 ? auxUrl[auxUrl.length - 1].split('_') : "";
    auxUrl.splice(0,1);
    auxUrl = auxUrl.join("_"); 
    return auxUrl;  
}

//AHDA 24/05/2019
export const getFilteredData = (data, key, search) => {
    
    data = data ?  data.filter(item => {
        if(item[key]){
            if(item[key].toUpperCase().indexOf(search) > -1)
                return item; 
        }
    }) : []; 

    return data; 
}

//AHDA 24/05/2019
export const getfilteredDataAllKeys = (data, search) => {
    let auxData = [...data];
    let filteredData = [];  
    
    auxData.map(item => {
        //console.log(Object.values(item)); 
        const match = Object.values(item).filter(v => {
            if(v){
                if(v.toString().toUpperCase().indexOf(search) > -1)
                    return v;  
            }
        }); 

        let hasMatch = match.length > 0 ? true : false; 

        if(hasMatch){
            filteredData.push(item); 
        }
        return item;  
    }); 

    return filteredData; 
}

//AHDA 28/05/2019
//Auxiliar method to get redirect url 
export const getRedirectUrl = (config) => {
    let redirect = "", isRedirect = false; 
    for(let x in config){
        const permissions = JSON.parse(config[x].grant_configuration); 
        let breakFor = false; 
        switch(config[x].menu_id){
            //Configuration
            case 15: 
                if(permissions.chkViewProfileAdmin){
                    redirect = "/perfiles-y-privilegios"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 14: 
                if(permissions.chkViewUser){
                    redirect = "/users"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 19: 
                if(permissions.chkViewParameter){
                    redirect = "/parametros"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 16: 
                if(permissions.chkViewNotification){
                    redirect = "/configuracion-de-notificaciones"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 13: 
                if(permissions.chkEvalArea){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }

                if(permissions.chkHistMovement){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }

                if(permissions.chkBuyWork){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }

                if(permissions.chkWorkAutor){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }

                if(permissions.chkStatusContr){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }
                if(permissions.chkDocStatus){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }
                if(permissions.chkWorkFlowPayment){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }
                if(permissions.chkLitWork){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true; 
                }
                if(permissions.chkHistWork){
                    redirect = "/reporte/bitacora-movimientos"; 
                    isRedirect = true;
                }
                breakFor = true; 
            break; 
            case 12: 
                if(permissions.chkViewProfile){
                    redirect = "/profile"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 17: 
                if(permissions.chkViewCatalog){
                    redirect = "/catalogos/ambiente"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
            case 21: 
                if(permissions.chkViewAllWork){
                    redirect = "/obras-registradas"; 
                    isRedirect = true;  
                    breakFor = true; 
                }else{
                    isRedirect = false; 
                }
            break; 
        }

        if(breakFor){
            break; 
        }
    }

    if(isRedirect){
        localStorage.setItem("redirect_url", redirect); 
        return redirect; 
    }else{
        return false
    }
}