import React from 'react';
import PropTypes from 'prop-types'; //AHDA 07/05/2019
import { withStyles } from '@material-ui/core/styles';

import SweetAlert from 'sweetalert-react';
import '../../../styles/components/vendors/sweetalert-react/sweetalert.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './tableDocumentacion-jss'; 

import { getFileTitle } from '../../../helpers/general';


class TableDocumentacion extends React.Component {
    
    state = {
        show: false,
        selected: null
    };

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.handleDelete(this.state.selected);
    }

    render(){

        //AHDA 07/05/2019
        //Added props
        const {data, handleView, handleDelete, showDelete, classes} = this.props; 

        return(
            <div>
                {/* 
                CH 26/03/2019
                Tabla para ver documentos
                */}
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell padding="dense">Documentos</TableCell>
                            <TableCell>Fecha de entrega</TableCell>
                            <TableCell>Ver</TableCell>
                            {
                                showDelete && <TableCell>Eliminar</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*AHDA 07/05/2019: Added mapping*/}
                        {
                            data ? 
                            data.map((item, idx) => (
                                <TableRow key={`row_${idx}`}>
                                    <TableCell padding="dense">{item.isNew ? item.ruta : getFileTitle(item.ruta)}</TableCell>
                                    <TableCell padding="dense">{item.delivery_date}</TableCell>
                                    
                                    <TableCell>
                                        <IconButton onClick={() => handleView(item)}>
                                            <Visibility />
                                        </IconButton>
                                    </TableCell>
                                    
                                    {
                                        showDelete &&
                                        <TableCell>
                                            <IconButton aria-label="Delete" onClick={() => this.handleOpenSwal(item)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    }
                                </TableRow>
                            )) : <TableRow><TableCell align="center">Sin documentos</TableCell></TableRow>
                        }
                    </TableBody>
                </Table>

                {/*AHDA 07/05/2019: Added SweetAalert*/}
                <SweetAlert
                    show={this.state.show}
                    customClass={classes.alertDialog}
                    title="¿Estás seguro?"
                    text="Una vez que elimines este elemento, no se podrá recuperar"
                    showCancelButton
                    onConfirm={() => {
                        this.handleConfirmSwal();
                    }}
                    onCancel={() => {
                        this.setState({ show: false, selected: null });
                    }}
                />
            </div> 
        )
    }
}

//AHDA 07/05/2019
//Added props and default props
TableDocumentacion.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired, 
    handleView: PropTypes.func, 
    handleDelete: PropTypes.func, 
    showDelete: PropTypes.bool
}

TableDocumentacion.defaultProps = {
    handleView: () => {}, 
    handleDelete: () => {}, 
    showDelete: true
}

export default withStyles(styles)(TableDocumentacion);