import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import '../../../styles/components/vendors/sweetalert-react/sweetalert.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from './tableRepeatedWorks-jss'; 






class TableRepeatedWorks extends React.Component {
    
    state = {
        show: false,
        selected: null
    };

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.handleDelete(this.state.selected);
    }

    render(){

        //AHDA 07/05/2019
        //Added props
        const {data, classes} = this.props; 

        return(
            <div>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell padding="dense">ID</TableCell>
                            <TableCell>Empresa/Proveedor</TableCell>
                            <TableCell>Título</TableCell>
                            <TableCell>Autor(es)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item, idx) => (
                                <TableRow key={`row_${idx}`}>
                                    <TableCell padding="dense">{item.id}</TableCell>
                                    <TableCell padding="dense">{item.companyProvider}</TableCell>
                                    <TableCell padding="dense">{item.title}</TableCell>
                                    <TableCell padding="dense">{item.catalogueAuthor}</TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>

            </div> 
        )
    }
}

//AHDA 07/05/2019
//AHDA 07/05/2019
//Added props and default props

//Added props and default props
TableRepeatedWorks.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired
   
}


export default withStyles(styles)(TableRepeatedWorks);