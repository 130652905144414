import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from './../../Catalogos/styles-jss';
import classNames from 'classnames';
import EditConfigure from './EditConfigure';
import Header from './notifHeader';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import fondo from './pp_boy.svg';

import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import Loader from '../../../../components/Loader/Loader';

import TableWithoutEdition from '../../../../components/Catalogue/TableWithoutEdition';

import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

import LoaderFull from '../../../../components/Loader/LoaderFull';
class ConfigureNotifications extends React.Component {

    state = {
        //Open form
        openForm: false,

        //Error 
        openError: false,

        checkedList: [],
        notificationId: 0,

        isLoading: true,

        // Datos de tablas
        headers: ["Clave", "Notificación", "Descripción", "Roles a notificar", "Envío por correo", "Notificaciones web", "Editar"],
        dataAN: [],
        dataTableAN: [],

        headersMN: ["Clave", "Notificación", "Envío por correo", "Notificaciones web"],
        dataMN: [],
        dataTableMN: [],

        roles: [],


        // Alert
        openAlert: false,
        variant: "",
        message: "",

        loading: true,
        chkEditNotification:false,
        chkViewNotification:false,
        //isanchez 07.05.2019 add permission
        chkViewUserNoti : false
    };

    //Open and close form
    handledOpenForm = (item) => {
        const obj = this.state.dataAN.find(obj => obj.notification_id == item.notificationId);
        this.setState({ openForm: true, checkedList: JSON.parse("[" + obj.rol_id + "]"), notificationId: item.notificationId });
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    handleDeleteItem = (item) => {
        console.log("Se deberia eliminar", item);
    }

  
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        let body = {
            notification_id: this.state.notificationId,
            roles: this.state.checkedList,
            portal: "obras"
        }


        apiPOST('/notifications/updateConfiguration', body).then(
            response => {
                if (response.status === 200) {

                    let obj = this.state.dataAN.find(obj => obj.notification_id == response.data[0].notification_id);
                    obj.rol_id = response.data[0].rol_id
                    obj.roles = response.data[0].roles

                    let dataAN = [...this.state.dataAN];
                    let auxIndex = dataAN.findIndex(itm => itm.notification_id === response.data[0].notification_id);
                    dataAN[auxIndex] = obj;


                    obj = this.state.dataTableAN.find(obj => obj.notificationId == response.data[0].notification_id);
                    obj.roles = response.data[0].roles

                    let dataTableAN = [...this.state.dataTableAN];
                    auxIndex = dataTableAN.findIndex(itm => itm.notification_id === response.data[0].notification_id);
                    dataTableAN[auxIndex] = obj;

                    this.setState({ dataTableAN: dataTableAN, dataAN: dataAN, openForm: false, isLoading: false })
                }
            }).catch(error => {
                console.log(error);
                this.setState({ openForm: false, openAlert: true, variant: "error", message: "Ocurrió un error inesperado", isLoading: false })
            });
    }

    handleClear = (event) => {
        this.setState({ checkedList: [] });
    }

    handleToggle = value => () => {
        const { checkedList } = this.state;
        const currentIndex = checkedList.indexOf(value);
        const newChecked = [...checkedList];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checkedList: newChecked });
    };


    componentDidMount() {

        /* isanchez 26.03.19 se agregaron permisos*/
        if(localStorage.getItem("user_permissions")!= null){
            let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 16)[0];
            console.log("per", config.grant_configuration);
            let chk = JSON.parse(config.grant_configuration);
            this.setState({
                chkEditNotification: chk.chkEditNotification,
                chkViewNotification: chk.chkViewNotification,
                chkViewUserNoti : chk.chkViewUserNoti
            });   
        }

        // Get notificaciones admi
        let dataAN = []
        apiPOST('/notifications/getConfiguration', { portal: "obras" }).then(
            response => {
                if (response.status === 200) {

                    const data = response.data

                    for (const x in data) {
                        const object = []
                        object.notificationId = data[x].notification_id.toString()
                        object.name = data[x].name
                        object.description = data[x].description
                        object.roles = data[x].roles
                        object.send_mail = data[x].send_mail
                        object.send_web = data[x].send_web
                        dataAN.push(object);
                    }


                    this.setState({ dataTableAN: dataAN, dataAN: data })
                }
            }).catch(error => {
                console.log(error);
            });

        // Get roles admi
        apiPOST('/roles/get', { portal: 2 }).then(
            response => {
                if (response.status === 200) {
                    this.setState({ roles: response.data })
                }
            }).catch(error => {
                console.log(error);
            });

        this.setState({ isLoading: false });


        // Get notificaciones admi
        let dataTableAN = []
        apiPOST('/notifications/getConfiguration', { portal: "obras" }).then(
            response => {
                if (response.status === 200) {

                    const data = response.data

                    for (const x in data) {
                        const object = []
                        object.notificationId = data[x].notification_id.toString()
                        object.name = data[x].name
                        object.description = data[x].description
                        object.roles = data[x].roles
                        object.send_mail = data[x].send_mail
                        object.send_web = data[x].send_web
                        dataTableAN.push(object);
                    }


                    this.setState({ dataTableAN: dataTableAN, dataAN: data })
                }
            }).catch(error => {
                console.log(error);
            });



        // Get notificaciones user
        let dataTableMN = []
        apiPOST('/notifications/getConfiguration', { portal: "obras", user_id: returnCurrentUserId() }).then(
            response => {
                if (response.status === 200) {

                    const data = response.data

                    for (const x in data) {
                        const object = []
                        object.notificationId = data[x].notification_id.toString()
                        object.notification = data[x].notification
                        object.send_mail_usr = (data[x].send_mail_usr == null) ? 0 : data[x].send_mail_usr
                        object.send_web_usr = (data[x].send_web_usr == null) ? 0 : data[x].send_web_usr

                        object.send_mail = data[x].send_mail
                        object.send_web = data[x].send_web

                        dataTableMN.push(object);
                    }


                    this.setState({ dataTableMN: dataTableMN, dataMN: data })
                }
            }).catch(error => {
                console.log(error);
            });

        this.setState({ loading: false });
    }


    // JCMY 11/03/19
    handleUpdate = (data, item, event) => {
        const checkChange = event.target.id

        if (checkChange === "check_send_mail") {
            item.send_mail = item.send_mail === 1 ? 0 : 1
        } else if (checkChange === "check_send_web") {
            item.send_web = item.send_web === 1 ? 0 : 1
        }

        let body = {
            notificationId: parseInt(item.notificationId),
            port: "obras",
            send_mail: item.send_mail,
            send_web: item.send_web
        }

        apiPOST('/notifications/updateconfigurationadmin', body).then(
            response => {
                if (response.status === 200) {
                    let auxData = [...this.state.dataTableAN]
                    let auxIndex = this.state.dataTableAN.findIndex(itm => itm.notificationId === item.notificationId);
                    auxData[auxIndex] = item;
                    this.setState({ dataTableAN: auxData });

                }
                else {
                    console.log(response)
                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                console.log(err);
                this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", isLoading: false, openForm: false });
            }
        )
    }



    handleUpdateUser = (data, item, event) => {
        const checkChange = event.target.id

        if (checkChange === "check_send_mail_usr") {
            item.send_mail_usr = item.send_mail_usr === 1 ? 0 : 1
        } else if (checkChange === "check_send_web_usr") {
            item.send_web_usr = item.send_web_usr === 1 ? 0 : 1
        }

        let body = {
            notification_id: parseInt(item.notificationId),
            user_id: returnCurrentUserId(),
            portal: "obras",
            send_mail: item.send_mail_usr,
            send_web: item.send_web_usr
        }

        apiPOST('/notifications/updateconfigurationuser', body).then(
            response => {
                if (response.status === 200) {

                    let auxData = [...this.state.dataTableMN]
                    let auxIndex = auxData.findIndex(itm => itm.notificationId === item.notificationId);
                    auxData[auxIndex] = item;
                    this.setState({ dataTableMN: auxData });

                }
                else {
                    console.log(response)
                    this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                console.log(err);
                this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", openForm: false, isLoading: false });
            }
        )
    }


    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className="marginTopLogo">
                {this.state.loading ? <LoaderFull /> : ''}

                {
                    this.state.chkViewNotification ? 
                    <Paper className={classNames(classes.root, classes.mbTable)}>
                    {/*AHDA 24/05/2019: Added showSearch*/}
                    <Header 
                        title="Configuración de Notificaciones" 
                        tooltip="Nueva Obra" 
                        textBtn="Nueva Obra" 
                        openForm={() => this.handleClickOpen(undefined)}
                        showSearch={false} />
                    {
                        this.state.isLoading ?
                        <Loader text="Cargando" /> :
                        <TableCatalogue
                            headers={this.state.headers}
                            data={this.state.dataTableAN}
                            openForm={this.handledOpenForm}
                            deleteItem={this.handleDeleteItem}
                            showDelete={false}
                            workId={0}
                            updateStatus={this.handleUpdate} 
                            canEdit={this.state.chkEditNotification}
                            />
                    }
                </Paper>
                :''
                }

                <EditConfigure
                    openForm={this.state.openForm}
                    closeForm={this.handledCloseForm}
                    classes={classes}
                    submit={this.handleSubmit}
                    clearForm={this.handleClear}
                >
                    <div>
                        <Grid
                            container
                            spacing={24}
                            alignItems="flex-start"
                            justify="flex-start"
                            direction="row">
                            <Grid item md={12} xs={12}>
                                <div className={classes.root}>
                                    <List className={classes.listaRoles}>
                                        {this.state.roles.map((role, idx) => (
                                            role.status === 1 ?
                                                (<ListItem key={role.key} dense button className={classes.listItem}>
                                                    <Avatar src={fondo} />
                                                    <ListItemText primary={role.name} />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox onChange={this.handleToggle(role.rol_id)} checked={this.state.checkedList.indexOf(role.rol_id) !== -1} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>) : ""
                                        ))}
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </EditConfigure>
                {/* isanchez 07.05.2019 show by chkViewUserNoti permission */}
                {
                    this.state.chkViewUserNoti ? 
                    <Paper className={classNames(classes.root, classes.mbTable)}>
                        <Header title="Mi Configuración de Notificaciones" tooltip="Nueva Obra" textBtn="Nueva Obra" openForm={() => this.handleClickOpen(undefined)} />

                        <TableWithoutEdition
                            headers={this.state.headersMN}
                            data={this.state.dataTableMN}
                            updateStatus={this.handleUpdateUser}
                        />
                    </Paper>
                    :''
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.openAlert}
                    autoHideDuration={6000}
                    onClose={this.handleCloseAlert}>
                    <CustomNotification
                        onClose={this.handleCloseAlert}
                        variant={this.state.variant}
                        message={this.state.message} />
                </Snackbar>
            </div>
        )
    }
}

export default withStyles(styles)(ConfigureNotifications);