import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import styles from './../reportes-jss';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

//Notifications  
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';
import Grid from '@material-ui/core/Grid';

//Auxiliars
import { convertToStringArray } from '../../../../utils/helpers'
import { textLabelsEs } from '../../../../utils//tableTexts';
import enLocale from 'moment/locale/en-au';
import esLocale from 'moment/locale/es';
import { ConsoleLogger } from '@aws-amplify/core';


const locale = {
  en: enLocale,
  es: esLocale
}
const keys = ['areaEvaluadora', 'empresa', 'tituloObra', 'autor', 'tipoObra', 'fechaIngreso', 'fechaMC', 'tiempoTranscurrido', 'estatus'];

class AdvFilter extends React.Component {

  state = {
    //Notification
    open: false,
    //Filters
    areaEvaluadora: 0,
    estatusObra: 0,
    pais: 0,
    genero: 0,
    tipoMaterial: 0,
    fechaIngreso: null,
    fechaIngresoMaterial: null,
    empresa: 0,
    //Catalogues
    areas: [],
    estatus: [],
    filtroEstatus: [],
    firstFilter: true,
    paises: [],
    generos: [],
    materiales: [],
    array: [],
    //Table
    columns: [],
    data: [],
    //Loading
    loading: false
  }

  //Notification
  handleClose = () => {
    this.setState({ open: false });
  };

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //Datepickers
  handleDateChangeI = (date) => {
    this.setState({ fechaIngreso: date });
  }

  handleDateChangeIM = (date) => {
    this.setState({ fechaIngresoMaterial: date });
  }

  //Clear filters
  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      areaEvaluadora: 0,
      estatusObra: 0,
      pais: 0,
      genero: 0,
      tipoMaterial: 0,
      fechaIngreso: null,
      fechaIngresoMaterial: null,
      empresa: 0,
      loading: true
    })

    const request = {
      fechaIngreso: "0",
      fechaIngresoMaterial: "0",
      empresa: 0,
      areaEvaluadora: 0,
      estatusObra: 0,
      pais: 0,
      genero: 0,
      tipoMaterial: 0
    }

    this.handleFetch(request);
  }

  //Download csv
  handleDownload = (data) => {

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    const dataCsv = [["Área evaluadora", "Empresa/Proveedor", "Título de la Obra", "Autor(es)/Nickname", "Tipo Obra", "Fecha de ingreso", "Fecha de MC", "Tiempo transcurrido", "Estatus"]]

    for (const x in data) {
      const object = []
      for(let y in data[x]){
        data[x][y] = `${data[x][y]}`.replace(/,/g,";")
      }
      console.log(data[x])
      object.push(data[x])
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: '"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Areaevaluadora.csv";
    download.click();

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteAreaEvaluadora'
    }
    apiPOST('/audit/add', body);
  }

  //Llamada de busqueda
  searchs = () => {

    this.setState({ loading: true });

    var fi = this.state.fechaIngreso === null ? 0 : new Date(this.state.fechaIngreso);
    var fim = this.state.fechaIngresoMaterial === null ? 0 : new Date(this.state.fechaIngresoMaterial)


    const request = {
      fechaIngreso: fi === 0 ? 0 : fi.getFullYear() + "-" + ((fi.getMonth() + 1) > 9 ? '' : '0') + (fi.getMonth() + 1) + "-" + ((fi.getDate()) <= 9 ? '0' : '') + fi.getDate(),
      fechaIngresoMaterial: fim === 0 ? 0 : fim.getFullYear() + "-" + ((fim.getMonth() + 1) > 9 ? '' : '0') + (fim.getMonth() + 1) + "-" + ((fim.getDate()) <= 9 ? '0' : '') + fim.getDate(),
      empresa: this.state.empresa,
      areaEvaluadora: this.state.areaEvaluadora,
      estatusObra: this.state.estatusObra,
      pais: this.state.pais,
      genero: this.state.genero,
      tipoMaterial: this.state.tipoMaterial
    }

    this.handleFetch(request);
  }

  async componentDidMount() {

    this.setState({ loading: true });

    //"Área Evaluadora", "Empresa/Proveedor", "Titulo de la obra", "Autor(es)/Nickname", "Tipo de obra",  
    //"Fecha de ingreso", "Fecha de MC", "Tiempo transcurrido", "Estatus"
    const { lang } = this.context;
    this.setState(
      {
        columns: [lang.reports.evaluatingArea, lang.reports.compProv, lang.reports.title, lang.reports.authors,
        lang.reports.playType, lang.reports.entryDate, lang.reports.mcDate, lang.reports.timePassed, lang.reports.status]
      }
    )

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 1 }, true).then(
      response => {
        if (response.status === 200) {

          this.setState({ areas: response.data })
        }
      }).catch(error => {
        console.log(error);
      });

    //AHDA 17/05/2019: added validation for refresh 
    await apiPOST('/catalogos/getCatalogo', { idCatalogo: 2 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ estatus: response.data })
        }
      }).catch(
        error => {
          console.log(error);
        });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }, true).then(
      response => {
        if (response.status === 200) {

          this.setState({ paises: response.data })
        }
      }).catch(
        error => {
          console.log(error);
        });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 4 }, true).then(
      response => {
        if (response.status === 200) {

          this.setState({ generos: response.data })
        }
      }).catch(error => {
        console.log(error);
      });

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 41 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ materiales: response.data })
        }
      }).catch(
        error => {
          console.log(error);
        });


    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 7 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ array: response.data })
        }
      }).catch(error => {
        console.log(error);
      });

    //Llamada carga inicial
    const request = {
      fechaIngreso: "0",
      fechaIngresoMaterial: "0",
      empresa: 0,
      areaEvaluadora: 0,
      estatusObra: 0,
      pais: 0,
      genero: 0,
      tipoMaterial: 0
    }

    this.handleFetch(request);
  }

  handleFetch(request) {
    apiPOST('/reportes/areaEvaluadora', request).then(
      response => {
        if (response.status === 200) {
          // JCMY 13/05/2019


          const result = [];
          const filtrosEstatus = []
          const list = response.data
          const { estatus } = this.state;
          for (const x in list) {
            var obj = [];

            obj.push(list[x].areaEvaluadora != null ? list[x].areaEvaluadora : "-");
            obj.push(list[x].empresa ? list[x].empresa : list[x].companyProvider ? list[x].companyProvider : '-');
            obj.push(list[x].tituloObra != null ? list[x].tituloObra : "-");
            obj.push(list[x].autor ? list[x].authores ? `${list[x].authores}/${list[x].autor}` : list[x].autor : list[x].authores ? list[x].authores : '-');
            obj.push(!list[x].tipoObra ? "-" : list[x].tipoObra === "primigenia" ? "Primigenia" : "Adaptación"); //AHDA 05/06/2019
            obj.push(list[x].fechaIngreso != null ? list[x].fechaIngreso : "-");
            obj.push(list[x].fechaMC != null ? list[x].fechaMC : "-");
            obj.push(list[x].tiempoT != null ? list[x].tiempoT : "-");
            obj.push(list[x].status != null ? list[x].status : "-");
            // AC-1208 cramirez 17/09/2019
            if (filtrosEstatus.length > 0 && this.state.firstFilter) {
              const findEstatus = filtrosEstatus.find((estats) => estats.data === list[x].status)
              if (findEstatus === undefined) {
                const estado = estatus.find((estats) => list[x].status === estats.data)
                if (estado != undefined)
                  filtrosEstatus.push(estado)
              }
            } else {
              const estado = estatus.find((estats) => list[x].status === estats.data)
              if(estado)
                filtrosEstatus.push(estado)
            }
            result.push(obj);
          }
          if (this.state.firstFilter) {
            this.setState({ data: result, loading: false, filtroEstatus: filtrosEstatus, firstFilter: false })
          } else {
            this.setState({ data: result, loading: false, })
          }
        } else {
          this.setState({ open: true, loading: false });
        }
      }).catch(error => {
        console.log(error);
        this.setState({ open: true, loading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                {/* JCMY 26/03/2019 */}
                {/* Cambiaron la variable y con eso dejo de imprimir el reporte */}
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.data)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title={lang.reports.reports} className={classes.evaluadora} desc="">
                {this.state.loading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <MuiPickersUtilsProvider utils={MomentUtils}>                    
                      <Grid item xs={6} sm={3} md={3}>
                          <DatePicker
                            keyboard={false}
                            helperText={lang.reports.evDateEnter}
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={this.state.fechaIngreso}
                            onChange={this.handleDateChangeI}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            clearable={true}
                            cancelLabel={lang.common.cancel}
                            clearLabel={lang.common.clear}
                            placeholder="DD/MM/AAAA"
                          />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                          <DatePicker
                            keyboard={false}
                            helperText={lang.reports.compDateEnter}
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={this.state.fechaIngresoMaterial}
                            onChange={this.handleDateChangeIM}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            clearable={true}
                            cancelLabel={lang.common.cancel}
                            clearLabel={lang.common.clear}
                            placeholder="DD/MM/AAAA"
                          />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.empresa} onChange={this.handleChange} name="empresa" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.array.map((arr, idx) => (
                              <MenuItem value={arr.value} key={`arr${idx}`}>
                                {arr.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.compProv}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.areaEvaluadora} onChange={this.handleChange} id="areaEvaluadora" name="areaEvaluadora" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.areas.map((area, idx) => (
                              <MenuItem value={area.value} key={`area${idx}`}>
                                {area.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.evaluatingArea}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.estatusObra} onChange={this.handleChange} name="estatusObra" displayEmpty className={classes.selectEmpty} >
                          <MenuItem value={"0"}>{lang.reports.all}</MenuItem>
                          {/*AHDAa 27/05/2019: chanaged value*/}
                          {
                            this.state.filtroEstatus.map((esta, idx) => (
                              <MenuItem value={esta.value} key={`esta${idx}`}>
                                {esta.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.playEstatus}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.pais} onChange={this.handleChange} name="pais" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.paises.map((pais, idx) => (
                              <MenuItem value={pais.value} key={`pais${idx}`}>
                                {pais.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.country}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.genero} onChange={this.handleChange} name="genero" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.generos.map((genero, idx) => (
                              <MenuItem value={genero.value} key={`genero${idx}`}>
                                {genero.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.genre}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.tipoMaterial} onChange={this.handleChange} name="tipoMaterial" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.materiales.map((material, idx) => (
                              <MenuItem value={material.value} key={`material${idx}`}>
                                {material.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.materialType}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.evaluadora}>
                  <MUIDataTable
                    title={`${lang.reports.repEvaluatingArea} (${this.state.data.length} resultados)`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>
                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common.errMsg} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

export default withStyles(styles)(AdvFilter);
